import {
  ADD_SHIPMENT_LOG_REQUESTED,
  EDIT_SHIPMENT_LOG_REQUESTED,
  UPDATE_SHIPMENT_LOG_FIELD_VALUE,
  CHECK_SHIPMENT_LOG_IS_VALID,
  CHECK_SHIPMENT_LOG_IS_DIRTY,
  SHOW_SHIPMENT_LOG_EDIT_FORM,
  CANCEL_SHIPMENT_LOG_EDIT_FORM,
  LOAD_SHIPMENT_LOG_REQUESTED,
  LOAD_SHIPMENT_LOG_SUCCESS,
  LOAD_SHIPMENT_LOG_FAILURE,
  LOAD_SHIPMENT_LOGS_REQUESTED,
  LOAD_SHIPMENT_LOGS_SUCCESS,
  LOAD_SHIPMENT_LOGS_FAILURE,
  SAVE_SHIPMENT_LOG_REQUESTED,
  SAVE_SHIPMENT_LOG_SUCCESS,
  SAVE_SHIPMENT_LOG_FAILURE,
  CHANGE_SHIPMENT_LOG_FILTER,
  INITIALIZE_SHIPMENT_LOG_FILTER
} from "../actions/shipmentLogActions";
import { LOGOUT_SUCCESS } from "../actions/authActions";
import { shipmentLog } from "../models/shipmentLog";
import { NULL_UUID } from "../api/constants";

const initialFilter = {
  fromDate: "",
  toDate: "",
  staff: "",
  clientId: NULL_UUID,
  status: ""
};

const initialState = {
  filter: { ...initialFilter },
  action: "",
  editFormIsOpen: false,
  saveSuccess: false,
  isValid: false,
  isDirty: true,
  isSaving: false,
  loading: false,
  shipmentLogs: [],
  editShipmentLog: { ...shipmentLog },
  originalShipmentLog: { ...shipmentLog },
  error: null
};

const shipmentLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_SHIPMENT_LOG_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          fromDate: action.fromDate,
          toDate: action.toDate
        }
      };

    case LOAD_SHIPMENT_LOGS_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_SHIPMENT_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        shipmentLogs: [...action.payload]
      };

    case LOAD_SHIPMENT_LOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case LOAD_SHIPMENT_LOG_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_SHIPMENT_LOG_SUCCESS:
      return {
        ...state,
        shipmentLog: { ...action.payload },
        loading: false
      };

    case LOAD_SHIPMENT_LOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case ADD_SHIPMENT_LOG_REQUESTED:
      return {
        ...state,
        isValid: false,
        isDirty: true,
        action: "Create Shipment Log",
        editShipmentLog: { ...action.payload },
        originalShipmentLog: { ...action.payload }
      };

    case EDIT_SHIPMENT_LOG_REQUESTED:
      return {
        ...state,
        isValid: false,
        isDirty: false,
        action: "Edit Shipment Log",
        editShipmentLog: { ...action.payload },
        originalShipmentLog: { ...action.payload }
      };

    case CHECK_SHIPMENT_LOG_IS_VALID:
      return {
        ...state,
        isValid: action.payload
      };

    case CHECK_SHIPMENT_LOG_IS_DIRTY:
      return {
        ...state,
        isDirty: action.isDirty
      };

    case UPDATE_SHIPMENT_LOG_FIELD_VALUE:
      return {
        ...state,
        editShipmentLog: {
          ...state.editShipmentLog,
          [action.id]: action.value
        }
      };

    case SHOW_SHIPMENT_LOG_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: true
      };

    case CANCEL_SHIPMENT_LOG_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: false,
        action: "",
        isValid: false,
        isDirty: false,
        saveSuccess: false,
        editShipmentLog: {},
      };

    case SAVE_SHIPMENT_LOG_REQUESTED:
      return {
        ...state,
        isSaving: true
      };

    case SAVE_SHIPMENT_LOG_SUCCESS:
      return {
        ...state,
        isSaving: false,
        editFormIsOpen: false,
        saveSuccess: true,
        editShipmentLog: {
          ...shipmentLog
        },
        originalShipmentLog: {
          ...shipmentLog
        },
        shipmentLogs: [
          ...state.shipmentLogs.filter(x => x.shipmentLogId !== action.payload.shipmentLogId),
          action.payload
        ]
      };

    case SAVE_SHIPMENT_LOG_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case CHANGE_SHIPMENT_LOG_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.id]: action.value
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default shipmentLogReducer;
