import { string, shape } from "prop-types";

export const shipmentLog = {
  shipmentLogId: "",
  clientName: "",
  sender: "",
  carrier: "",
  trackingNumber: "",
  dateShipped: "",
  dateReceived: "",
  itemsInShipment: "",
  status: "",
};

export const shipmentLogShape = shape({
  shipmentLogId: string,
  clientName: string,
  sender: string,
  carrier: string,
  trackingNumber: string,
  dateShipped: string,
  dateReceived: string,
  itemsInShipment: string,
  status: string,
});
