import React, { Component } from "react";
import { string } from "prop-types";
import { Link } from "react-router-dom";
import { fetchImage } from "../../api/imagesApi";

const noImageStyle = {
  backgroundColor: "#EDEDED",
  margin: "0 auto"
}

class SearchItemImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null
    };
  }

  async componentDidMount() {
    await this.doRefreshImage();

  }

  doRefreshImage = async () => {
    const { clientId, objectId, imageNumber } = this.props;

    this.setState({
      loading: true
    });

    const objImage = await fetchImage(clientId, objectId, imageNumber);

    if (objImage) {
      this.setState({
        loading: false,
        image: objImage.Body
      });
    }
    else {
      this.setState({
        loading: false,
        image: null
      });
    }
  }

  render() {
    const {
      objectUrl,
    } = this.props;

    let { image, loading } = this.state;

    if (loading) {
      return (
        <span>
          <i className="fa fa-spin fa-spinner" />
        </span>
      );
    }
    else if (image) {
      return (
        <Link to={objectUrl} className="url-button">
          <img
            src={`data:image/jpeg;base64,${btoa(
              String.fromCharCode(...image.data)
            )}`}
            className="search-item-image"
            alt="object"
          />
        </Link>
      );
    }
    else {
      return (
        <Link to={objectUrl} className="url-button">
          <div
            className="no-image-div"
            style={noImageStyle}
          />
        </Link>
      )
    }
  }
}

SearchItemImage.propTypes = {
  objectUrl: string.isRequired,
  clientId: string.isRequired,
  objectId: string.isRequired,
};

export default SearchItemImage;
