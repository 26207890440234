import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { Auth, Logger } from 'aws-amplify';
import AppForgotPasswordRequestForm from './AppForgotPasswordRequestForm'
import AppResetPasswordForm from './AppResetPasswordForm';

const logger = new Logger('AppForgotPassword');

class AppForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.changeState = this.changeState.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.formRequestIsValid = this.formRequestIsValid.bind(this);
    this.formSubmitIsValid = this.formSubmitIsValid.bind(this);
    this.sendResetPasswordRequest = this.sendResetPasswordRequest.bind(this);
    this.submitNewPassword = this.submitNewPassword.bind(this);
    this.cancelResetPassword = this.cancelResetPassword.bind(this);

    this._validAuthStates = ['forgotPassword'];
    this.state = {
      delivery: null,
      processing: false,
      error: '',
    }
  }

  handleChange = (event) => {
    event.preventDefault();

    this.setState({[event.target.id]: event.target.value });
  }

  formRequestIsValid = () => {
    const {username} = this.state;

    //Really this should be a valid email address
    return username != null &&
      (username.length >=1);
  }

  formSubmitIsValid = () => {
    const {username} = this.state;

    //Really this should be a valid email address
    return username != null &&
      (username.length >=1);
  }

  sendResetPasswordRequest = () => {
    const { username } = this.state;

    Auth.forgotPassword(username)
      .then(data => {
        logger.info("resetPasswordSuccess", data);
        this.setState({ delivery: data.CodeDeliveryDetails });
      })
      .catch(err => {
        logger.info("sendResetPasswordRequest Error", err);
        this.setState({ error: err.message || err });
      });

  }

  submitNewPassword = () => {
    const { username, verificationcode, new_password} = this.state;

    if (!Auth || typeof Auth.forgotPassword !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    Auth.forgotPasswordSubmit(username, verificationcode, new_password)
      .then((data) =>  {
        logger.info("forgotPasswordSubmit success", data);
        this.changeState('signIn');
        this.setState({ delivery: null });
      })
      .catch(err => {
        logger.info("forgotPasswordSubmit Error", err);
        this.setState({ error: err.message || err });
      });
  }

   cancelResetPassword = () => {
    logger.info("cancelResetPassword");

    this.changeState('signIn');
  }

  changeState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(state, data);
    }
  }

  render() {
    const { authState } = this.props;

    if (!this._validAuthStates.includes(authState)) {
      return null;
    }

    const { error, delivery, processing } = this.state;

    const isValid = delivery ? this.formSubmitIsValid() : this.formRequestIsValid();
    return delivery ?
      (
        <AppResetPasswordForm
          error={error}
          processing={processing}
          handleChange={this.handleChange}
          formIsValid={isValid}
          resetPassword={this.submitNewPassword}
          cancelResetPassword={this.cancelResetPassword}
        />
      ) :
      (
        <AppForgotPasswordRequestForm
          error={error}
          processing={processing}
          handleChange={this.handleChange}
          formIsValid={isValid}
          resetPasswordRequest={this.sendResetPasswordRequest}
          cancelResetPassword={this.cancelResetPassword}
        />
      );


  }

}

AppForgotPassword.propTypes = {
  authState: string,
  onStateChange: func,
}

AppForgotPassword.defaultProps = {
  authState: 'default',
  onStateChange: null,
}

export default AppForgotPassword;
