import { string, shape, arrayOf } from "prop-types";

export const shipment = {
  shipmentId: "",
  description: "",
  requestedBy: "",
  dateRequested: "",
  dateShipped: "",
  carrier: "",
  shippedTo: "",
  trackingNumber: "",
  dateReceived: "",
  status: "",
  objects: []
};

export const shipmentShape = shape({
  shipmentId: string,
  description: string,
  dateRequested: string,
  dateShipped: string,
  carrier: string,
  objects: arrayOf(
    shape({
      objectId: string,
      inventoryId: string
    })
  ),
  shippedTo: string,
  trackingNumber: string,
  dateReceived: string,
  status: string
});

export const shippedObject = shape({
  objectId: string,
  inventoryId: string
});

export const shipmentFilterShape = shape({
  fromDate: string,
  toDate: string,
  staff: string,
  clientId: string
});
