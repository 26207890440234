import { Auth, Hub, Logger } from "aws-amplify";
import { push } from "connected-react-router";
import {
  initializeAppOnSuccessfulLogin,
  deleteUserProfile
} from "../actions/authActions";

const logger = new Logger("AmplifyBridge");

export default class AmplifyBridge {
  constructor(store) {
    this.store = store;

    this.onHubCapsule = this.onHubCapsule.bind(this);
    Hub.listen("auth", this, "AmplifyBridge"); // Add this component as a listener of auth events.

    this.checkUser();
  }

  onHubCapsule(capsule) {
    //logger.info("Amplify HUB event", capsule.payload.event);
    //logger.info("AMPLIFY EVENT", JSON.stringify(capsule.payload));

    this.checkUser(); // triggered every time user sign in / out

    if (capsule.payload.event === "signIn") {
      this.store.dispatch(push("/"));
    }

    if (capsule.payload.event === "signOut") {
      this.store.dispatch(push("/"));
    }
  }

  checkUser() {
    logger.info("checking user");
    Auth.currentAuthenticatedUser()
      .then(user => this.checkUserSuccess(user))
      .catch(err => this.checkUserError(err));
  }

  checkUserSuccess(user) {
    //logger.info("check user success, loading profile", user);

    Auth.userAttributes(user)
      .then(data => this.loadProfileSuccess(data))
      .catch(err => this.loadProfileError(err));
  }

  checkUserError(err) {
    logger.info("check user error", err);
    this.store.dispatch(deleteUserProfile());
  }

  loadProfileSuccess(data) {
    //logger.info("user attributes", data);

    const profile = this.translateAttributes(data);
    //logger.info("About to dispatch updates to user profile", profile);

    this.store.dispatch(initializeAppOnSuccessfulLogin(profile));
  }

  // Auth.userAttributes returns an array of attributes.
  // We map it to an object for easy use.
  translateAttributes(data) {
    const attributes = {};
    data
      .filter(attr =>
        [
          "sub",
          "email",
          "name",
          "given_name",
          "family_name",
          "custom:tier",
          "custom:role",
          "custom:tenant_id"
        ].includes(attr.Name)
      )
      .forEach(
        attr =>
          (attributes[
            attr.Name.replace("custom:", "")
              .replace("given_name", "first_name")
              .replace("family_name", "last_name")
          ] = attr.Value)
      );

    return attributes;
  }

  loadProfileError(err) {
    logger.info("load profile error", err);
    this.store.dispatch(deleteUserProfile());
  }
}
