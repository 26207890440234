import React from "react";
import moment from "moment";
import { bool } from "prop-types";
import ObjectViewRow from "./components/ObjectViewRow";
import { shipmentShape } from "../../models/shipment";

const checkoutTitleStyle = {
  fontSize: "20px",
  fontWeight: "600",
  marginLeft: "4px"
};

const checkoutSectionStyle = {
  paddingBottom: "36px",
  borderTop: "1px solid rgb(128, 128, 128)",
  paddingTop: "36px"
};

const hiddenDividerCheckoutSectionStyle = {
  paddingBottom: "36px"
};

const ViewCheckout = ({ shipment, hideDivider }) => (
  <div
    style={
      hideDivider ? hiddenDividerCheckoutSectionStyle : checkoutSectionStyle
    }
  >
    <h3 style={checkoutTitleStyle}>Checked Out</h3>
    <table className="view-object-checkout">
      <tbody>
        <ObjectViewRow label="Requested By" value={shipment.requestedBy} />
        <ObjectViewRow
          label="Date Requested"
          value={
            moment(shipment.dateRequested).isValid()
              ? moment(shipment.dateRequested).format("MM/DD/YYYY")
              : null
          }
        />
        <ObjectViewRow
          label="Date Shipped"
          value={
            moment(shipment.dateShipped).isValid()
              ? moment(shipment.dateShipped).format("MM/DD/YYYY")
              : null
          }
        />
        <ObjectViewRow label="Carrier" value={shipment.carrier} />
        <ObjectViewRow
          label="Tracking Number"
          value={shipment.trackingNumber}
        />
        <ObjectViewRow label="Description" value={shipment.description} />
        <ObjectViewRow label="Status" value={shipment.status} />
        <ObjectViewRow
          label="Date Received"
          value={
            moment(shipment.dateReceived).isValid()
              ? moment(shipment.dateReceived).format("MM/DD/YYYY")
              : null
          }
        />
      </tbody>
    </table>
  </div>
);

ViewCheckout.propTypes = {
  shipment: shipmentShape.isRequired,
  hideDivider: bool
};

ViewCheckout.defaultProps = {
  hideDivider: false
};

export default ViewCheckout;
