import React from 'react';
import { bool, string, func } from 'prop-types';
import {
  Label,
} from 'reactstrap';
import CheckBoxInput from '../../common/CheckBoxInput';

const FormCheckBox = ({label, id, checked, handleChange}) => (
  <div>
    <CheckBoxInput
      id={id}
      checked={checked}
      handleChange={handleChange}
    />
    <Label>{label}</Label>
  </div>
);

FormCheckBox.propTypes = {
  label: string.isRequired,
  id: string.isRequired,
  checked: bool.isRequired,
  handleChange: func.isRequired,
}

export default FormCheckBox;
