import { doFetch } from "./index";

export const fetchRequestedObjects = async () => {
  const path = `/requests`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
};

export const persistRequestObject = async object => {
  if (!object) {
    return false;
  }

  const path = `/requests`;

  const params = {
    method: "POST",
    headers: {},
    body: JSON.stringify(object)
  };

  const response = await doFetch(path, params);

  return response;
};

export const persistRemoveObject = async objectId => {
  if (!objectId) {
    return false;
  }

  const path = `/requests/${objectId}`;

  const params = {
    method: "DELETE",
    headers: {}
  };

  const response = await doFetch(path, params);
  return response;
};
