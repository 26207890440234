import "@babel/polyfill";
//import { Logger } from "aws-amplify";
import { fetchRequestedObjects } from "../api/requestsApi";

export const LOAD_REQUESTED_OBJECTS_REQUESTED = "LOAD_REQUESTED_OBJECTS_REQUESTED";
export const LOAD_REQUESTED_OBJECTS_SUCCESS = "LOAD_REQUESTED_OBJECTS_SUCCESS";
export const LOAD_REQUESTED_OBJECTS_FAILURE = "LOAD_REQUESTED_OBJECTS_FAILURE";

export const SELECT_REQUESTED_OBJECT = "SELECT_REQUESTED_OBJECT";
export const UNSELECT_REQUESTED_OBJECT = "UNSELECT_REQUESTED_OBJECT";
export const FILTER_REQUESTED_OBJECTS = "FILTER_REQUESTED_OBJECTS";
export const SELECT_ALL_REQUESTED_OBJECTS = "SELECT_ALL_REQUESTED_OBJECTS";

//const logger = new Logger("requestActions");

const requestObjectsRequested = () => ({
  type: LOAD_REQUESTED_OBJECTS_REQUESTED
});

const requestObjectsSuccess = objects => ({
  type: LOAD_REQUESTED_OBJECTS_SUCCESS,
  payload: objects
});

const requestObjectsFailure = error => ({
  type: LOAD_REQUESTED_OBJECTS_FAILURE,
  payload: error
});

const selectAllRequested = showAll => ({
  type: SELECT_ALL_REQUESTED_OBJECTS,
  payload: showAll
});

const selectObjectRequested = object => ({
  type: SELECT_REQUESTED_OBJECT,
  payload: object
});

const unselectObjectRequested = objectId => ({
  type: UNSELECT_REQUESTED_OBJECT,
  payload: objectId
});

export const filterRequests = clientId => ({
  type: FILTER_REQUESTED_OBJECTS,
  payload: clientId
});


export const saveRequestedObjects = () => {};

export const selectObject = (objectId) => async (dispatch, getState) => {
  const { requestedObjects } = getState().requests;
  const obj = requestedObjects.filter(x => x.objectId == objectId)
  dispatch(selectObjectRequested(obj));
}

export const unselectObject = (objectId) => async (dispatch) => {
  dispatch(unselectObjectRequested(objectId));
}

export const selectAll = (showAll) => async (dispatch, getState) => {

  dispatch(selectAllRequested(showAll));

  const { requestedObjects } = getState().requests;

  let selectedObjClientId = "";
  for (let obj of requestedObjects) {

    if (selectedObjClientId !== obj.clientId
      && selectedObjClientId !== ""
      && showAll) {
      return false;
    }

    if  (showAll) {
      dispatch(selectObject(obj.objectId));
    }
    else {
      dispatch(unselectObject(obj.objectId));
    }
    selectedObjClientId = obj.clientId;
  }
  return true;
};

export const loadRequestedObjects = () => async dispatch => {
  dispatch(requestObjectsRequested());
  try {
    const data = await fetchRequestedObjects();
    dispatch(requestObjectsSuccess(data));
  }
  catch (err) {
    dispatch(requestObjectsFailure(err));
  }
};
