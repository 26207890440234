import React from 'react';
import { func, bool, string } from 'prop-types';
import { Container, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import {
  authBackgroundStyle,
  authFormStyle,
  actionButtonStyle,
  cancelButtonStyle
} from "../../styles/authStyles";

const resetPasswordNoteStyle = {
  marginLeft: "15px",
  marginRight: "15px",
  marginTop: "15px",
  marginBottom: "40px",
  color: "#212529"
}


const AppResetPasswordForm = ({ processing, handleChange, formIsValid, resetPassword, cancelResetPassword, error}) => (
  <div className="forgot-password-background" style={authBackgroundStyle}>
    <Container className="ResetPasswordForm" style={authFormStyle}>
      <h2>Reset your password</h2>
      <div className="reset-password-note" style={resetPasswordNoteStyle}>
        You will receive a verification code from The Digital Archivist to reset your password.
      </div>
      <Form>
        <Col>
          <FormGroup>
            <Label>Verification Code *</Label>
            <Input
              id="verificationcode"
              name="verificationcode"
              placeholder="Enter code"
              type="text"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="new_password">New Password: </Label>
            <Input
              id="new_password"
              name="new_password"
              placeholder="Enter new password"
              type="password"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <Button
            color="primary"
            size="lg"
            style={actionButtonStyle}
            disabled={!formIsValid}
            onClick={() => resetPassword()}
          >
            Submit
          </Button>
          <Button
            outline
            color="secondary"
            size="lg"
            style={cancelButtonStyle}
            onClick={() => cancelResetPassword()}
          >
            Cancel
          </Button>
        </Col>
        {processing ?
          (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Resetting password...
            </span>
          ) :
          null
        }
        <Col>
          <Label>{error}</Label>
        </Col>
      </Form>
    </Container>
  </div>
);


AppResetPasswordForm.propTypes = {
  processing: bool.isRequired,
  formIsValid: bool.isRequired,
  handleChange: func.isRequired,
  resetPassword: func.isRequired,
  cancelResetPassword: func.isRequired,
  error: string
}

AppResetPasswordForm.defaultProps = {
  error: '',
}

export default AppResetPasswordForm;
