import moment from "moment";
import { loadLocations } from "./locationActions";
import findObject from "../api/lookupObjectApi";
import { persistCheckIn, deleteCheckIn } from "../api/checkinApi";

export const INITIALIZE_CHECKINS = "INITIALIZE_CHECKINS";
export const UPDATE_CHECKIN_FORM_STATE = "UPDATE_CHECKIN_FORM_STATE";
export const CHECKIN_LOOKUP_OBJECT_REQUESTED =
  "CHECKIN_LOOKUP_OBJECT_REQUESTED";
export const CHECKIN_LOOKUP_OBJECT_SUCCESS = "CHECKIN_LOOKUP_OBJECT_SUCCESS";
export const CHECKIN_LOOKUP_OBJECT_FAILURE = "CHECKIN_LOOKUP_OBJECT_FAILURE";

export const CHECKIN_LOOKUP_LOCATION_REQUESTED =
  "CHECKIN_LOOKUP_LOCATION_REQUESTED";
export const CHECKIN_LOOKUP_LOCATION_SUCCESS =
  "CHECKIN_LOOKUP_LOCATION_SUCCESS";
export const CHECKIN_LOOKUP_LOCATION_FAILURE =
  "CHECKIN_LOOKUP_LOCATION_FAILURE";

export const CHECKIN_OBJECT_REQUESTED = "CHECKIN_OBJECT_REQUESTED";
export const CHECKIN_OBJECT_SUCCESS = "CHECKIN_OBJECT_SUCCESS";
export const CHECKIN_OBJECT_FAILURE = "CHECKIN_OBJECT_FAILURE";

export const DELETE_CHECKIN_REQUESTED = "DELETE_CHECKIN_REQUESTED";
export const DELETE_CHECKIN_SUCCESS = "DELETE_CHECKIN_SUCCESS";
export const DELETE_CHECKIN_FAILURE = "DELETE_CHECKIN_FAILURE";

export const REFRESH_CHECKEDIN_OBJECTS = "REFRESH_CHECKEDIN_OBJECTS";
export const REMOVE_PENDING_CHECKIN = "REMOVE_PENDING_CHECKIN";

export const initializeCheckIns = () => ({
  type: INITIALIZE_CHECKINS
});

const lookupObjectRequested = () => ({
  type: CHECKIN_LOOKUP_OBJECT_REQUESTED
});

const lookupObjectSuccess = (object, checkInDate) => ({
  type: CHECKIN_LOOKUP_OBJECT_SUCCESS,
  payload: object,
  checkInDate
});

const lookupObjectFailure = err => ({
  type: CHECKIN_LOOKUP_OBJECT_FAILURE,
  payload: err
});

const lookupLocationRequested = () => ({
  type: CHECKIN_LOOKUP_LOCATION_REQUESTED
});

const lookupLocationSuccess = (location, objectId) => ({
  type: CHECKIN_LOOKUP_LOCATION_SUCCESS,
  payload: location,
  objectId
});

const lookupLocationFailure = err => ({
  type: CHECKIN_LOOKUP_LOCATION_FAILURE,
  payload: err
});

const checkInObjectRequested = () => ({
  type: CHECKIN_OBJECT_REQUESTED
});

const checkInObjectSuccess = checkInObject => ({
  type: CHECKIN_OBJECT_SUCCESS,
  payload: checkInObject
});

const checkInObjectFailure = err => ({
  type: CHECKIN_OBJECT_FAILURE,
  payload: err
});

const deleteCheckInObjectRequested = checkInId => ({
  type: DELETE_CHECKIN_REQUESTED,
  checkInId

});

const deleteCheckInObjectSuccess = checkInId => ({
  type: DELETE_CHECKIN_SUCCESS,
  checkInId
});

const deleteCheckInObjectFailure = err => ({
  type: DELETE_CHECKIN_FAILURE,
  payload: err
});

// const refreshCheckedInObjects = (checkedInObject) => ({
//   type: REFRESH_CHECKEDIN_OBJECTS,
//   payload: checkedInObject
// })

export const updateFormFieldValue = (id, value) => ({
  type: UPDATE_CHECKIN_FORM_STATE,
  id,
  value
});

export const removePendingCheckIn = () => ({
  type: REMOVE_PENDING_CHECKIN
});

const checkInObject = () => async (dispatch, getState) => {
  dispatch(checkInObjectRequested);
  const { currentCheckInObject } = getState().checkin;

  try {
    const response = await persistCheckIn(currentCheckInObject);
    if (response.status) {
      dispatch(checkInObjectSuccess(currentCheckInObject));
    }
    else {
      dispatch(
        checkInObjectFailure("Error saving check-in.  Please try again later.")
      );
    }
  }
  catch (err) {
    dispatch(checkInObjectFailure(err));
  }
};

export const lookupObject = identifier => async (dispatch, getState) => {
  dispatch(lookupObjectRequested());

  try {
    const { tenantId: clientId } = getState().system;
    const { checkInDate } = getState().checkin;

    const response = await findObject(identifier, clientId);

    await dispatch(
      lookupObjectSuccess(response, moment(checkInDate).format("MM/DD/YYYY"))
    );

    return true;
  }
  catch (err) {
    dispatch(lookupObjectFailure(err));
    return false;
  }
};

export const lookupLocation = locationId => async (dispatch, getState) => {
  dispatch(lookupLocationRequested());

  try {
    let { locations } = getState().location;
    if (locations.length === 0) {
      await dispatch(loadLocations());
      locations = getState().location.locations;
    }

    const response = locations.filter(x => x.locationId === locationId);
    if (response.length > 0) {
      const { currentCheckInObject } = getState().checkin;

      await dispatch(
        lookupLocationSuccess(response[0]),
        currentCheckInObject.objectId
      );
      dispatch(checkInObject());
      return true;
    }
    else {
      dispatch(lookupLocationFailure(`Location ${locationId} not found.`));
      return false;
    }
  }
  catch (err) {
    dispatch(lookupLocationFailure(err));
  }
};

export const removeCheckIn = checkInId => async (dispatch, getState) => {
  dispatch(deleteCheckInObjectRequested(checkInId));

  const { checkedInObjects } = getState().checkin;

  const checkIn = checkedInObjects.find(x => x.checkInId === checkInId);
  if (!checkIn) {
    dispatch(deleteCheckInObjectFailure("checkin not found"));
    return;
  }

  try {
    const response = await deleteCheckIn(checkIn);

    if (response.status) {
      dispatch(deleteCheckInObjectSuccess(checkInId));
    }
    else {
      dispatch(deleteCheckInObjectFailure("Error checking in object"));
    }
  }
  catch (err) {
    dispatch(deleteCheckInObjectFailure(err));
  }

};
