import {
  UPDATE_USER_PROFILE,
  DELETE_USER_PROFILE,
  LOGOUT_SUCCESS
} from '../actions/authActions';

const initialState = {
  profile: {
    name: "",
    tenant_id: "",
    sub: "",
    email: "",
    first_name: "",
    last_name: "",
    role: "",
    tier: ""
  }

};

const profileReducer = (state = initialState, action) => {
  switch(action.type) {
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        profile: action.payload
      };

    case DELETE_USER_PROFILE:
      return {
        ...state,
        profile: {
          ...initialState.profile
        }
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        profile: {
          name: ''
        }
      };

    default:
      return state;
  }
}

export default profileReducer;

