export const noteRowStyle = {
  display: "flex",
  marginBottom: "15px"
};

export const notesLabelStyle = {
  color: "black",
  fontWeight: "600",
  width: "180px"
};

export const tagsValueStyle = {
  color: "black",
  textTransform: ""
};

export const viewObjectRowStyle = {
  paddingBottom: "30px"
};

export const backToSearchResultsStyle = {
  ...viewObjectRowStyle,
  textDecoration: "underline",
  paddingTop: "8px"
};

export const viewObjectTitleStyle = {
  borderBottom: "solid 1px #808080",
  paddingBottom: "30px",
  marginBottom: "30px"
};

export const notesColStyle = {
  marginTop: "30px"
};


export const relatedItemsRowStyle = {
  display: "flex",
  paddingBottom: "36px",
  marginBottom: "36px",
  borderBottom: "solid 1px #808080"
};
