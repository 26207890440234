import React, { Component } from "react";
import { string, bool, func, shape } from "prop-types";
import { Link } from "react-router-dom";
import { fetchImage } from "../../api/imagesApi";
import inventoryIdFormat from "../../tools/objectFormatters";
import { ImageNumber } from "../../api/constants";

const noImageStyle = {
  backgroundColor: "#EDEDED",
  margin: "0 auto"
};

// const objectStyle = {
//   marginTop: "10px",
//   paddingRight: "26px",
//   position: "relative"
// };

const objectStyle = {
  textAlign: "center",
  marginBottom: "35px"
};

const inventoryIdStyle = {
  color: "#212529",
  fontWeight: "700",
  fontSize: "17px",
  marginTop: "15px"
};

const designerStyle = {
  fontSize: "15px",
  color: "#212529"
};

const objectTypeStyle = {
  fontSize: "13px",
  color: "#707070",
  marginBottom: "15px",
  marginTop: "5px"
};

const xButtonStyle = {
  position: "absolute",
  left: "6px",
  top: "-3px",
  backgroundColor: "white",
  color: "#A3A3A3",
  height: "20px",
  width: "20px",
  textAlign: "center",
  fontSize: "26px",
  lineHeight: "22px",
  cursor: "pointer",
  paddingLeft: "2px"
};

class MySavedObjectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null
    };
  }

  async componentDidMount() {
    const { clientId, object } = this.props;

    this.setState({
      loading: true
    });

    const objImage = await fetchImage(
      clientId,
      object.objectId,
      ImageNumber.SearchImage
    );

    if (objImage) {
      this.setState({
        loading: false,
        image: objImage.Body
      });
    }
    else {
      this.setState({
        loading: false,
        image: null
      });
    }
  }

  render() {
    const { object, allowLink, showXButton, handleRemove } = this.props;

    const objectUrl = `/object/${object.objectId}`;

    let { image, loading } = this.state;

    if (loading) {
      return (
        <span>
          <i className="fa fa-spin fa-spinner" />
        </span>
      );
    }
    else if (image) {
      if (allowLink) {
        return (
          <div>
            <div>
              {showXButton ? (
                <div
                  style={xButtonStyle}
                  aria-hidden
                  role="button"
                  onClick={handleRemove}
                >
                  &times;
                </div>
              ) : null}
            </div>

            <Link to={objectUrl} className="url-button">
              <div style={objectStyle}>
                <img
                  src={`data:image/jpeg;base64,${btoa(
                    String.fromCharCode(...image.data)
                  )}`}
                  className="view-item-image"
                  alt="object"
                />
                <h4 style={inventoryIdStyle}>
                  {inventoryIdFormat(object.inventoryId)}
                </h4>
                <h5 style={designerStyle}>{object.designer}</h5>
                <h6 style={objectTypeStyle}>{object.objectType}</h6>
              </div>
            </Link>
          </div>
        );
      }
      else {
        return (
          <div style={objectStyle}>
            {showXButton ? (
              <div
                style={xButtonStyle}
                aria-hidden
                role="button"
                onClick={handleRemove}
              >
                &times;
              </div>
            ) : null}
            <img
              src={`data:image/jpeg;base64,${btoa(
                String.fromCharCode(...image.data)
              )}`}
              className="view-item-image"
              alt="object"
            />
            <h4 style={inventoryIdStyle}>
              {inventoryIdFormat(object.inventoryId)}
            </h4>
            <h5 style={designerStyle}>{object.designer}</h5>
            <h6 style={objectTypeStyle}>{object.objectType}</h6>
          </div>
        );
      }
    }
    else {
      if (allowLink) {
        return (
          <div style={objectStyle}>
            <Link to={objectUrl} className="url-button">
              <div 
                className="no-image-div" 
                style={noImageStyle} 
              />
              <h4 style={inventoryIdStyle}>
                {inventoryIdFormat(object.inventoryId)}
              </h4>
              <h5 style={designerStyle}>{object.designer}</h5>
              <h6 style={objectTypeStyle}>{object.objectType}</h6>
            </Link>
          </div>
        );
      }
      else {
        return (
          <div style={objectStyle}>
            <div 
              className="no-image-div" 
              style={noImageStyle}  
            />
            <h4 style={inventoryIdStyle}>
              {inventoryIdFormat(object.inventoryId)}
            </h4>
            <h5 style={designerStyle}>{object.designer}</h5>
            <h6 style={objectTypeStyle}>{object.objectType}</h6>
          </div>
        );
      }
    }
  }
}

MySavedObjectCard.propTypes = {
  clientId: string.isRequired,
  object: shape({
    objectId: string,
    objectType: string,
    inventoryId: string,
    designer: string
  }).isRequired,
  allowLink: bool,
  showXButton: bool,
  handleRemove: func
};

MySavedObjectCard.defaultProps = {
  allowLink: false,
  showXButton: false,
  handleRemove: null
};

export default MySavedObjectCard;
