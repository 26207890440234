import { v4 } from "node-uuid";
import moment from "moment";
import { doFetch } from "./index";
import { shipment as newShipment } from "../models/shipment";

export const createShipment = selectedObjects => {
  return {
    ...newShipment,
    shipmentId: v4(),
    clientId: selectedObjects[0].clientId,
    clientName: selectedObjects[0].clientName,
    dateRequested: moment(new Date()).format("YYYY-MM-DD"),
    status: "Pending",
    objects: [
      ...selectedObjects.map(x => ({
        objectId: x.objectId,
        clientId: selectedObjects[0].clientId,
        inventoryId: x.inventoryId,
        designer: x.designer,
        objectType: x.objectType
      }))
    ]
  };
};

export const fetchRequestedObjects = async () => {
  const path = `/requests`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
};

export const fetchShipmentList = async (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return {};
  }

  const path = `/shipments?fromDate=${moment(fromDate).format(
    "YYYY-MM-DD"
  )}&toDate=${moment(toDate).format("YYYY-MM-DD")}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
};

export const fetchShipment = async shipmentId => {
  if (!shipmentId) {
    return {};
  }

  const path = `/shipments/${shipmentId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);
  return response;
};

export const persistShipment = async shipment => {
  if (!shipment) {
    return false;
  }

  let method = "PUT";
  if (shipment.dateCreated === undefined) {
    method = "POST";
  }

  const path = `/shipments`;

  const params = {
    method: method,
    headers: {},
    body: JSON.stringify(shipment)
  };

  const response = await doFetch(path, params);

  return response;
};
