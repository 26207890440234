import "@babel/polyfill";
import { fetchSystemLists, persistSystemList } from "../api/systemApi";
import { fetchClientList } from "../api/clientApi";
import { fetchLocations } from "../api/locationApi";

export const LOAD_SYSTEM_LISTS_REQUESTED = "LOAD_SYSTEM_LISTS_REQUESTED";
export const LOAD_SYSTEM_LISTS_SUCCESS = "LOAD_SYSTEM_LISTS_SUCCESS";
export const LOAD_SYSTEM_LISTS_FAILURE = "LOAD_SYSTEM_LISTS_FAILURE";
export const LOAD_SYSTEM_CLIENT_LIST_REQUESTED =
  "LOAD_SYSTEM_CLIENT_LIST_REQUESTED";

export const LOAD_SYSTEM_CLIENT_LIST_SUCCESS =
  "LOAD_SYSTEM_CLIENT_LIST_SUCCESS";
export const LOAD_SYSTEM_CLIENT_LIST_FAILURE = "LOAD_SYSTEM_CLIENT_LIST_FAILURE";

export const LOAD_LOCATION_LIST_REQUESTED = "LOAD_LOCATION_LIST_REQUESTED";
export const LOAD_LOCATION_LIST_SUCCESS = "LOAD_LOCATION_LIST_SUCCESS";
export const LOAD_LOCATION_LIST_FAILURE = "LOAD_LOCATION_LIST_FAILURE";


export const SET_CLIENT_ID = "SET_CLIENT_ID";
export const ADD_SYSTEMLIST_VALUE = "ADD_SYSTEMLIST_VALUE"
export const SAVE_SYSTEM_LIST_REQUESTED = "SAVE_SYSTEM_LIST_REQUESTED";
export const SAVE_SYSTEM_LIST_SUCCESS = "SAVE_SYSTEM_LIST_SUCCESS";
export const SAVE_SYSTEM_LIST_FAILURE = "SAVE_SYSTEM_LIST_FAILURE";

const saveSystemListRequested = () => ({
  type: SAVE_SYSTEM_LIST_REQUESTED
})

const saveSystemListSuccess = (listName, listValues) => ({
  type: SAVE_SYSTEM_LIST_SUCCESS,
  listName,
  listValues
})

const saveSystemListFailure = (err) => ({
  type: SAVE_SYSTEM_LIST_FAILURE,
  payload: err
})

export const addSystemListValue = (listName, value) => ({
  type: ADD_SYSTEMLIST_VALUE,
  listName,
  value
});

const loadSystemListsRequested = () => ({
  type: LOAD_SYSTEM_LISTS_REQUESTED
});

const loadSystemListsSuccess = lists => ({
  type: LOAD_SYSTEM_LISTS_SUCCESS,
  payload: lists
});

const loadSystemClientListRequested = () => ({
  type: LOAD_SYSTEM_CLIENT_LIST_REQUESTED,
});

const loadSystemClientListSuccess = clients => ({
  type: LOAD_SYSTEM_CLIENT_LIST_SUCCESS,
  payload: clients
});

const loadSystemClientListFailure = err => ({
  type: LOAD_SYSTEM_CLIENT_LIST_FAILURE,
  payload: err
});

const loadLocationListRequested = () => ({
  type: LOAD_LOCATION_LIST_REQUESTED
});

const loadLocationListSuccess = locations => ({
  type: LOAD_LOCATION_LIST_SUCCESS,
  payload: locations
});

const loadLocationListFailure = err => ({
  type: LOAD_LOCATION_LIST_FAILURE,
  payload: err
});



const loadSystemListsFailure = error => ({
  type: LOAD_SYSTEM_LISTS_FAILURE,
  payload: error
});

const setNewTenantId = (tenantId, clientName) => ({
  type: SET_CLIENT_ID,
  tenantId,
  clientName
});

export const setTenantId = (tenantId, clientName) => (dispatch, getState) => {
 const { tenantId: clientId } = getState().system;
 if (tenantId !== clientId) {
   dispatch(setNewTenantId(tenantId, clientName));
 }
};

export const loadSystemClientList = () => async dispatch => {
  dispatch(loadSystemClientListRequested());

  try {
    const clients = await fetchClientList();

    dispatch(loadSystemClientListSuccess(clients.map(x => ({
      clientId: x.clientId,
      clientName: x.clientName
    }))));
  }
  catch (err) {
    dispatch(loadSystemClientListFailure(err));
  }

}

export const loadLocationList = () => async dispatch => {
  dispatch(loadLocationListRequested());

  try {
    const data = await fetchLocations();
    dispatch(loadLocationListSuccess(data));
  }
  catch (err) {
    dispatch(loadLocationListFailure(err));
  }

}

export const loadSystemLists = tenantId => async dispatch => {
  dispatch(loadSystemListsRequested());
  try {

    const data = await fetchSystemLists(tenantId);
    dispatch(loadSystemListsSuccess(data));

  }
  catch (err) {
    dispatch(loadSystemListsFailure(err));
  }
};

export const saveSystemList = (id, listValues) => async dispatch => {
  dispatch(saveSystemListRequested());

  try {
    const response = await persistSystemList(id, listValues);
    if (response.status === true){
      dispatch(saveSystemListSuccess(id, listValues));
    }
    else {
      dispatch(saveSystemListFailure("Error updating dynamodb"));
    }

  }
  catch (err) {
    dispatch(saveSystemListFailure(err));
  }
}
