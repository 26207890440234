import React from 'react';
import {
  Button,
} from 'reactstrap';
import { func } from 'prop-types';

const addNewObjectStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '30px',
}

const addNewObjectButtonStyle = {
  backgroundColor: '#344D5D',
  textTransform: 'uppercase',
  padding: '16px',
  fontFamily: 'proxima-nova',
  fontWeight: '600px',
}

const AddNew = ({handleAddObjectClick}) => (
  <div className="add-new-object" style={addNewObjectStyle}>
    <h2>Search</h2>
    <div>
      <Button onClick={handleAddObjectClick} style={addNewObjectButtonStyle}>Add New Object</Button>
    </div>
  </div>
);

AddNew.propTypes = {
  handleAddObjectClick: func.isRequired,
}

export default AddNew;
