import React from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import MyArchivePage from "./MyArchivePage";
import AdminDashboard from "./AdminDashboard";

const HomePage = (props) => {
  const { userRole } = props;

  if (userRole === "User") {
    return (
      <MyArchivePage />
    )
  }
  else if (
    userRole === "System Administrator" ||
    userRole === "Digital Archivist Administrator" ||
    userRole === "Digital Archivist User"
    ) {
      return <AdminDashboard />
  }
  else {
    return "ERROR"
  }

}

HomePage.propTypes = {
  userRole: string,
};

HomePage.defaultProps = {
  userRole: ""
};

const mapStateToProps = state => {
  return {
    userRole: state.user.profile.role,
  };
};

export default connect(mapStateToProps)(HomePage);
