import {
  SEARCH_OBJECTS_REQUESTED,
  SEARCH_OBJECTS_SUCCESS,
  SEARCH_OBJECTS_FAILURE,
  CHANGE_SEARCH_TERM,
  REQUEST_OBJECT_REQUESTED,
  REQUEST_OBJECT_SUCCESS,
  REQUEST_OBJECT_FAILURE,
  REMOVE_OBJECT_REQUESTED,
  REMOVE_OBJECT_SUCCESS,
  REMOVE_OBJECT_FAILURE,
} from '../actions/searchActions';
import {
  SAVE_MYARCHIVE_REQUESTED,
  SAVE_MYARCHIVE_SUCCESS,
  SAVE_MYARCHIVE_FAILURE,
  REMOVE_ALL_MYARCHIVE_SUCCESS
} from "../actions/myArchiveActions";

import { SAVE_SHIPMENT_SUCCESS } from '../actions/shipmentActions';
import { SET_CLIENT_ID } from '../actions/systemActions';
import { LOGOUT_SUCCESS } from '../actions/authActions';

const initialState = {
  searchTerm: '',
  requestedObjects: [],
  refreshObjectImages: [],
  loading: false,
  processing: [],
  savedObjects: [],
  lastUpdated: 0,
  items:[],
  error: null
}

const searchReducer = (state = initialState, action) => {
  switch(action.type) {
    case CHANGE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      }

    case SEARCH_OBJECTS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case SEARCH_OBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload
      }

    case SEARCH_OBJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      }

    case REQUEST_OBJECT_REQUESTED:
      return {
        ...state,
        processing: [
          ...state.processing.filter(x => x!== action.objectId),
          action.objectId
        ]
      }

    case REQUEST_OBJECT_SUCCESS:
      return {
        ...state,
        requestedObjects: [...state.requestedObjects.filter(x => x !== action.objectId), action.objectId],
        processing: [
          ...state.processing.filter(x => x!== action.objectId)
        ]
      }

    case REQUEST_OBJECT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        processing: [
          ...state.processing.filter(x => x!== action.objectId)
        ]
      }

    case REMOVE_OBJECT_REQUESTED:
      return {
        ...state,
        processing: [
          ...state.processing.filter(x => x!== action.objectId),
          action.objectId
        ]
      }

    case REMOVE_OBJECT_SUCCESS:
      return {
        ...state,
        requestedObjects: [...state.requestedObjects.filter(x => x != action.objectId)],
        processing: [
          ...state.processing.filter(x => x!== action.objectId)
        ]
      }

    case REMOVE_OBJECT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        processing: [
          ...state.processing.filter(x => x!== action.objectId)
        ]
      }

    case REMOVE_ALL_MYARCHIVE_SUCCESS:
      return {
        ...state,
        processing: [],
        savedObjects: []
      }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        searchTerm: '',
        requestedObjects: [],
        savedObjects: [],
        loading: false,
        processing: [],
        items: []
      };

    case SET_CLIENT_ID:
      return {
        ...state,
        searchTerm: '',
        items: [],
        requestedObjects: []
      };

    case SAVE_SHIPMENT_SUCCESS:
      return {
        ...state,
        requestedObjects: [...state.requestedObjects.filter(guid => {
          action.payload.objects.find(obj => obj.objectId === guid) === undefined
        })]
      }

   case SAVE_MYARCHIVE_REQUESTED:
      return {
        ...state,
        processing: [
          ...state.processing.filter(x => x !== action.objectId),
          action.objectId
        ]
      };

    case SAVE_MYARCHIVE_SUCCESS:
      return {
        ...state,
        processing: [
          ...state.processing.filter(x => x !== action.payload.objectId)
        ],
        error: null,
      };

    case SAVE_MYARCHIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
        processing: [
          ...state.search.filter(x => x!== action.objectId)
        ]
      };

    default:
      return state;
  }
}

export default searchReducer;


