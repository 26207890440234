import React, { Component } from "react";
import { string, number, bool, func } from "prop-types";
import { Link } from "react-router-dom";
import { fetchImage } from "../../api/imagesApi";
import { ImageNumber } from "../../api/constants";
import inventoryIdFormat from "../../tools/objectFormatters";
import RemoveObjectIcon from "../../assets/images/icons/Remove-Object-Icon.png";

const noImageStyle = {
  height: "225px",
  width: "150px",
  backgroundColor: "#EDEDED",
  margin: "0 auto"
};

const relatedObjectStyle = {
  marginTop: "10px",
  paddingRight: "26px",
  position: "relative"
};

const relatedObjectLinkStyle = {
  color: "#1D1D1D"
};

const relatedObjectDesignerStyle = {
  fontWeight: 400
};

const removeButtonStyle = {
  position: "absolute",
  right: "0px",
  backgroundColor: "white",
  color: "#A3A3A3",
  height: "20px",
  width: "20px",
  textAlign: "center",
  fontSize: "26px",
  lineHeight: "22px",
  cursor: "pointer",
  paddingLeft: "2px"
};

const xButtonStyle = {
  position: "absolute",
  right: "6px",
  top: "-3px",
  backgroundColor: "white",
  color: "#A3A3A3",
  height: "20px",
  width: "20px",
  textAlign: "center",
  fontSize: "26px",
  lineHeight: "22px",
  cursor: "pointer",
  paddingLeft: "2px"
};

class ViewRelatedItemImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null
    };
  }

  async componentDidMount() {
    const { clientId, objectId, imageNumber } = this.props;

    this.setState({
      loading: true
    });

    const objImage = await fetchImage(clientId, objectId, imageNumber);

    if (objImage) {
      this.setState({
        loading: false,
        image: objImage.Body
      });
    }
    else {
      this.setState({
        loading: false,
        image: null
      });
    }
  }

  render() {
    const {
      objectId,
      inventoryId,
      designer,
      allowLink,
      showXButton,
      handleRemove
    } = this.props;

    const objectUrl = `/object/${objectId}`;

    let { image, loading } = this.state;

    if (loading) {
      return (
        <span>
          <i className="fa fa-spin fa-spinner" />
        </span>
      );
    }
    else if (image) {
      if (allowLink) {
        return (
          <Link
            to={objectUrl}
            className="url-button"
            style={relatedObjectLinkStyle}
          >
            <div style={relatedObjectStyle}>
              {showXButton ? (
                <div
                  style={xButtonStyle}
                  aria-hidden
                  role="button"
                  onClick={handleRemove}
                >
                  &times;
                </div>
              ) : null}
              <img
                src={`data:image/jpeg;base64,${btoa(
                  String.fromCharCode(...image.data)
                )}`}
                className="view-item-image"
                alt="object"
              />
              <h4>{inventoryIdFormat(inventoryId)}</h4>
              <h5 style={relatedObjectDesignerStyle}>{designer}</h5>
            </div>
          </Link>
        );
      }
      else {
        return (
          <div style={relatedObjectStyle}>
            {/* {showXButton ? (
              <div
                style={xButtonStyle}
                aria-hidden
                role="button"
                onClick={handleRemove}
              >
                &times;
              </div>) : null} */}
            {showXButton ? (
              <div>
                <img
                  src={RemoveObjectIcon}
                  style={removeButtonStyle}
                  onClick={handleRemove}
                  aria-hidden
                  alt="remove related object"
                />
              </div>

            ) : null}
            <img
              src={`data:image/jpeg;base64,${btoa(
                String.fromCharCode(...image.data)
              )}`}
              className="view-item-image"
              alt="object"
            />
            <h4>{inventoryIdFormat(inventoryId)}</h4>
            <h5 style={relatedObjectDesignerStyle}>{designer}</h5>
          </div>
        );
      }
    }
    else {
      if (allowLink) {
        return (
          <div style={relatedObjectStyle}>
            <Link
              to={objectUrl}
              className="url-button"
              style={relatedObjectLinkStyle}
            >
              <div style={noImageStyle} />
              <h4>{inventoryIdFormat(inventoryId)}</h4>
              <h5 style={relatedObjectDesignerStyle}>{designer}</h5>
            </Link>
          </div>
        );
      }
      else {
        return (
          <div style={relatedObjectStyle}>
            <div style={noImageStyle} />
            <h4>{inventoryIdFormat(inventoryId)}</h4>
            <h5 style={relatedObjectDesignerStyle}>{designer}</h5>
          </div>
        );
      }
    }
  }
}

ViewRelatedItemImage.propTypes = {
  clientId: string.isRequired,
  objectId: string.isRequired,
  inventoryId: string,
  designer: string,
  imageNumber: number,
  allowLink: bool,
  showXButton: bool,
  handleRemove: func
};

ViewRelatedItemImage.defaultProps = {
  inventoryId: "",
  designer: "",
  imageNumber: ImageNumber.SearchImage,
  allowLink: false,
  showXButton: false,
  handleRemove: null
};

export default ViewRelatedItemImage;
