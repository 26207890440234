import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, arrayOf, shape, bool } from "prop-types";
import { Table, Input, Label, Col, Row, Button } from "reactstrap";
import {
  loadSystemUserList,
  changeSystemUserFilter,
  editSystemUser
} from "../../actions/systemUserActions";
import { addSystemUser } from "../../actions/userActions";
import EditUserPage from "../clients/EditUserPage";
import { userShape } from "../../models/user";
import editIcon from "../../assets/images/edit-icon.png";
import {
  filterPageHeaderStyle,
  filterHeaderStyle,
  filterFormButtonActionStyle,
  filterLabelStyle,
  tableHeaderStyle,
  tableButtonStyle,
} from "../../styles/formStyles";


class SystemUsersPage extends Component {
  componentDidMount = () => {
    const { loadSystemUserList } = this.props;
    loadSystemUserList();
  };

  handleFilterChange = e => {
    e.preventDefault();
    const { changeSystemUserFilter } = this.props;

    changeSystemUserFilter(e.target.id, e.target.value);
  };

  handleAddUser = e => {
    e.preventDefault();
    const { addSystemUser } = this.props;
    addSystemUser();
  };

  handleEditUser = userId => {
    const { editSystemUser } = this.props;
    editSystemUser(userId);
  };

  render() {
    const { userRole, filter, users, loading } = this.props;

    if (!(userRole === "System Administrator" || userRole === "Digital Archivist Administrator")) {
      return (
        <div>
          <h2>Unauthorized</h2>
          Please contact the system administrator.
        </div>
      );
    }

    return (
      <div className="system-users">
        <div className="system-users-header" style={filterPageHeaderStyle}>
          <h2>System Users</h2>
          <div>
            <Button
              onClick={this.handleAddUser}
              style={filterFormButtonActionStyle}
            >
              Add User
            </Button>
          </div>
        </div>

        <div className="system-users-filter" style={filterHeaderStyle}>
          <Row>
            <Col sm={4}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by User Name
              </Label>
              <Input
                type="text"
                id="fullName"
                value={filter.fullName}
                onChange={this.handleFilterChange}
              />
            </Col>
          </Row>
        </div>

        <div className="system-users-table">
          <Table hover responsive>
            <thead style={tableHeaderStyle}>
              <tr>
                <th>User</th>
                <th>Login</th>
                <th>Role</th>
                <th>{null}</th>
              </tr>
            </thead>
            <tbody>
              {users
                .filter(
                  item =>
                    item.fullName
                      .toUpperCase()
                      .indexOf(filter.fullName.toUpperCase()) >= 0
                )
                .map(item => (
                  <tr key={item.userId}>
                    <td>{item.fullName}</td>
                    <td>{item.email}</td>
                    <td>{item.role}</td>
                    <td>
                      <div
                        className="editButton"
                        role="button"
                        aria-hidden
                        style={tableButtonStyle}
                        onClick={e => {
                          e.preventDefault();
                          this.handleEditUser(item.userId);
                        }}
                      >
                        <img src={editIcon} alt="Edit User" />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="Loading">
          {loading === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Loading...
            </span>
          ) : null}
        </div>
        <EditUserPage />
      </div>
    );
  }
}

SystemUsersPage.propTypes = {
  userRole: string.isRequired,
  filter: shape({
    fullName: string
  }).isRequired,
  loading: bool.isRequired,
  users: arrayOf(userShape).isRequired,
  changeSystemUserFilter: func.isRequired,
  loadSystemUserList: func.isRequired,
  addSystemUser: func.isRequired,
  editSystemUser: func.isRequired
};

const mapStateToProps = state => {
  return {
    users: state.systemUsers.users,
    userRole: state.user.profile.role,
    filter: state.systemUsers.filter,
    loading: state.systemUsers.loading
  };
};

export default connect(
  mapStateToProps,
  {
    loadSystemUserList,
    addSystemUser,
    editSystemUser,
    changeSystemUserFilter
  }
)(SystemUsersPage);
