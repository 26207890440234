import React from "react";
import { string, number, func } from "prop-types";
import { Col, Button } from "reactstrap";
import { buttonUrlLinkStyle } from "../../styles/formStyles";

const imageStyle = {
  width: "100%"
};

const imageLinkStyle = {
  textDecoration: "underline",
  marginTop: "20px",
  marginBottom: "20px"
};

const thumbnailRowStyle = {
  display: "inline-flex"
};

const thumbnailBoxStyle = {
  width: "100px",
  height: "100px",
  cursor: "pointer"
};

const thumbnailImageStyle = {
  maxHeight: "100%"
};

const noImageStyle = {
  height: "477px",
  width: "100%",
  backgroundColor: "#EDEDED",
  margin: "0"
};

const ViewObjectImagePanel = ({
  image1,
  image2,
  thumbnailNumber,
  handleThumbnailClick,
  downloadImage
}) => (
  <Col sm={5}>
    {(thumbnailNumber === 1 && image1) ||
      (thumbnailNumber === 2 && image2) ? (
        <div>
          <img
            src={thumbnailNumber !== 2 ? image1 : image2}
            style={imageStyle}
            className="search-item-image"
            alt="object"
          />
          <div style={imageLinkStyle}>
            <Button style={buttonUrlLinkStyle} onClick={downloadImage}>
              Download Image
            </Button>
          </div>
        </div>
    ) : (
      <div style={noImageStyle} />
    )}

    {image2 ? (
      <div style={thumbnailRowStyle}>
        <div
          style={thumbnailBoxStyle}
          role="button"
          aria-hidden
          onClick={() => handleThumbnailClick(1)}
        >
          <img style={thumbnailImageStyle} src={image1} alt="Thumbnail 1" />
        </div>
        <div
          style={thumbnailBoxStyle}
          role="button"
          aria-hidden
          onClick={() => handleThumbnailClick(2)}
        >
          <img style={thumbnailImageStyle} src={image2} alt="Thumbnail 2" />
        </div>
      </div>
    ) : null}
  </Col>
);

ViewObjectImagePanel.propTypes = {
  image1: string,
  image2: string,
  thumbnailNumber: number,
  handleThumbnailClick: func.isRequired,
  downloadImage: func.isRequired,
};

ViewObjectImagePanel.defaultProps = {
  image1: null,
  image2: null,
  thumbnailNumber: 1
};

export default ViewObjectImagePanel;
