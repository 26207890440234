import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../main/HomePage';
import ViewObjectPage from '../main/ViewObjectPage';
import RequestObjectsPage from '../requests/RequestObjectsPage';
import MySavedObjectsPage from "../mySavedObjects/MySavedObjectsPage"
import ImageManagementPage from '../images/ImageManagementPage';
import CheckInObjectsPage from '../checkin/CheckInObjectsPage';
import LocationsPage from '../locations/LocationsPage'
import ClientsPage from '../clients/ClientsPage'
import SystemUsersPage from '../system-users/SystemUsersPage'
import ShipmentsPage from '../shipments/ShipmentsPage';
import ShipmentLogsPage from '../shipment-logs/ShipmentLogsPage';
import NotFoundPage from './NotFoundPage';
import ChangePasswordPage from "../auth/ChangePasswordPage"
import PrivacyPolicy from "../main/PrivacyPolicy";
import TermsOfUse from "../main/TermsOfUse";

const appBodyStyle = {
  width: '1140px', // Need media query
  maxWidth: '1140px',
  margin: '0 auto',
  paddingTop: '30px',
  marginTop: "89px",
  marginBottom: "4em",
  flexGrow: "1",
}

const PrimaryBody = () => (
  <div className="app-body" style={appBodyStyle}>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/my-saved-objects" component={MySavedObjectsPage} />
      <Route path="/object/:objectId" component={ViewObjectPage} />
      <Route exact path="/requests" component={RequestObjectsPage} />
      <Route exact path="/images" component={ImageManagementPage} />
      <Route exact path="/check-in" component={CheckInObjectsPage} />
      <Route exact path="/locations" component={LocationsPage} />
      <Route exact path="/clients" component={ClientsPage} />
      <Route exact path="/shipments" component={ShipmentsPage} />
      <Route exact path="/shipment-log" component={ShipmentLogsPage} />
      <Route exact path="/locations" component={LocationsPage} />
      <Route exact path="/system-users" component={SystemUsersPage} />
      <Route exact path="/change-password" component={ChangePasswordPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms-of-use" component={TermsOfUse} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  </div>
);

export default PrimaryBody;
