import "@babel/polyfill";
import moment from "moment";
import {
  createShipmentLog,
  persistShipmentLog,
  fetchShipmentLogs
} from "../api/shipmentLogApi";
import objectsAreEqual from "../tools/objectChecker";

export const INITIALIZE_SHIPMENT_LOG_FILTER = "INITIALIZE_SHIPMENT_LOG_FILTER";
export const SHOW_SHIPMENT_LOG_EDIT_FORM = "SHOW_SHIPMENT_LOG_EDIT_FORM";
export const CANCEL_SHIPMENT_LOG_EDIT_FORM = "CANCEL_SHIPMENT_LOG_EDIT_FORM";

export const LOAD_SHIPMENT_LOG_REQUESTED = "LOAD_SHIPMENT_LOG_REQUESTED";
export const LOAD_SHIPMENT_LOG_SUCCESS = "LOAD_SHIPMENT_LOG_SUCCESS";
export const LOAD_SHIPMENT_LOG_FAILURE = "LOAD_SHIPMENT_LOG_FAILURE";

export const LOAD_SHIPMENT_LOGS_REQUESTED = "LOAD_SHIPMENT_LOGS_REQUESTED";
export const LOAD_SHIPMENT_LOGS_SUCCESS = "LOAD_SHIPMENT_LOGS_SUCCESS";
export const LOAD_SHIPMENT_LOGS_FAILURE = "LOAD_SHIPMENT_LOGS_FAILURE";

export const SAVE_SHIPMENT_LOG_REQUESTED = "SAVE_SHIPMENT_LOG_REQUESTED";
export const SAVE_SHIPMENT_LOG_SUCCESS = "SAVE_SHIPMENT_LOG_SUCCESS";
export const SAVE_SHIPMENT_LOG_FAILURE = "SAVE_SHIPMENT_LOG_FAILURE";

export const CHECK_SHIPMENT_LOG_IS_VALID = "CHECK_SHIPMENT_LOG_IS_VALID";
export const CHECK_SHIPMENT_LOG_IS_DIRTY = "CHECK_SHIPMENT_LOG_IS_DIRTY";
export const UPDATE_SHIPMENT_LOG_FIELD_VALUE = "UPDATE_SHIPMENT_LOG_FIELD_VALUE";

export const ADD_SHIPMENT_LOG_REQUESTED = "ADD_SHIPMENT_LOG_REQUESTED";
export const EDIT_SHIPMENT_LOG_REQUESTED = "EDIT_SHIPMENT_LOG_REQUESTED";

export const CHANGE_SHIPMENT_LOG_FILTER = "CHANGE_SHIPMENT_LOG_FILTER";


const initializeShipmentLogFilterRequest = (fromDate, toDate) => ({
  type: INITIALIZE_SHIPMENT_LOG_FILTER,
  fromDate,
  toDate
});


const showEditForm = () => ({
  type: SHOW_SHIPMENT_LOG_EDIT_FORM
});

const saveShipmentLogRequested = () => ({
  type: SAVE_SHIPMENT_LOG_REQUESTED
});

const saveShipmentLogSuccess = shipmentLog => ({
  type: SAVE_SHIPMENT_LOG_SUCCESS,
  payload: shipmentLog
});

const saveShipmentLogFailure = error => ({
  type: SAVE_SHIPMENT_LOG_FAILURE,
  payload: { ...error }
});

export const changeShipmentLogFilterRequested = (id, value) => ({
  type: CHANGE_SHIPMENT_LOG_FILTER,
  id,
  value
});

export const cancelChanges = () => ({
  type: CANCEL_SHIPMENT_LOG_EDIT_FORM
});

export const addShipmentLogRequested = shipmentLog => ({
  type: ADD_SHIPMENT_LOG_REQUESTED,
  payload: shipmentLog
});

export const editShipmentLogRequested = shipmentLog => ({
  type: EDIT_SHIPMENT_LOG_REQUESTED,
  payload: shipmentLog
});

const showShipmentLogIsValid = valid => ({
  type: CHECK_SHIPMENT_LOG_IS_VALID,
  payload: valid
});

const updateShipmentLog = (id, value) => ({
  type: UPDATE_SHIPMENT_LOG_FIELD_VALUE,
  id,
  value
});

const loadShipmentLogsRequested = () => ({
  type: LOAD_SHIPMENT_LOGS_REQUESTED
});

const loadShipmentLogsSuccess = shipmentLogs => ({
  type: LOAD_SHIPMENT_LOGS_SUCCESS,
  payload: shipmentLogs
});

const loadShipmentLogsFailure = error => ({
  type: LOAD_SHIPMENT_LOGS_FAILURE,
  payload: error
});

export const updateShipmentLogFieldValue = (id, value) => async (
  dispatch,
  getState
) => {

  await dispatch(updateShipmentLog(id, value));

  if (id === "dateShipped" && moment(value, "YYYY-MM-DD", true).isValid()) {
    dispatch(updateShipmentLog("status", "Shipped"));
  }

  if (id === "dateReceived" && moment(value, "YYYY-MM-DD", true).isValid()) {
    dispatch(updateShipmentLog("status", "Received"));
  }

  const { editShipmentLog } = getState().shipmentLog;

  const isValid = validateShipmentLog(editShipmentLog);

  dispatch(showShipmentLogIsValid(isValid));

  dispatch(shipmentLogIsDirty());
};

const shipmentLogIsDirty = () => async (dispatch, getState) => {
  const { editShipmentLog, originalShipmentLog } = getState().shipmentLog;

  const isDirty = !objectsAreEqual(editShipmentLog, originalShipmentLog);

  await dispatch({
    type: CHECK_SHIPMENT_LOG_IS_DIRTY,
    isDirty
  });
};

export const loadShipmentLogs = (fromDate, toDate) => async dispatch => {
  dispatch(loadShipmentLogsRequested());
  try {
    const data = await fetchShipmentLogs(fromDate, toDate);

    dispatch(loadShipmentLogsSuccess(data));
  }
  catch (err) {
    dispatch(loadShipmentLogsFailure(err));
  }
};

export const addShipmentLog = () => async (dispatch, getState) => {
  const newShipmentLog = createShipmentLog();
  const { editFormIsOpen } = getState().shipmentLog;

  dispatch(addShipmentLogRequested(newShipmentLog));
  if (!editFormIsOpen) {
    await dispatch(showEditForm());
  }
};

export const editShipmentLog = shipmentLogId => async (dispatch, getState) => {
  const { shipmentLogs, editFormIsOpen } = getState().shipmentLog;
  const shipmentLog = shipmentLogs.filter(x => x.shipmentLogId === shipmentLogId)[0];
  if (shipmentLog) {
    dispatch(editShipmentLogRequested(shipmentLog));
    if (!editFormIsOpen) {
      await dispatch(showEditForm());
    }
  }
};

export const saveShipmentLog = () => async (dispatch, getState) => {
  const { editShipmentLog } = getState().shipmentLog;

  dispatch(saveShipmentLogRequested());

  try {

    const success = await persistShipmentLog(editShipmentLog);

    if (success) {
      dispatch(saveShipmentLogSuccess(editShipmentLog));
    }
  }
  catch (err) {
    dispatch(saveShipmentLogFailure(err));
  }
};

export const initializeShipmentLogFilter = () => dispatch => {
  const fromDate = moment(new Date())
    .subtract(30, "days")
    .toISOString()
    .slice(0, 10);
  const toDate = moment(new Date())
    .toISOString()
    .slice(0, 10);

  dispatch(initializeShipmentLogFilterRequest(fromDate, toDate));
};

export const changeShipmentLogFilter = (id, value) => (dispatch) => {
  dispatch(changeShipmentLogFilterRequested(id, value));

  if (!(id === "fromDate" || id === "toDate")) {
    return;
  }

  dispatch(loadShipmentLogList());
};

const loadShipmentLogList = () => (dispatch, getState) => {
  const { fromDate, toDate } = getState().shipmentLog.filter;

  // check if incomplete date field
  if (
    !moment(toDate, "YYYY-MM-DD").isValid() ||
    !moment(fromDate, "YYYY-MM-DD").isValid()
  ) {
    return;
  }

  dispatch(loadShipmentLogs(fromDate, toDate));
}

const validateShipmentLog = (shipmentLog) => {

  const isValid =
    !!shipmentLog.clientName &&
    shipmentLog.clientName.length > 0 &&
    !!shipmentLog.sender &&
    shipmentLog.sender.length > 0 &&
    !!shipmentLog.carrier &&
    shipmentLog.carrier.length > 0 &&
    !!shipmentLog.itemsInShipment &&
    shipmentLog.itemsInShipment.length > 0 &&
    !!shipmentLog.dateShipped &&
    shipmentLog.dateShipped.length > 0;

  return isValid
};
