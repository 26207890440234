import { v4 } from "node-uuid";
import { doFetch } from "./index";
import newArchiveObject from "../models/archiveObject";
import generateNineDigitId from "../tools/idGenerator";

export const createArchiveObject = (clientId, clientName) => {
  return {
    ...newArchiveObject,
    objectId: v4(),
    inventoryId: generateNineDigitId(),
    clientId,
    clientName
  }
};

export const fetchObject = async (objectId, clientId) => {

  if (!objectId) {
    return {};
  }

  const path = `/objects/${objectId}?clientId=${clientId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);
  return response;
};


export const persistObject = async archiveObject => {
  if (!archiveObject) {
    return false;
  }

  const path = `/objects`;

  const params = {
    method: "POST",
    headers: {},
    body: JSON.stringify(archiveObject)
  };

  const response = await doFetch(path, params);

  return response;
};
