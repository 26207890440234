import React from "react";
import Select from "react-select";
import { string, bool, func, arrayOf, shape, oneOfType } from "prop-types";
import CreatableSelect from "react-select/lib/Creatable";

const formSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#FFF" : provided.color,
    backgroundColor: state.isSelected ? "#344D5D" : provided.color,
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#344D5D",
      opacity: 0.45
    }
  }),
  control: base => ({
    ...base
  }),
  singleValue: provided => ({ ...provided, color: "black" })
};

const SelectInput = ({
  id,
  placeholder,
  handleChange,
  options,
  value,
  isSearchable,
  isClearable,
  isMulti,
  isCreatable
}) => {
  if (!isCreatable) {
    return (
      <Select
        id={id}
        name={id}
        placeholder={placeholder}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        value={value}
        onChange={(newValue, actionMeta) => handleChange(newValue, actionMeta)}
        styles={formSelectStyle}
      />
    );
  }
  else {
    return (
      <CreatableSelect
        id={id}
        name={id}
        placeholder={placeholder}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        value={value}
        onChange={(newValue, actionMeta) =>
          handleChange(newValue, actionMeta, id)
        }
        styles={formSelectStyle}
      />
    );
  }
};

SelectInput.propTypes = {
  id: string.isRequired,
  placeholder: string,
  isSearchable: bool,
  isClearable: bool,
  isMulti: bool,
  isCreatable: bool,
  value:
    oneOfType([
      shape({
        label: string,
        value: string
      }),
      arrayOf(
        shape({
          label: string,
          value: string
        })
      )
    ]),
  options: arrayOf(
    shape({
      label: string,
      value: string
    })
  ),
  handleChange: func.isRequired
};

SelectInput.defaultProps = {
  options: [],
  isSearchable: false,
  isClearable: false,
  isMulti: false,
  isCreatable: false,
  placeholder: "",
  value: null
};

export default SelectInput;
