import React from "react"
import moment from "moment";
import { string, bool } from "prop-types";
import ObjectViewRow from "./components/ObjectViewRow";

const checkinTitleStyle = {
  fontSize: "20px",
  fontWeight: "600",
  marginLeft: "4px"
};

const checkinSectionStyle = {
  paddingBottom: '36px',
  borderTop: "1px solid rgb(128, 128, 128)",
  paddingTop: "36px",
}

const hiddenDividerCheckinSectionStyle = {
  paddingBottom: '36px',
}

const ViewCheckin = ({checkinDate, checkinBy, location, hideDivider}) => (
  <div style={hideDivider ? hiddenDividerCheckinSectionStyle : checkinSectionStyle}>
    <h3 style={checkinTitleStyle}>Checked In</h3>
    <table className="view-object-checkin">
      <tbody>
        <ObjectViewRow
          label="Date Returned"
          value={moment(checkinDate).isValid ?
              moment(checkinDate).format("MM/DD/YYYY")
              : null
          }
        />
        <ObjectViewRow label="Checked In By" value={checkinBy} />
        <ObjectViewRow label="Location" value={location} />
      </tbody>
    </table>
  </div>
);


ViewCheckin.propTypes = {
  checkinDate: string.isRequired,
  checkinBy: string.isRequired,
  location: string.isRequired,
  hideDivider: bool,
}

ViewCheckin.defaultProps = {
  hideDivider: false
}

export  default ViewCheckin;

