import React from "react";
import { string, arrayOf, shape, func, bool } from "prop-types";
import { FormGroup, Label, Col } from "reactstrap";
import SelectInput from "../../common/SelectInput";
import { editFormRequiredFieldIndicatorStyle } from "../../../styles/formStyles";

const FormSelectInput = ({
  label,
  id,
  placeholder,
  isSearchable,
  isClearable,
  isMulti,
  isCreatable,
  value,
  multivalue,
  options,
  handleChange,
  required
}) => (
  <FormGroup row>
    <Label for={id} sm={3}>
      {label}
      {required ? (
        <span
          className="required-field-indicator"
          style={editFormRequiredFieldIndicatorStyle}
        >
          *
        </span>
      ) : null}
    </Label>
    <Col sm={9}>
      <SelectInput
        id={id}
        placeholder={placeholder}
        value={isMulti ? multivalue : value}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isCreatable={isCreatable}
        isMulti={isMulti}
        handleChange={(newValue, actionMeta, id) => handleChange(newValue, actionMeta, id)}
        options={options}
      />
    </Col>
  </FormGroup>
);

FormSelectInput.propTypes = {
  label: string.isRequired,
  id: string.isRequired,
  placeholder: string,
  isSearchable: bool,
  isClearable: bool,
  isMulti: bool,
  isCreatable: bool,
  multivalue: arrayOf(
    shape({
      label: string,
      value: string
    })
  ),
  value: shape({
    label: string,
    value: string
  }),
  options: arrayOf(
    shape({
      label: string,
      value: string
    })
  ),
  required: bool,
  handleChange: func.isRequired,
};

FormSelectInput.defaultProps = {
  required: false,
  isSearchable: false,
  isClearable: false,
  isCreatable: false,
  isMulti: false,
  options: [],
  placeholder: "",
  value: {
    label: "",
    value: "",
  },
  multivalue: [{
    label: "",
    value: "",
  }]
};

export default FormSelectInput;
