import "@babel/polyfill";
import {
  createUser,
  createSystemUser,
  persistUser,
  fetchUser,
  disableCognitoUser,
  enableCognitoUser
} from "../api/userApi";
import objectsAreEqual from "../tools/objectChecker";
import { saveClient } from "./clientActions";

export const LOAD_USER_REQUESTED = "LOAD_USER_REQUESTED";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE";

export const SAVE_USER_REQUESTED = "SAVE_USER_REQUESTED";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";
export const SAVE_SYSTEM_USER_SUCCESS = "SAVE_SYSTEM_USER_SUCCESS";

export const DISABLE_USER_REQUESTED = "DISABLE_USER_REQUESTED";
export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";
export const DISABLE_USER_FAILURE = "DISABLE_USER_FAILURE";

export const ENABLE_USER_REQUESTED = "ENABLE_USER_REQUESTED";
export const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS";
export const ENABLE_USER_FAILURE = "ENABLE_USER_FAILURE";

export const CHECK_USER_IS_VALID = "CHECK_USER_IS_VALID";
export const CHECK_USER_IS_DIRTY = "CHECK_USER_IS_DIRTY";
export const UPDATE_USER_FIELD_VALUE = "UPDATE_USER_FIELD_VALUE";
export const UPDATE_USER_FORM_STATE = "UPDATE_USER_FORM_STATE";
export const UPDATE_USER_FULL_NAME = "UPDATE_USER_FULL_NAME";

export const SHOW_USER_EDIT_FORM = "SHOW_USER_EDIT_FORM";
export const ADD_USER_REQUESTED = "ADD_USER_REQUESTED";
export const EDIT_USER_REQUESTED = "EDIT_USER_REQUESTED";

export const CHANGE_USER_FILTER = "CHANGE_USER_FILTER";

export const CANCEL_USER_EDIT_FORM = "CANCEL_USER_EDIT_FORM";
export const INITIALIZE_USER_FILTER = "INITIALIZE_USER_FILTER";

const changeUserFilterRequested = (id, value) => ({
  type: CHANGE_USER_FILTER,
  id,
  value
});

export const changeUserFilter = (id, value) => dispatch => {
  dispatch(changeUserFilterRequested(id, value));
};


const saveUserRequested = () => ({
  type: SAVE_USER_REQUESTED
});

const saveUserSuccess = user => ({
  type: SAVE_USER_SUCCESS,
  payload: user
});

const saveSystemUserSuccess = user => ({
  type: SAVE_SYSTEM_USER_SUCCESS,
  payload: user
});


const saveUserFailure = error => ({
  type: SAVE_USER_FAILURE,
  payload: error
});

export const cancelUserEdit = () => ({
  type: CANCEL_USER_EDIT_FORM
});

export const addUserRequested = user => ({
  type: ADD_USER_REQUESTED,
  payload: user
});

export const editUserRequested = () => ({
  type: EDIT_USER_REQUESTED,
});

const showUserIsValid = valid => ({
  type: CHECK_USER_IS_VALID,
  payload: valid
});

const updateUser = (id, value) => ({
  type: UPDATE_USER_FIELD_VALUE,
  id,
  value
});

const loadUserRequested = () => ({
  type: LOAD_USER_REQUESTED
});

export const loadUserSuccess = user => ({
  type: LOAD_USER_SUCCESS,
  payload: user
});

const loadUserFailure = error => ({
  type: LOAD_USER_FAILURE,
  payload: error
});

const disableUserRequested = () => ({
  type: DISABLE_USER_REQUESTED
});

const disableUserSuccess = user => ({
  type: DISABLE_USER_SUCCESS,
  payload: user
});

const disableUserFailure = error => ({
  type: DISABLE_USER_FAILURE,
  payload: error
});

const enableUserRequested = () => ({
  type: ENABLE_USER_REQUESTED
});

const enableUserSuccess = user => ({
  type: ENABLE_USER_SUCCESS,
  payload: user
});

const enableUserFailure = error => ({
  type: ENABLE_USER_FAILURE,
  payload: error
});


const setformState = (id, value) => ({
  type: UPDATE_USER_FORM_STATE,
  id,
  value
});

const updateUserFullName = () => async (dispatch, getState) => {
  const { editUser } = getState().users;
  dispatch({
    type: UPDATE_USER_FULL_NAME,
    payload: editUser.firstName + " " + editUser.lastName
  });
};

export const updateUserFormState = (id, value) => dispatch => {
  dispatch(setformState(id, value));
};

export const updateUserFieldValue = (id, value) => async (
  dispatch,
  getState
) => {
  dispatch(updateUser(id, value));
  if (id == "firstName" || id == "lastName") {
    dispatch(updateUserFullName());
  }

  const { editUser } = getState().users;

  dispatch(validateUser(editUser));

  //dispatch(showUserIsValid(isValid));
  dispatch(userIsDirty());
};

const userIsDirty = () => async (dispatch, getState) => {
  const { editUser, originalUser } = getState().users;

  const isDirty = !objectsAreEqual(editUser, originalUser);

  await dispatch({
    type: CHECK_USER_IS_DIRTY,
    isDirty
  });
};

// export const loadUsers = () => async dispatch => {
//   dispatch(loadUsersRequested());
//   try {
//     const data = await fetchUsers();
//     dispatch(loadUsersSuccess(data));
//   } catch (err) {
//     dispatch(loadUsersFailure(err));
//   }
// };

export const cancelChanges = () => async dispatch => {
  dispatch(cancelUserEdit());
};

export const addUser = () => async (dispatch, getState) => {
  const { editFormIsOpen } = getState().users;
  const { isDirty, isValid, editClient } = getState().clients;

  if (isDirty && isValid) {
    saveClient();
  }
  const newUser = createUser(editClient);

  await dispatch(addUserRequested(newUser));
  await dispatch(validateUser());
  if (!editFormIsOpen) {
    await dispatch(showEditForm());
  }
};

export const addSystemUser = () => async (dispatch, getState) => {
  const { editFormIsOpen } = getState().users;

  const newUser = createSystemUser();

  await dispatch(addUserRequested(newUser));
  await dispatch(validateUser());
  if (!editFormIsOpen) {
    await dispatch(showEditForm(true));
  }
};

export const showEditForm = (systemUser = false) => ({
  type: SHOW_USER_EDIT_FORM,
  payload: systemUser
});

export const editUser = (userId, clientId) => async (dispatch) => {
  dispatch(loadUserRequested());

  try{
    const user = await fetchUser(userId, clientId);

    await dispatch(loadUserSuccess(user));
    if (user) {
      dispatch(editUserRequested());
    }
  }
  catch (err) {
    dispatch(loadUserFailure(err));
  }
};

export const saveUser = () => async (dispatch, getState) => {
  const { editUser: user, addAnother, systemUser } = getState().users;

  dispatch(saveUserRequested());

  try {
    const response = await persistUser(user);

    if (response.status) {
      if (systemUser === true) {
        dispatch(saveSystemUserSuccess(response.user));
      }
      else {
        dispatch(saveUserSuccess(response.user));
      }

      if (addAnother) {
        if (systemUser) {
          dispatch(addSystemUser());
        }
        else {
          dispatch(addUser());
        }

      }
      else {
        dispatch(cancelUserEdit());
        // if (systemUser) {
        //   dispatch(loadSystemUserList());
        // }
      }
    }
    else {
      dispatch(saveUserFailure("Error saving user"));
    }
  }
  catch (err) {
    dispatch(saveUserFailure(err));
  }
};

const validateUser = () => async (dispatch, getState) => {
  //const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const { editUser: user } = getState().users;

  const isValid =
    !!user.fullName &&
    user.fullName.length > 0 &&
    !!user.firstName &&
    user.firstName.length > 0 &&
    !!user.lastName &&
    user.lastName.length > 0 &&
    !!user.email &&
    user.email.length > 0 &&
    !!user.role &&
    emailRegEx.test(user.email);

  dispatch(showUserIsValid(isValid));
};

export const disableUser = user => async dispatch => {
  dispatch(disableUserRequested());
  try {
    user.status = "Disabled";
    const response = await disableCognitoUser(user);

    if (response.status === true) {
      dispatch(disableUserSuccess(user));
    }
    else {
      dispatch(disableUserFailure("There was a problem disabling the user."));
    }
  }
  catch (err) {
    dispatch(disableUserFailure(err));
  }

}

export const enableUser = user => async dispatch => {
  dispatch(enableUserRequested());
  try {
    user.status = "Active";
    const response = await enableCognitoUser(user);
    if (response.status === true) {
      dispatch(enableUserSuccess(user));
    }
    else {
      dispatch(enableUserFailure("There was a problem disabling the user."));
    }
  }
  catch (err) {
    dispatch(enableUserFailure(err));
  }
}
