import { v4 } from "node-uuid";
import { doFetch } from "./index";


export const createCheckInObject = () => {
  return {
    checkInId: v4(),
    objectId: "",
    inventoryId: "",
    objectType: "",
    designer: "",
    locationId: "",
    locationName: "",
    checkInDate: ""
  };
};

export const persistCheckIn = async (checkInObject) => {
  const path = "/checkin/";

  const params = {
    method: "POST",
    headers: {},
    body: JSON.stringify(checkInObject)
  };

  return await doFetch(path, params);

}


export const deleteCheckIn = async (checkInObject) => {
  const path = "/checkin/";

  const params = {
    method: "DELETE",
    headers: {},
    body: JSON.stringify(checkInObject)
  };

  return await doFetch(path, params);

}
