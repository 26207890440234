import {
  LOAD_REQUESTED_OBJECTS_REQUESTED,
  LOAD_REQUESTED_OBJECTS_SUCCESS,
  LOAD_REQUESTED_OBJECTS_FAILURE,
  SELECT_REQUESTED_OBJECT,
  UNSELECT_REQUESTED_OBJECT,
  SELECT_ALL_REQUESTED_OBJECTS,
  FILTER_REQUESTED_OBJECTS,
} from '../actions/requestActions';
import { REMOVE_OBJECT_SUCCESS } from '../actions/searchActions';
import { SAVE_SHIPMENT_SUCCESS } from '../actions/shipmentActions';
import { LOGOUT_SUCCESS } from '../actions/authActions';
import { shipment } from '../models/shipment';
import { NULL_UUID } from '../api/constants';

const initialState = {
  editFormIsOpen: false,
  requestedObjects: [],
  selectedObjects: [],
  selectAll: false,
  loading: false,
  saveSuccess: false,
  isValid: false,
  isDirty: true,
  isSaving: false,
  clientId: NULL_UUID,
  shipment: shipment,
  originalShipment: shipment,
  error: null
};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REQUESTED_OBJECTS_REQUESTED:
      return {
        ...state,
        loading: true
      }

    case LOAD_REQUESTED_OBJECTS_SUCCESS:
      return {
        ...state,
        requestedObjects: action.payload,
        loading: false
      }

    case LOAD_REQUESTED_OBJECTS_FAILURE:
      return {
        ...state,
        requestedObjects: [{}],
        error: action.payload,
        loading: false
      }

    case REMOVE_OBJECT_SUCCESS:
      return {
        ...state,
        requestedObjects: [...state.requestedObjects.filter(x => x.objectId != action.objectId)]
      }

    case SELECT_ALL_REQUESTED_OBJECTS:
      return {
        ...state,
        allSelected: action.payload
      }

    case SELECT_REQUESTED_OBJECT:
      return {
        ...state,
        selectedObjects: [...state.selectedObjects.filter(obj => obj.objectId != action.payload.objectId), ...action.payload]
      }

    case UNSELECT_REQUESTED_OBJECT:
      return {
        ...state,
        selectedObjects: [...state.selectedObjects.filter(obj => obj.objectId != action.payload)]
      }

    case FILTER_REQUESTED_OBJECTS:
      return {
        ...state,
        clientId: action.payload,
        allSelected: false,
        selectedObjects: [],
      }

    case SAVE_SHIPMENT_SUCCESS:
      return {
        ...state,
        allSelected: false,
        requestedObjects: [],
        selectedObjects: [],

      }
    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }

}

export default requestReducer;
