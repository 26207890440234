import React, { Component } from "react";
import { string, number } from "prop-types";
import { Link } from "react-router-dom";
import { fetchImage } from "../../api/imagesApi";

const tableImageStyle = {
  height: "65px",
  width: "43px",
}

const noImageStyle = {
  height: "65px",
  width: "43px",
  backgroundColor: "#EDEDED",
  margin: "0"
};

class RequestObjectImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: null
    };
  }

  async componentDidMount() {
    const { clientId, objectId, imageNumber } = this.props;

    this.setState({
      loading: true
    });

    const objImage = await fetchImage(clientId, objectId, imageNumber);

    if (objImage) {
      this.setState({
        loading: false,
        image: objImage.Body
      });
    }
    else {
      this.setState({
        loading: false,
        image: null
      });
    }


  }

  render() {
    const { objectId } = this.props;
    const objectUrl = `/object/${objectId}`;
    const { image, loading } = this.state;

    if (loading) {
      return (
        <span>
          <i className="fa fa-spin fa-spinner" />
        </span>
      );
    }

    if (image) {
      return (
        <Link to={objectUrl} className="url-button">
          <img
            src={`data:image/jpeg;base64,${btoa(
              String.fromCharCode(...image.data)
            )}`}
            className="request-object-image"
            alt="object"
            style={tableImageStyle}
          />
        </Link>

      );
    }
    else {
      return (
        <Link to={objectUrl} className="url-button">
          <div
            style={noImageStyle}
          />
        </Link>
      )
    }
  }
}

RequestObjectImage.propTypes = {
  clientId: string,
  objectId: string,
  imageNumber: number,
};

RequestObjectImage.defaultProps = {
  clientId: "",
  objectId: "",
  imageNumber: 0,
};


export default RequestObjectImage;
