import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  width: 65,
  height: 65,
  borderWidth: 2,
  borderColor: "#666",
  borderStyle: "dashed",
  borderRadius: 5
};

const activeStyle = {
  borderStyle: "solid",
  borderColor: "#6c6",
  backgroundColor: "#eee"
};

const acceptStyle = {
  borderStyle: "solid",
  borderColor: "#00e676"
};

const rejectStyle = {
  borderStyle: "solid",
  borderColor: "#ff1744"
};

const thumbsContainer = {
  width: '100%',
  height: '100%',
};

const img = {
  display: "block",
  width: "auto",
  height: "100%"
};

const dropzoneWrapperStyle = {
  height: '65px',
  width: '65px',
}

const dropzoneIndicatorStyle = (hasFile) => ({
  display: hasFile ? "none" : "initial"
})



const ArchivistDropZone = ({uploadFile, multiple}) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(
      acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    );
    uploadFile(acceptedFiles[0]);
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    accept: "image/*",
    multiple,
    onDrop
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);

  const thumbs = files.map(file => (
    <img key={file.name} src={file.preview} style={img} alt="preview" />
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div style={dropzoneWrapperStyle}>
      <div style={dropzoneIndicatorStyle(acceptedFiles.length > 0)}>
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
        </div>
      </div>
      <div style={thumbsContainer}>{thumbs}</div>
    </div>
  );
}

export default ArchivistDropZone;
