import React from "react";
import { string, func, bool } from "prop-types";
import {
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import {
  authBackgroundStyle,
  authFormStyle,
  authFormTitleStyle,
  logoStyle,
  actionButtonStyle
} from "../../styles/authStyles";
import logoImage from '../../assets/images/da-logo-black-2.png';

const forgotPasswordButtonStyle = {
  display: 'contents',
  fontFamily: 'proxima-nova, regular',
  fontSize: '16px',
  color: '#838383',
  textDecoration: 'underline',
}


const AppSignInForm = ({
  authData,
  formIsValid,
  error,
  handleChange,
  signIn,
  forgotPassword,
  processing
}) => (
  <div className="signin-page-background" style={authBackgroundStyle}>
    <Container className="SignInForm" style={authFormStyle}>
      <Form>
        <div className="signin-form-title" style={authFormTitleStyle}>
          <img src={logoImage} alt="The Digital Archivist" style={logoStyle} />
        </div>
        <Col>
          <FormGroup>
            <Label for="username">E-mail Address</Label>
            <Input
              id="username"
              name="username"
              placeholder="Enter email"
              type="text"
              autoFocus
              default={authData || ""}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup style={{marginBottom: '0.5rem'}}>
            <Label for="password">Password</Label>
            <Input
              id="password"
              name="password"
              placeholder="Enter password"
              type="password"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <Button
            style={forgotPasswordButtonStyle}
            onClick={() => forgotPassword()}
          >
            Forgot Password?
          </Button>
        </Col>

        <Col xs="6">
          <Button
            style={actionButtonStyle}
            size="lg"
            disabled={!formIsValid || processing}
            onClick={() => signIn()}
          >
            {processing ? (
              <span>
                <i className="fa fa-spin fa-spinner" style={{marginRight: '0.3em'}} />
                Logging In...
              </span>
              ) : (
                <span>Log In</span>
              )
            }
          </Button>

        </Col>
        {error ? (
          <Col xs="6">
            <Label>{error}</Label>
          </Col>
        ) : null}
      </Form>
    </Container>
  </div>
);

AppSignInForm.propTypes = {
  authData: string,
  processing: bool.isRequired,
  formIsValid: bool.isRequired,
  error: string,
  handleChange: func.isRequired,
  signIn: func.isRequired,
  forgotPassword: func.isRequired
};

AppSignInForm.defaultProps = {
  authData: "",
  error: ""
};

export default AppSignInForm;
