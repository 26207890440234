import { Logger } from 'aws-amplify';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { routerReducer } from 'react-router-redux'
import profileReducer from './authReducer';
import searchReducer from './searchReducer';
import objectReducer from './objectReducer'
import systemReducer from './systemReducer';
import shipmentReducer from './shipmentReducer';
import shipmentLogReducer from './shipmentLogReducer';
import requestReducer from './requestReducer';
import imageManagementReducer from './imageManagementReducer';
import locationReducer from './locationReducer';
import checkinReducer from './checkinReducer';
import clientReducer from './clientReducer';
import userReducer from './userReducer';
import systemUserReducer from './systemUserReducer';
import myArchiveReducer from './myArchiveReducer';

const logger = new Logger('rootReducer');

const appReducer = combineReducers({
  checkin: checkinReducer,
  clients: clientReducer,
  imageManagement: imageManagementReducer,
  location: locationReducer,
  myArchive: myArchiveReducer,
  object: objectReducer,
  requests: requestReducer,
  routing: routerReducer,
  search: searchReducer,
  shipment: shipmentReducer,
  shipmentLog: shipmentLogReducer,
  system: systemReducer,
  user: profileReducer,
  users: userReducer,
  systemUsers: systemUserReducer
});

const rootReducer = (state = {}, action) => {

  if (action.type === 'LOGGED_OUT') {
    const { routing } = state;
    logger.debug("Clearing local storage");
    Object.keys(state).forEach(key => {
      storage.removeItem(`persist:${key}`);
    });

    state = routing;

  }

  return appReducer(state, action);
};

export default rootReducer;

