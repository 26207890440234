import React from "react";
import { Container, Row, Col } from "reactstrap";
import { func, string, arrayOf, shape } from "prop-types";
import MySavedObjectCard from "./MySavedObjectCard";

const MySavedObjectList = ({ savedObjects, removeObject, clientId }) => (
  <Container fluid className="search-results">
    <Row>
      {savedObjects.map(object => (
        <Col key={object.objectId} xs="12" sm="6" md="3">
          <MySavedObjectCard
            key={object.objectId}
            object={object}
            handleRemove={() => removeObject(object.objectId)}
            clientId={clientId}
            showXButton
            allowLink
          />
        </Col>
      ))}
    </Row>
  </Container>
);

MySavedObjectList.propTypes = {
  savedObjects: arrayOf(
    shape({
      objectId: string,
      objectType: string,
      inventoryId: string,
      designer: string
    })
  ).isRequired,
  clientId: string.isRequired,
  removeObject: func.isRequired
};

export default MySavedObjectList;
