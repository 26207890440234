import { doFetch } from "./index";
import { location as newLocation } from "../models/location";
import generateNineDigitId from "../tools/idGenerator";

export const createLocation = () => {
  return {
    ...newLocation,
    locationId: generateNineDigitId()
  };
};

export const fetchLocation = async (locationId) => {
  const path = `/locations/${locationId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);
  return response;

}

export const fetchLocations = async () => {
  const path = "/locations";

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response.sort((a, b) => {
    if (a.locationName > b.locationName) return 1;
    if (a.locationName < b.locationName) return -1;
    return 0;
  });
};

export const persistLocation = async location => {
  if (!location) {
    return false;
  }

  let method = "POST";
  const path = `/locations`;

  const params = {
    method: method,
    headers: {},
    body: JSON.stringify(location)
  };

  const response = await doFetch(path, params);

  return response;
};
