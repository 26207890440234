import { Auth, Storage } from "aws-amplify";
import { doFetch } from "./index";
import { downloadUrl } from "../tools/downloadBlob";
import { imageCustomPrefix } from "./constants";

export const fetchImage = async (clientId, objectId, imageNumber ) => {
  const path = `/objects/${objectId}/images/${imageNumber}?clientId=${clientId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
}

export const downloadOriginalImage = async (clientId, objectId, inventoryId, thumbnailNumber) => {

  let fileName = "";
  let imageNumber = 0;
  if (thumbnailNumber === 1) {
    imageNumber = 0;
    fileName = `${inventoryId}-1.jpg`
  }
  else {
    imageNumber = 2;
    fileName = `${inventoryId}-2.jpg`
  }

  const creds = await Auth.currentCredentials();
  const fileKey = `downloads/${creds.identityId}/${fileName}`

  const response = await getOriginalImagePath(clientId, objectId, fileKey, imageNumber);
  if (!response.status) {
    return;
  }

  const url = await Storage.get(fileName, {
      level: 'private',
      expires: 30,
      customPrefix: imageCustomPrefix
    });

  await downloadUrl(url, fileName);

}

const getOriginalImagePath = async (clientId, objectId, fileKey, imageNumber) => {
  const path = `/objects/${objectId}/download?clientId=${clientId}`;

  const payload = {
    imageNumber,
    fileKey
  }

  const params = {
    method: "POST",
    headers: {},
    body: JSON.stringify(payload)
  }

  const response = await doFetch(path, params)

  return response;

}
