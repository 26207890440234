import { v4 } from "node-uuid";
import moment from "moment";
import { doFetch } from "./index";
import { shipmentLog as newShipmentLog } from "../models/shipmentLog";

export const createShipmentLog = () => ({
  ...newShipmentLog,
  shipmentLogId: v4(),
  dateRequested: moment(new Date()).format("YYYY-MM-DD"),
  status: "Pending"
});

export const fetchShipmentLogs = async (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return {};
  }

  const path = `/shipment-logs?fromDate=${moment(fromDate).format(
    "YYYY-MM-DD"
  )}&toDate=${moment(toDate).format("YYYY-MM-DD")}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
};

export const fetchShipmentLog = async shipmentLogId => {
  if (!shipmentLogId) {
    return {};
  }

  const path = `/shipment-logs/${shipmentLogId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);
  return response;
};

export const persistShipmentLog = async shipmentLog => {
  if (!shipmentLog) {
    return false;
  }

  const method = "POST";

  const path = `/shipment-logs`;

  const params = {
    method,
    headers: {},
    body: JSON.stringify(shipmentLog)
  };

  const response = await doFetch(path, params);

  return response;
};
