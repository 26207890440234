import "@babel/polyfill";
import {
  persistRequestObject,
  persistRemoveObject
} from "../api/requestsApi";
import search from "../api/searchApi";

export const CHANGE_SEARCH_TERM = "CHANGE_SEARCH_TERM";

export const SEARCH_OBJECTS_REQUESTED = "SEARCH_OBJECTS_REQUESTED";
export const SEARCH_OBJECTS_SUCCESS = "SEARCH_OBJECTS_SUCCESS";
export const SEARCH_OBJECTS_FAILURE = "SEARCH_OBJECTS_FAILURE";

export const REQUEST_OBJECT_REQUESTED = "REQUEST_OBJECT_REQUESTED";
export const REQUEST_OBJECT_SUCCESS = "REQUEST_OBJECT_SUCCESS";
export const REQUEST_OBJECT_FAILURE = "REQUEST_OBJECT_FAILURE";

export const REMOVE_OBJECT_REQUESTED = "REMOVE_OBJECT_REQUESTED";
export const REMOVE_OBJECT_SUCCESS = "REMOVE_OBJECT_SUCCESS";
export const REMOVE_OBJECT_FAILURE = "REMOVE_OBJECT_FAILURE";

export const requestObjectRequested = objectId => ({
  type: REQUEST_OBJECT_REQUESTED,
  objectId
});

export const requestObjectFailure = (error, objectId) => ({
  type: REQUEST_OBJECT_FAILURE,
  payload: { error },
  objectId
});

export const requestObjectSuccess = objectId => ({
  type: REQUEST_OBJECT_SUCCESS,
  objectId
});

export const removeObjectRequested = objectId => ({
  type: REMOVE_OBJECT_REQUESTED,
  objectId
});

export const removeObjectFailure = (error, objectId) => ({
  type: REMOVE_OBJECT_FAILURE,
  payload: { error },
  objectId
});

export const removeObjectSuccess = objectId => ({
  type: REMOVE_OBJECT_SUCCESS,
  objectId
});

export const searchObjectsRequested = () => ({
  type: SEARCH_OBJECTS_REQUESTED
});

export const searchObjectsSuccess = objectList => ({
  type: SEARCH_OBJECTS_SUCCESS,
  payload: objectList
});

export const searchObjectsFailure = error => ({
  type: SEARCH_OBJECTS_FAILURE,
  payload: { error }
});

export const changeSearchTerm = searchTerm => ({
  type: CHANGE_SEARCH_TERM,
  payload: searchTerm
});

export const doSearch = () => async (dispatch, getState) => {
  const { searchTerm } = getState().search;

  dispatch(searchObjectsRequested());

  try {
    const { tenantId } = getState().system;
    const data = await search("object", searchTerm, 0, tenantId);
    dispatch(searchObjectsSuccess(data));
  }
  catch (err) {
    dispatch(searchObjectsFailure(err));
  }
};

export const requestObject = objectId => async (dispatch, getState) => {
  dispatch(requestObjectRequested(objectId));
  try {
    const { items } = getState().search;
    const object = items.filter(x => x.objectId === objectId)[0];

    let response;
    if (object) {
      response = await persistRequestObject(object);
    }
    if (response) {
      dispatch(requestObjectSuccess(objectId));
    }
    else {
      dispatch(requestObjectFailure("Error saving object request"));
    }
  }
  catch (err) {
    dispatch(requestObjectFailure(err, objectId));
  }
};

export const removeObject = objectId => async (dispatch) => {
  dispatch(removeObjectRequested(objectId));
  try {

    const response = await persistRemoveObject(objectId);


    if (response) {
      dispatch(removeObjectSuccess(objectId));
    }
    else {
      dispatch(removeObjectFailure("Error saving object remove"));
    }
  }
  catch (err) {
    dispatch(removeObjectFailure(err, objectId));
  }
};


