import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, arrayOf, shape, bool } from "prop-types";
import { Table, Input, Label, Col, Row, Button } from "reactstrap";
import {
  addClient,
  loadClients,
  editClient,
  changeClientFilter
} from "../../actions/clientActions";
import EditClientPage from "./EditClientPage";
import EditUserPage from "./EditUserPage";
import { clientShape } from "../../models/client";
import editIcon from "../../assets/images/edit-icon.png";
import {
  filterPageHeaderStyle,
  filterHeaderStyle,
  filterFormButtonActionStyle,
  filterLabelStyle,
  tableHeaderStyle,
  tableButtonStyle,
} from "../../styles/formStyles";


class ClientsPage extends Component {
  componentDidMount = () => {
    const { filter, loadClients } = this.props;
    loadClients(filter.fromDate, filter.toDate);
  };

  handleFilterChange = e => {
    e.preventDefault();
    const { changeClientFilter } = this.props;

    changeClientFilter(e.target.id, e.target.value);
  };

  handleAddClient = e => {
    e.preventDefault();
    const { addClient } = this.props;
    addClient();
  };

  handleEditClient = clientId => {
    const { editClient } = this.props;
    editClient(clientId);
  };

  render() {
    const { userRole, filter, clients, loading } = this.props;

    if (!(userRole === "System Administrator" || userRole === "Digital Archivist Administrator" || userRole === "Digital Archivist User")) {
      return (
        <div>
          <h2>Unauthorized</h2>
          Please contact the system administrator.
        </div>
      );
    }

    return (
      <div className="clients">
        <div className="clients-header" style={filterPageHeaderStyle}>
          <h2>Clients</h2>
          <div>
            <Button
              onClick={this.handleAddClient}
              style={filterFormButtonActionStyle}
            >
              Add Client
            </Button>
          </div>
        </div>

        <div className="clients-filter" style={filterHeaderStyle}>
          <Row>
            <Col sm={4}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Client Name
              </Label>
              <Input
                type="text"
                id="clientName"
                value={filter.clientName}
                onChange={this.handleFilterChange}
              />
            </Col>
          </Row>
        </div>

        <div className="clients-table">
          <Table hover responsive>
            <thead style={tableHeaderStyle}>
              <tr>
                <th>Client</th>
                <th>{null}</th>
              </tr>
            </thead>
            <tbody>
              {clients
                .filter(
                  item =>
                    item.clientName
                      .toUpperCase()
                      .indexOf(filter.clientName.toUpperCase()) >= 0
                )
                .map(item => (
                  <tr key={item.clientId}>
                    <td>{item.clientName}</td>
                    <td>
                      <div
                        className="editButton"
                        role="button"
                        aria-hidden
                        style={tableButtonStyle}
                        onClick={e => {
                          e.preventDefault();
                          this.handleEditClient(item.clientId);
                        }}
                      >
                        <img src={editIcon} alt="Edit Client" />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="Loading">
          {loading === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Loading...
            </span>
          ) : null}
        </div>
        <EditClientPage />
        <EditUserPage />
      </div>
    );
  }
}

ClientsPage.propTypes = {
  userRole: string.isRequired,
  filter: shape({
    clientName: string
  }).isRequired,
  loading: bool.isRequired,
  clients: arrayOf(clientShape).isRequired,
  changeClientFilter: func.isRequired,
  loadClients: func.isRequired,
  addClient: func.isRequired,
  editClient: func.isRequired
};

const mapStateToProps = state => {
  return {
    userRole: state.user.profile.role,
    filter: state.clients.filter,
    clients: state.clients.clients.sort((a, b) => {
      if (a.clientName > b.clientName) return 1;
      if (a.clientName < b.clientName) return -1;
      return 0;
    }),
    loading: state.clients.loading
  };
};

export default connect(
  mapStateToProps,
  {
    loadClients,
    addClient,
    editClient,
    changeClientFilter
  }
)(ClientsPage);
