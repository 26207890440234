import {
  persistToMyArchive,
  fetchMyArchive,
  deleteFromMyArchive,
  deleteAllFromMyArchive
} from "../api/myArchiveApi";

export const FETCH_MYARCHIVE_REQUESTED = "FETCH_MYARCHIVE_REQUESTED";
export const FETCH_MYARCHIVE_SUCCESS = "FETCH_MYARCHIVE_SUCCESS";
export const FETCH_MYARCHIVE_FAILURE = "FETCH_MYARCHIVE_FAILURE";

export const SAVE_MYARCHIVE_REQUESTED = "SAVE_MYARCHIVE_REQUESTED";
export const SAVE_MYARCHIVE_SUCCESS = "SAVE_MYARCHIVE_SUCCESS";
export const SAVE_MYARCHIVE_FAILURE = "SAVE_MYARCHIVE_FAILURE";

export const REMOVE_MYARCHIVE_REQUESTED = "REMOVE_MYARCHIVE_REQUESTED";
export const REMOVE_MYARCHIVE_SUCCESS = "REMOVE_MYARCHIVE_SUCCESS";
export const REMOVE_MYARCHIVE_FAILURE = "REMOVE_MYARCHIVE_FAILURE";

export const REMOVE_ALL_MYARCHIVE_REQUESTED = "REMOVE_ALL_MYARCHIVE_REQUESTED";
export const REMOVE_ALL_MYARCHIVE_SUCCESS = "REMOVE_ALL_MYARCHIVE_SUCCESS";
export const REMOVE_ALL_MYARCHIVE_FAILURE = "REMOVE_ALL_MYARCHIVE_FAILURE";


const getMyArchiveRequested = () => ({
  type: FETCH_MYARCHIVE_REQUESTED
});

const getMyArchiveSuccess = objects => ({
  type: FETCH_MYARCHIVE_SUCCESS,
  payload: objects
});

const getMyArchiveFailure = err => ({
  type: FETCH_MYARCHIVE_FAILURE,
  payload: err
});

const saveToMyArchiveRequested = (objectId) => ({
  type: SAVE_MYARCHIVE_REQUESTED,
  objectId
});

const saveToMyArchiveSuccess = object => ({
  type: SAVE_MYARCHIVE_SUCCESS,
  payload: object
});

const saveToMyArchiveFailure = (objectId, err) => ({
  type: SAVE_MYARCHIVE_FAILURE,
  payload: err,
  objectId
});

const deleteFromMyArchiveRequested = () => ({
  type: REMOVE_MYARCHIVE_REQUESTED
});

const deleteFromMyArchiveSuccess = objectId => ({
  type: REMOVE_MYARCHIVE_SUCCESS,
  objectId
});

const deleteFromMyArchiveFailure = err => ({
  type: REMOVE_MYARCHIVE_FAILURE,
  payload: err
});

const deleteAllFromMyArchiveRequested = () => ({
  type: REMOVE_ALL_MYARCHIVE_REQUESTED
});

const deleteAllFromMyArchiveSuccess = () => ({
  type: REMOVE_ALL_MYARCHIVE_SUCCESS,
});

const deleteAllFromMyArchiveFailure = err => ({
  type: REMOVE_ALL_MYARCHIVE_FAILURE,
  payload: err
});

export const getMyArchive = () => async (dispatch, getState) => {
  dispatch(getMyArchiveRequested());

  try {
    const { tenantId: clientId } = getState().system;

    const response = await fetchMyArchive(clientId);
    if (response) {
      dispatch(getMyArchiveSuccess(response));
    }
    else {
      dispatch(
        getMyArchiveFailure(
          "There was a problem retrieving your saved objects in your archive.  Please try again or contact The Digital Archivist if problem continues."
        )
      );
    }
  }
  catch (err) {
    dispatch(saveToMyArchiveFailure(err));
  }
};

export const saveToMyArchive = objectId => async (dispatch, getState) => {
  dispatch(saveToMyArchiveRequested(objectId));

  try {
    const { items } = getState().search;
    const { tenantId: clientId } = getState().system;
    const saveObject = items.find(x => x.objectId === objectId);


    if (saveObject) {
      const object = {
        objectId: saveObject.objectId,
        designer: saveObject.designer,
        inventoryId: saveObject.inventoryId,
        objectType: saveObject.objectType
      };

      const response = await persistToMyArchive(object, clientId);
      if (response) {
        dispatch(saveToMyArchiveSuccess(object));
      }
      else {
        dispatch(
          saveToMyArchiveFailure(
            objectId,
            "Object was not saved.  Please try again or contact The Digital Archivist if problem continues."
          )
        );
      }
    }
  }
  catch (err) {
    dispatch(saveToMyArchiveFailure(objectId, err));
  }
};

export const removeFromMyArchive = objectId => async (dispatch, getState) => {
  dispatch(deleteFromMyArchiveRequested());

  try {
    const { tenantId: clientId } = getState().system;
    const response = await deleteFromMyArchive(objectId, clientId);

    if (response) {
      dispatch(deleteFromMyArchiveSuccess(objectId));
    }
    else {
      dispatch(
        deleteFromMyArchiveFailure(
          `There was a problem deleting the object from your archive.
          Please try again or contact The Digital Archivist if problem continues.`
        )
      );
    }
  }
  catch (err) {
    dispatch(deleteFromMyArchiveFailure(err))
  }
};

export const removeAllFromMyArchive = objectId => async (dispatch, getState) => {
  dispatch(deleteAllFromMyArchiveRequested());

  try {
    const { tenantId: clientId } = getState().system;
    const response = await deleteAllFromMyArchive(clientId);
    if (response) {
      dispatch(deleteAllFromMyArchiveSuccess(objectId));
    }
    else {
      dispatch(
        deleteAllFromMyArchiveFailure(
          `There was a problem deleting all objects from your archive.
          Please try again or contact The Digital Archivist if problem continues.`
        )
      );
    }
  }
  catch (err) {
    dispatch(deleteAllFromMyArchiveFailure(err))
  }
};
