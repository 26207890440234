import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, arrayOf, shape } from "prop-types";
import { Table, Input, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  initializeCheckIns,
  lookupLocation,
  lookupObject,
  updateFormFieldValue,
  removePendingCheckIn,
  removeCheckIn
} from "../../actions/checkInActions";
import NoClientSelectedPage from "../common/NoClientSelectedPage";
import { NULL_UUID } from "../../api/constants";
import inventoryIdFormat from "../../tools/objectFormatters";
import removeIcon from "../../assets/images/remove-2.png";


const checkInObjectsForm = {
  paddingTop: "15px",
  paddingBottom: "30px"
};

const tableHeaderStyle = {
  backgroundColor: "#EBEBEB"
};

const tableHeaderStyleCenter = {
  ...tableHeaderStyle,
  textAlign: "center"
};

const hiddenTableStyle = {
  display: "none"
};

const checkedInTableStyle = {
  display: "block"
};

const requestsPendingTable = {
  paddingBottom: "30px",
  display: "block"
};

const tableButtonStyle = {
  cursor: "pointer",
  display: "block"
};

const hiddenButtonStyle = {
  display: "none"
};

class CheckInObjectsPage extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.txtObject = React.createRef();
    this.txtLocation = React.createRef();
  }

  componentDidMount() {
    this.txtObject.focus();

    const { initializeCheckIns } = this.props;
    initializeCheckIns();
  }

  handleChange = e => {
    e.preventDefault();

    const { updateFormFieldValue } = this.props;
    updateFormFieldValue(event.target.id, event.target.value);
  };

  handleDateFilterChange = (id, date) => {
    const { updateFormFieldValue } = this.props;
    updateFormFieldValue(id, date.toISOString().slice(0, 10));
  };

  // handleSubmitCheckin = () => {
  //   const { checkInObject } = this.props;
  //   checkInObject();
  // };

  handleLocationLookup = e => {
    if (e.key === "Enter") {
      e.preventDefault();

      const { lookupLocation } = this.props;
      const locationFound = lookupLocation(e.target.value);
      if (locationFound) {
        this.txtObject.focus();
      }
      else {
        this.txtLocation.focus();
      }
    }
  };

  handleObjectLookup = e => {
    if (e.key === "Enter") {
      e.preventDefault();

      const { lookupObject } = this.props;
      const objectFound = lookupObject(e.target.value);
      if (objectFound) {
        this.txtLocation.focus();
      }
      else {
        this.txtObject.focus();
      }

    }
  };

  handleRemovePendingCheckIn = () => {
    const { removePendingCheckIn } = this.props;

    removePendingCheckIn();

    this.txtObject.focus();

  }

  handleRemoveCheckIn = (checkInId) => {
    const { removeCheckIn } = this.props;

    removeCheckIn(checkInId);

    this.txtObject.focus();

  }

  render = () => {
    const {
      userRole,
      tenantId,
      firstName,
      locationId,
      objectId,
      checkInDate,
      checkInObject,
      checkedInObjects,
    } = this.props;

    if (
      !(
        userRole === "System Administrator" ||
        userRole === "Digital Archivist Administrator" ||
        userRole === "Digital Archivist User"
      )
    ) {
      return (
        <div>
          <h2>Unauthorized</h2>
          Please contact the system administrator.
        </div>
      );
    }

    if (tenantId === NULL_UUID) {
      return <NoClientSelectedPage firstName={firstName} />;
    }
    else {
      return (
        <div className="checkin-objects">
          <div className="checkin-objects-header">
            <h2>Check-in Objects</h2>
          </div>

          <div className="checkin-objects-form" style={checkInObjectsForm}>
            <Row>
              <Col sm={4}>
                <Input
                  id="objectId"
                  name="objectId"
                  value={objectId}
                  onKeyPress={this.handleObjectLookup}
                  onChange={this.handleChange}
                  innerRef={input => {
                    this.txtObject = input;
                  }}
                  placeholder="Scan Inventory Id Barcode"
                  onBlur={this.handleLookupObject}
                />
              </Col>
              <Col sm={4}>
                <Input
                  id="locationId"
                  placeholder="Scan Location Barcode"
                  value={locationId}
                  //disabled={checkInObject.objectId === ""}
                  innerRef={input => {
                    this.txtLocation = input;
                  }}
                  onKeyPress={this.handleLocationLookup}
                  onChange={this.handleChange}
                  onBlur={this.handleEnterLocation}
                />
              </Col>
              <Col sm={4}>
                <DatePicker
                  id="checkInDate"
                  onChange={date => this.handleDateFilterChange("checkInDate", date)}
                  selected={moment(checkInDate).toDate()}
                />
              </Col>
            </Row>
          </div>

          <div className="checkedin-pending-table" style={requestsPendingTable}>
            <Table responsive>
              <thead style={tableHeaderStyle}>
                <tr>
                  <th colSpan="6" style={tableHeaderStyleCenter}>
                    Pending...
                  </th>
                </tr>
                <tr>
                  <th>Inventory Id</th>
                  <th>Object Type</th>
                  <th>Designer</th>
                  <th>Location</th>
                  <th colSpan="2">Check-In Date</th>
                </tr>
              </thead>
              <tbody>
                <tr key={checkInObject.objectId}>
                  <td>{inventoryIdFormat(checkInObject.inventoryId)}</td>
                  <td>{checkInObject.objectType}</td>
                  <td>{checkInObject.designer}</td>
                  <td>{checkInObject.locationName}</td>
                  <td>{checkInObject.checkInDate}</td>
                  <td>
                    <div
                      className="removePendingButton"
                      role="button"
                      aria-hidden
                      style={
                        checkInObject.objectId === ""
                          ? hiddenButtonStyle
                          : tableButtonStyle
                      }
                      onClick={e => {
                        e.preventDefault();
                        this.handleRemovePendingCheckIn();
                      }}
                    >
                      <img src={removeIcon} alt="Remove pending check-in" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div
            className="checkedin-table"
            style={
              checkedInObjects.length === 0
                ? hiddenTableStyle
                : checkedInTableStyle
            }
          >
            <Table responsive>
              <thead style={tableHeaderStyle}>
                <tr>
                  <th colSpan="6" style={tableHeaderStyleCenter}>
                    Checked In
                  </th>
                </tr>
                <tr>
                  <th>Inventory Id</th>
                  <th>Object Type</th>
                  <th>Designer</th>
                  <th>Location</th>
                  <th colSpan="2">Check-In Date</th>
                </tr>
              </thead>
              <tbody>
                {checkedInObjects.map(obj => (
                  <tr key={obj.objectId}>
                    <td>
                      <Link to={`/object/${obj.objectId}`}>
                        {inventoryIdFormat(obj.inventoryId)}
                      </Link>
                    </td>
                    <td>{obj.objectType}</td>
                    <td>{obj.designer}</td>
                    <td>{obj.locationName}</td>
                    <td>{obj.checkInDate}</td>
                    <td>
                      <div
                        className="removeCheckInButton"
                        role="button"
                        aria-hidden
                        style={tableButtonStyle}
                        onClick={e => {
                          e.preventDefault();
                          this.handleRemoveCheckIn(obj.checkInId);
                        }}
                      >
                        <img src={removeIcon} alt="Remove pending check-in" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      );
    }
  };
}

CheckInObjectsPage.propTypes = {
  userRole: string.isRequired,
  tenantId: string.isRequired,
  firstName: string.isRequired,
  locationId: string.isRequired,
  objectId: string.isRequired,
  checkInDate: string.isRequired,
  checkInObject: shape({
    objectId: string,
    inventoryId: string,
    designer: string,
    objectType: string,
    locationId: string,
    locationName: string,
    checkInDate: string
  }).isRequired,
  checkedInObjects: arrayOf(
    shape({
      objectId: string,
      inventoryId: string,
      designer: string,
      objectType: string,
      locationId: string,
      locationName: string,
      checkInDate: string
    })
  ).isRequired,
  lookupLocation: func.isRequired,
  lookupObject: func.isRequired,
  updateFormFieldValue: func.isRequired,
  initializeCheckIns: func.isRequired,
  removePendingCheckIn: func.isRequired,
  removeCheckIn: func.isRequired,
};

const mapStateToProps = state => {
  return {
    userRole: state.user.profile.role,
    tenantId: state.system.tenantId,
    firstName: state.user.profile.first_name,
    location: state.checkin.location,
    checkInObject: state.checkin.currentCheckInObject,
    locationId: state.checkin.locationId,
    objectId: state.checkin.objectId,
    checkedInObjects: state.checkin.checkedInObjects,
    checkInDate: state.checkin.checkInDate
  };
};

export default connect(
  mapStateToProps,
  {
    initializeCheckIns,
    updateFormFieldValue,
    lookupLocation,
    lookupObject,
    removePendingCheckIn,
    removeCheckIn
  }
)(CheckInObjectsPage);
