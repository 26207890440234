import { doFetch } from "./index";

export const fetchMyArchive = async (clientId) => {
  const path = `/myarchive?clientId=${clientId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;

}

export const persistToMyArchive = async (object, clientId) => {
  const path = `/myarchive?clientId=${clientId}`;

  const params = {
    method: "POST",
    headers: {},
    body: JSON.stringify(object)
  };

  const response = await doFetch(path, params);
  return response;
}

export const deleteFromMyArchive = async (objectId, clientId) => {
  const path = `/myarchive/${objectId}?clientId=${clientId}`;

  const params = {
    method: "DELETE",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
}

export const deleteAllFromMyArchive = async (clientId) => {
  const path = `/myarchive?clientId=${clientId}`;

  const params = {
    method: "DELETE",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
}
