import { v4 } from "node-uuid";
import { doFetch } from "./index";
import { client as newClient } from "../models/client";

export const createClient = () => {
  return {
    ...newClient,
    clientId: v4()
  };
};

export const fetchClient = async (clientId) => {
  const path = `/clients/${clientId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);
  return response;

}

export const fetchClientList = async () => {
  const path = "/clients";

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response.sort((a, b) => {
    if (a.clientName > b.clientName) return 1;
    if (a.clientName < b.clientName) return -1;
    return 0;
  });
};

export const persistClient = async client => {
  if (!client) {
    return false;
  }

  let method = "POST";
  const path = `/clients`;

  const params = {
    method: method,
    headers: {},
    body: JSON.stringify(client)
  };

  const response = await doFetch(path, params);

  return response;
};
