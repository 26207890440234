import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, arrayOf, shape, bool } from "prop-types";
import { Table, Input, Label, Col, Row, Button } from "reactstrap";
import {
  addLocation,
  loadLocations,
  editLocation,
  changeLocationFilter
} from "../../actions/locationActions";
import EditLocationPage from "./EditLocationPage";
import { locationShape } from "../../models/location";
import editIcon from "../../assets/images/edit-icon.png";

const addHeaderStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px"
};

const addButtonStyle = {
  backgroundColor: "#344D5D",
  textTransform: "uppercase",
  padding: "16px",
  fontFamily: "proxima-nova",
  fontWeight: "600px"
};

const tableButtonStyle = {
  cursor: "pointer"
}

class LocationsPage extends Component {
  componentDidMount = () => {
    const { filter, loadLocations } = this.props;
    loadLocations(filter.fromDate, filter.toDate);
  };

  handleFilterChange = e => {
    e.preventDefault();
    const { changeLocationFilter } = this.props;

    changeLocationFilter(e.target.id, e.target.value);
  };

  handleAddLocation = e => {
    e.preventDefault();
    const { addLocation } = this.props;
    addLocation();
  };

  handleEditLocation = locationId => {
    const { editLocation } = this.props;
    editLocation(locationId);
  };

  render() {
    const { filter, locations, loading } = this.props;

    return (
      <div className="locations">
        <div className="locations-header" style={addHeaderStyle}>
          <h2>Locations</h2>
          <div>
            <Button onClick={this.handleAddLocation} style={addButtonStyle}>
              Add Location
            </Button>
          </div>
        </div>

        <div className="locations-filter">
          <Row>
            <Col sm={4}>
              <Label className="small-filter-label">
                Filter by Location Name
              </Label>
              <Input
                type="text"
                id="locationName"
                value={filter.locationName}
                onChange={this.handleFilterChange}
              />
            </Col>
          </Row>
        </div>

        <div className="locations-table">
          <Table hover responsive>
            <thead>
              <tr>
                <th>Location</th>
                <th>{null}</th>
              </tr>
            </thead>
            <tbody>
              {locations
                .filter(
                  item =>
                    item.locationName
                      .toUpperCase()
                      .indexOf(filter.locationName.toUpperCase()) >= 0
                )
                .map(item => (
                  <tr key={item.locationId}>
                    <td>{item.locationName}</td>
                    <td>
                      <div
                        className="editButton"
                        role="button"
                        aria-hidden
                        style={tableButtonStyle}
                        onClick={e => {
                          e.preventDefault();
                          this.handleEditLocation(item.locationId);
                        }}
                      >
                        <img src={editIcon} alt="Edit Location" />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="Loading">
          {loading === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Loading...
            </span>
          ) : null}
        </div>
        <EditLocationPage />
      </div>
    );
  }
}

LocationsPage.propTypes = {
  filter: shape({
    locationName: string
  }).isRequired,
  loading: bool.isRequired,
  locations: arrayOf(locationShape).isRequired,
  changeLocationFilter: func.isRequired,
  loadLocations: func.isRequired,
  addLocation: func.isRequired,
  editLocation: func.isRequired
};

const mapStateToProps = state => {
  return {
    filter: state.location.filter,
    locations: state.location.locations,
    clientList: state.system.lists["clients"],
    locationStatusList: state.system.lists["locationStatuses"],
    staffList: state.system.lists["staff"],
    loading: state.location.loading
  };
};

export default connect(
  mapStateToProps,
  {
    loadLocations,
    addLocation,
    editLocation,
    changeLocationFilter
  }
)(LocationsPage);
