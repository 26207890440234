import {
  ADD_SHIPMENT_REQUESTED,
  EDIT_SHIPMENT_REQUESTED,
  UPDATE_SHIPMENT_FIELD_VALUE,
  CHECK_SHIPMENT_IS_VALID,
  CHECK_SHIPMENT_IS_DIRTY,
  SHOW_SHIPMENT_EDIT_FORM,
  CANCEL_SHIPMENT_EDIT_FORM,
  LOAD_SHIPMENT_REQUESTED,
  LOAD_SHIPMENT_SUCCESS,
  LOAD_SHIPMENT_FAILURE,
  LOAD_SHIPMENT_LIST_REQUESTED,
  LOAD_SHIPMENT_LIST_SUCCESS,
  LOAD_SHIPMENT_LIST_FAILURE,
  SAVE_SHIPMENT_REQUESTED,
  SAVE_SHIPMENT_SUCCESS,
  SAVE_SHIPMENT_FAILURE,
  CHANGE_SHIPMENT_FILTER,
  INITIALIZE_SHIPMENT_FILTER
} from "../actions/shipmentActions";
import { LOGOUT_SUCCESS } from "../actions/authActions";
import { shipment } from "../models/shipment";
import { NULL_UUID } from "../api/constants";

const initialFilter = {
  fromDate: "",
  toDate: "",
  staff: "",
  clientId: NULL_UUID,
  status: ""
};

const initialState = {
  filter: { ...initialFilter },
  action: '',
  editFormIsOpen: false,
  saveSuccess: false,
  loading: false,
  isValid: false,
  isDirty: true,
  isSaving: false,
  shipments: [],
  editShipment: { ...shipment },
  originalShipment: { ...shipment },
  error: null
};

const shipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_SHIPMENT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          fromDate: action.fromDate,
          toDate: action.toDate
        }
      };

    case LOAD_SHIPMENT_LIST_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_SHIPMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shipments: [...action.payload]
      };

    case LOAD_SHIPMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case LOAD_SHIPMENT_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_SHIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case LOAD_SHIPMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case ADD_SHIPMENT_REQUESTED:
      return {
        ...state,
        isValid: false,
        isDirty: true,
        saving: false,
        isSaving: false,
        action: "CREATE SHIPMENT",
        editFormIsOpen: true,
        editShipment: { ...action.payload },
        originalShipment: { ...action.payload }
      };

    case EDIT_SHIPMENT_REQUESTED:
      return {
        ...state,
        isValid: false,
        isDirty: false,
        action: "EDIT SHIPMENT",
        editFormIsOpen: true,
        editShipment: { ...action.payload },
        originalShipment: { ...action.payload }
      };

    case CHECK_SHIPMENT_IS_VALID:
      return {
        ...state,
        isValid: action.payload
      };

    case CHECK_SHIPMENT_IS_DIRTY:
      return {
        ...state,
        isDirty: action.isDirty
      };

    case UPDATE_SHIPMENT_FIELD_VALUE:
      return {
        ...state,
        editShipment: {
          ...state.editShipment,
          [action.id]: action.value

        }
      };

    case SHOW_SHIPMENT_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: true
      };

    case CANCEL_SHIPMENT_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: false,
        action: '',
        isValid: false,
        isDirty: false,
        saveSuccess: false,
        editShipment: {
          ...shipment
        },
        originalShipment: {
          ...shipment
        }
      };

    case SAVE_SHIPMENT_REQUESTED:
      return {
        ...state,
        isSaving: true
      };

    case SAVE_SHIPMENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        allSelected: false,
        editFormIsOpen: false,
        saveSuccess: true,
        requestedObjects: [],
        selectedObjects: [],
        shipment: {
          ...shipment
        },
        originalShipment: {
          ...shipment
        }
      };

    case SAVE_SHIPMENT_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case CHANGE_SHIPMENT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.id]: action.value
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default shipmentReducer;
