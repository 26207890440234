import React from "react";
import { func, bool, string } from "prop-types";
import {
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import {
  authBackgroundStyle,
  authFormStyle,
  actionButtonStyle,
  cancelButtonStyle
} from "../../styles/authStyles";

const changePasswordButtonStyle = {
  ...actionButtonStyle,
  width: "160px"
};

const backToSiginPasswordButtonStyle = {
  ...cancelButtonStyle,
  width: "160px"
};

const noteStyle = {
  marginLeft: "15px",
  marginRight: "15px",
  marginTop: "15px",
  marginBottom: "20px",
  color: "#212529"
};

const noteFormBackgroundStyle = {
  ...authFormStyle,
  height: "600px",
  width: "600px"
};

const specialChars =
  "   ^ $ * . ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ `";

const AppRequireNewPasswordForm = ({
  processing,
  handleChange,
  formIsValid,
  changePassword,
  cancelChangePassword,
  error
}) => (
  <div className="require-new-password-background" style={authBackgroundStyle}>
    <Container
      className="require-new-password-container"
      style={noteFormBackgroundStyle}
    >
      <h2 style={{ textAlign: "center", color: "black" }}>
        Please change your password
      </h2>
      <div className="change-password-note" style={noteStyle}>
        <ul style={{ listStyle: "none" }}>
          <li>10 or more characters</li>
          <li>Have a lowercase character</li>
          <li>Have an uppercase character</li>
          <li>Have a number</li>
          <li>
            Have a special character:
            {specialChars}
          </li>
        </ul>
      </div>
      <Form>
        <Col>
          <FormGroup>
            <Label>Enter new password:</Label>
            <Input
              autoFocus
              id="newPassword"
              name="newPassword"
              placeholder="New password"
              type="password"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Reenter new password:</Label>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm password"
              type="password"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <Button
            color="primary"
            size="lg"
            disabled={!formIsValid || processing}
            onClick={() => changePassword()}
            style={changePasswordButtonStyle}
          >
            {processing ? (
              <span>
                <i
                  className="fa fa-spin fa-spinner"
                  style={{ marginRight: "0.3em" }}
                />
                Logging In...
              </span>
            ) : (
              <span>Change Password</span>
            )}
          </Button>

          <Button
            outline
            color="secondary"
            size="lg"
            onClick={() => cancelChangePassword()}
            style={backToSiginPasswordButtonStyle}
          >
            Back to Sign In
          </Button>
        </Col>

        {error ? (
          <Col xs="6">
            <Label>{error}</Label>
          </Col>
        ) : null}
      </Form>
    </Container>
  </div>
);

AppRequireNewPasswordForm.propTypes = {
  processing: bool.isRequired,
  formIsValid: bool.isRequired,
  handleChange: func.isRequired,
  changePassword: func.isRequired,
  cancelChangePassword: func.isRequired,
  error: string
};

AppRequireNewPasswordForm.defaultProps = {
  error: ""
};

export default AppRequireNewPasswordForm;
