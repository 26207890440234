export const makeOptionListFromStringArray = arr => {
  return arr
    ? arr.map(x => ({
        label: x,
        value: x
      }))
    : {
        label: "",
        value: ""
      };
};

export const makeOptionValueFromString = str =>
  str
    ? {
        label: str,
        value: str
      }
    : {
        label: "",
        value: ""
      };

export const makeOptionMultiValueFromStringArray = arr => {
  return arr.map(x => ({
    label: x,
    value: x
  }));
};
