import React from "react";
import { string, bool, func } from "prop-types";
import { FormGroup, Label, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const requireFieldIndicatorStyle = {
  color: "#FF0000",
  paddingRight: ".5em"
};

// const dateFieldStyle = {

// }



const FormDateInput = ({ label, id, value, handleChange, required, disabled}) => (
  <FormGroup row>
    <Label for={id} sm={3}>
      {label}
      {required ? (
        <span
          className="required-field-indicator"
          style={requireFieldIndicatorStyle}
        >
          *
        </span>
      ) : null}
    </Label>
    <Col sm={9}>
      <DatePicker
        id="fromDate"
        disabled={disabled}
        selected={value ? moment(value).toDate() : null}
        onChange={date => handleChange(id, date)}
        //style={dateInputFilterStyle}
      />
    </Col>
  </FormGroup>
);

FormDateInput.propTypes = {
  label: string.isRequired,
  id: string.isRequired,
  value: string.isRequired,
  required: bool,
  disabled: bool,
  handleChange: func.isRequired
};

FormDateInput.defaultProps = {
  required: false,
  disabled: false,
};

export default FormDateInput;
