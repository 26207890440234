import "@babel/polyfill";

import moment from "moment";
import { fetchObjectsWithoutPrimaryImage } from "../api/imageManagementApi";
import { NULL_UUID } from '../api/constants';

export const INITIALIZE_NOIMAGES_FILTER = "INITIALIZE_NOIMAGES_FILTER";
export const CHANGE_NOIMAGES_FILTER = "CHANGE_NOIMAGES_FILTER";

export const LOAD_OBJECTS_NOIMAGES_REQUESTED =
  "LOAD_OBJECTS_NOIMAGES_REQUESTED";
export const LOAD_OBJECTS_NOIMAGES_SUCCESS = "LOAD_OBJECTS_NOIMAGES_SUCCESS";
export const LOAD_OBJECTS_NOIMAGES_FAILURE = "LOAD_OBJECTS_NOIMAGES_FAILURE";

const initializeImageManagementFilterRequested = (fromDate, toDate) => ({
  type: INITIALIZE_NOIMAGES_FILTER,
  fromDate,
  toDate
});

const loadObjectsWithoutImagesRequested = () => ({
  type: LOAD_OBJECTS_NOIMAGES_REQUESTED
});

const loadObjectsWithoutImagesSuccess = objects => ({
  type: LOAD_OBJECTS_NOIMAGES_SUCCESS,
  payload: objects
});

const loadObjectsWithoutImagesFailure = error => ({
  type: LOAD_OBJECTS_NOIMAGES_FAILURE,
  payload: error
});

const changeImageManagementFilterRequested = (id, value) => ({
  type: CHANGE_NOIMAGES_FILTER,
  id,
  value
});


export const initializeImageManagementFilter = () => dispatch => {
  const fromDate = moment(new Date())
    .subtract(30, "days")
    .toISOString()
    .slice(0, 10);
  const toDate = moment(new Date())
    .toISOString()
    .slice(0, 10);

  dispatch(initializeImageManagementFilterRequested(fromDate, toDate));
};

export const loadObjectsWithoutImages = () => async (dispatch, getState) => {

  dispatch(loadObjectsWithoutImagesRequested());

  const { tenantId  } = getState().system;

  if (tenantId === NULL_UUID) {
    return;
  }

  try {

    const { fromDate, toDate, year, season, division } = getState().imageManagement.filter;

    const data = await fetchObjectsWithoutPrimaryImage(fromDate, toDate, year, season, division, tenantId);

    dispatch(loadObjectsWithoutImagesSuccess(data));
  }
  catch (err) {
    dispatch(loadObjectsWithoutImagesFailure(err));
  }
};

export const changeImageManagementFilter = (id, value) => (dispatch) => {
  dispatch(changeImageManagementFilterRequested(id, value));

  if (!(id === "fromDate" || id === "toDate" || id === "year" || id === "season" || id === "division")) {
    return;
  }

  dispatch(loadObjectsWithoutImages());
};


