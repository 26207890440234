import React from "react";
import { Container, Row, Col } from "reactstrap";
import { func, string, arrayOf, shape } from "prop-types";
import SearchItem from "./SearchItem";

const MyArchiveSearchResults = ({ items, saveMyObject, savedObjects, processing }) => (
  <Container fluid className="search-results">
    <Row>
      {items.map(item => (
        <Col key={item.objectId} xs="12" sm="6" md="3">
          <SearchItem
            key={item.objectId}
            item={item}
            requestObject={saveMyObject}
            processing={processing.includes(item.objectId)}
            action={
              savedObjects.some(x => x.objectId === item.objectId) ? "Saved!" : "Save"
            }
          />
        </Col>
      ))}
    </Row>
  </Container>
);

MyArchiveSearchResults.propTypes = {
  items: arrayOf(
    shape({
      objectId: string,
      objectType: string,
      inventoryId: string,
      designer: string
    })
  ).isRequired,
  savedObjects:arrayOf(
    shape({
      objectId: string,
      objectType: string,
      inventoryId: string,
      designer: string
    })
  ).isRequired,
  processing: arrayOf(string).isRequired,
  saveMyObject: func.isRequired
};

export default MyArchiveSearchResults;
