import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from "reactstrap";
import { bool, string, func } from "prop-types";
import FormTextInput from "../common/FormTextInput";

import { locationShape } from "../../models/location";
import {
  saveLocation,
  cancelChanges,
  updateLocationFieldValue,
  updateLocationFormState
} from "../../actions/locationActions";
import FormCheckBox from "../main/components/FormCheckBox";
import {
  editFormHeaderStyle,
  editFormHeaderCheckboxStyle,
  editFormHeaderActionButtonStyle,
  editFormHeaderCancelButtonStyle,
  editFormHeaderNoteStyle,
  editFormRequiredFieldIndicatorStyle
} from "../../styles/formStyles";

let labelPrinter;

class EditLocationPage extends Component {
  handleChange = e => {
    e.preventDefault();

    let value;
    if (event.target.type == "checkbox") {
      value = !!(event.target.type === "checked");
    }
    else {
      value = event.target.value;
    }

    const { updateLocationFieldValue } = this.props;
    updateLocationFieldValue(e.target.id, value);
  };

  handleFormStateChange = event => {
    const { updateLocationFormState } = this.props;
    updateLocationFormState(event.target.id, !!event.target.value);
  };

  handleSave = e => {
    e.preventDefault();

    const { saveLocation } = this.props;
    saveLocation();
  };

  handleCancel = e => {
    e.preventDefault();

    const { cancelChanges } = this.props;
    cancelChanges();
  };

  handlePrintBarcode = (id, e) => {
    e.preventDefault();
    if (id === null) {
      alert("Location Id is not properly set.  Please refresh the page and try again.");
      return;
    }

    const printScript = document.getElementById("barcodeScriptId");

    if (!printScript) {
      const script = document.createElement("script");
      script.src = "/BrowserPrint-2.0.0.75.min.js";
      script.id = "barcodeScriptId";
      document.body.appendChild(script);

      script.onload = () => {
        this.printBarcode(id);
      };
    }

    if (printScript) {
      this.printBarcode(id);
    }
  };

  printBarcode = id => {
    const dataToWrite = `
    ^XA
    ^FO50,50^BY2
    ^BCN,100,Y,N,N
    ^FD>:${id}^FS
    ^XZ
    `;

    this.printLabel(dataToWrite);
  };

  printLabel = dataToWrite => {
    /* eslint-disable-next-line no-undef */
    BrowserPrint.getDefaultDevice(
      "printer",
      function(device) {
        //Add device to list of devices and to html select element
        labelPrinter = device;
        labelPrinter.send(dataToWrite, undefined, function(error) {
          alert(error);
        });
      },
      function(error) {
        alert(error);
      }
    );
  };

  render = () => {
    const {
      action,
      editFormIsOpen,
      isValid,
      isDirty,
      location,
      saveSuccess,
      saving,
      addAnother
    } = this.props;

    return (
      <ReactModal isOpen={editFormIsOpen} ariaHideApp={false}>
        <Alert color="sucess" isOpen={saveSuccess}>
          Save Successful
        </Alert>
        <Alert color="dangery" isOpen={false}>
          Error Saving
        </Alert>

        <div className="editLocationPages">
          <Form>
            <Container
              fluid
              className="form-header"
              style={editFormHeaderStyle}
            >
              <Row>
                <Col xs="3" style={editFormHeaderCheckboxStyle}>
                  <FormCheckBox
                    id="addAnother"
                    label="Add Another"
                    checked={addAnother}
                    handleChange={this.handleFormStateChange}
                  />
                </Col>
                <Col xs="3">
                  <Button
                    style={editFormHeaderCancelButtonStyle}
                    onClick={e =>
                      this.handlePrintBarcode(
                        location.locationId,
                        e
                      )
                    }
                  >
                    Print Barcode
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    onClick={this.handleCancel}
                    style={editFormHeaderCancelButtonStyle}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={this.handleSave}
                    style={editFormHeaderActionButtonStyle}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Container>

            <div className="form-header-title">
              <h2>{action}</h2>
              <div className="title-note" style={editFormHeaderNoteStyle}>
                <span
                  className="required-field-indicator"
                  style={editFormRequiredFieldIndicatorStyle}
                >
                  *
                </span>
                Indicates required field
              </div>
            </div>

            <FormTextInput
              label="Location"
              id="locationName"
              required
              value={location.locationName}
              handleChange={this.handleChange}
            />
          </Form>
        </div>
        <div className="Loading">
          {saving === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Saving...
            </span>
          ) : null}
        </div>
      </ReactModal>
    );
  };
}

EditLocationPage.propTypes = {
  action: string.isRequired,
  editFormIsOpen: bool.isRequired,
  isValid: bool.isRequired,
  isDirty: bool.isRequired,
  saving: bool.isRequired,
  addAnother: bool.isRequired,
  saveSuccess: bool.isRequired,
  location: locationShape.isRequired,
  updateLocationFieldValue: func.isRequired,
  saveLocation: func.isRequired,
  cancelChanges: func.isRequired,
  updateLocationFormState: func.isRequired
};

const mapStateToProps = state => ({
  addAnother: state.location.addAnother,
  action: state.location.action,
  editFormIsOpen: state.location.editFormIsOpen,
  isValid: state.location.isValid,
  isDirty: state.location.isDirty,
  saveSuccess: state.location.saveSuccess,
  location: state.location.editLocation,
  saving: state.location.isSaving,
  statuses: state.system.lists["locationStatuses"]
});

export default connect(
  mapStateToProps,
  {
    updateLocationFieldValue,
    updateLocationFormState,
    saveLocation,
    cancelChanges
  }
)(EditLocationPage);
