import React, { Component } from "react";
import { Auth } from 'aws-amplify';
import { Redirect } from "react-router-dom";
import {
  Container,
  Col,
  Form,
  FormGroup,
  Input,
  Alert
} from "reactstrap";
import ProcessingButton from "../common/ProcessingButton";
import {
  authBackgroundStyle,
  authFormStyle,
} from "../../styles/authStyles";

const noteStyle = {
  marginLeft: "15px",
  marginRight: "15px",
  marginTop: "15px",
  marginBottom: "20px",
  color: "#212529"
}

const noteFormBackgroundStyle = {
  ...authFormStyle,
  width: "520px",
}


// const linkButtonStyle = {
//   ...cancelButtonStyle,
//   borderColor: "#6c757d",
//   borderWidth: "1px"
// }

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      processing: false,
      toDashboard: false,
      saveSuccess: false
      //error: '',
    }
  }

  handleChange = e => {
    e.preventDefault();

    this.setState({[event.target.id]: event.target.value });
  }

  handleSubmit = () => {
    const { oldPassword, password1 } = this.state;

    this.setState({
      processing: true
    });

    Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, password1);
    })
    .then(data => {
      if (data.toUpperCase() === "SUCCESS") {
        this.setState({
          saveSuccess: true,
          processing: false,
          toDashboard: true
        });
      }
    })
    .catch(() => {
      this.setState({
        processing: false,
        errorSaving: true,
      });
    });
  }

  cancelChangePassword = () => {
    this.setState({
      processing: false,
      errorSaving: false,
      toDashboard: true
    });

  }

  formIsValid = () => {
    const {password1, password2, oldPassword }  = this.state || "";

    const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[!@#$%^&*.{}?-`~_|;:'<>,()"/\]\\])[a-zA-Z0-9!@#$%^&*.{}?-`~_|;:'<>,()"/\\\]]{10,20}$/;

    return password1 &&
      password2 &&
      password1 === password2 &&
      password1 !== oldPassword &&
      re.test(password1)

  }

  render() {
    const { processing, toDashboard, saveSuccess, errorSaving } = this.state;
    if (toDashboard) {
      return (
        <Redirect to="/" />
      )
    }
    const formIsValid = this.formIsValid();

    const specialChars = "^ $ * . ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ `"
    return (
      <div>
        <div>
          <Alert color="success" isOpen={saveSuccess}>
            Save Successful
          </Alert>
          <Alert color="dangery" isOpen={errorSaving}>
            There was a problem saving your password.  Please re-enter and try again.
          </Alert>
        </div>
        <div className="change-password-background" style={authBackgroundStyle}>

          <Container className="change-password-container" style={noteFormBackgroundStyle}>
            <h2 style={{textAlign: "center", color: "black"}}>Reset your password</h2>
            <div className="change-password-note" style={noteStyle}>
              Passwords must be at least 10 characters long, include
              <br />
              a lowercase character,
              an uppercase character,
              a number and
              a special character:
              <br />
              {specialChars}
            </div>
            <Form>
              <Col>
                <FormGroup>
                  <Input
                    id="oldPassword"
                    name="oldPassword"
                    placeholder="Enter Current Password"
                    type="password"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    id="password1"
                    name="password1"
                    placeholder="Enter New Password"
                    type="password"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    id="password2"
                    name="password2"
                    placeholder="Re-enter New Password"
                    type="password"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col style={{marginTop: "30px"}}>
                <ProcessingButton
                  id="changePassword"
                  buttonLabel="Submit"
                  processingLabel="Submitting.."
                  isDisabled={!formIsValid}
                  processing={processing}
                  buttonStyle="dark"
                  handleClick={() => this.handleSubmit()}
                />
                <ProcessingButton
                  id="cancelChangePassword"
                  buttonLabel="Cancel"
                  processingLabel="Canceling.."
                  buttonStyle="light"
                  handleClick={() => this.cancelChangePassword()}
                />

              </Col>
              {/* <Col>
                <Label>{error}</Label>
              </Col> */}
            </Form>
          </Container>
        </div>
      </div>
    )
  }
}

export default ChangePasswordPage;
