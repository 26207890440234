import { Logger } from "aws-amplify";
import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from "reactstrap";
import { bool, string, func, arrayOf } from "prop-types";
import FormTextInput from "../common/FormTextInput";
import FormDateInput from "../common/FormDateInput";
import FormSelectInput from "../main/components/FormSelectInput";
import ViewRelatedItemImage from "../main/ViewRelatedItemImage";
import { shipmentShape } from "../../models/shipment";
import {
  saveShipment,
  cancelChanges,
  updateShipmentFieldValue
} from "../../actions/shipmentActions";
import {
  makeOptionListFromStringArray,
  makeOptionValueFromString
} from "../../tools/dropdownListUtils";

import {
  editFormHeaderStyle,
  editFormHeaderActionButtonStyle,
  editFormHeaderCancelButtonStyle,
} from "../../styles/formStyles"
import { ImageNumber } from "../../api/constants";

const logger = new Logger("EditShipmentPage");

class EditShipmentPage extends Component {
  handleChange = e => {
    e.preventDefault();

    logger.info("handleChange", e);
    let value;
    if (event.target.type == "checkbox") {
      value = !!(event.target.type === "checked");
    }
    else {
      value = event.target.value;
    }

    const { updateShipmentFieldValue } = this.props;
    updateShipmentFieldValue(e.target.id, value);

  };

  handleDateChange = (id, date) => {
    const { updateShipmentFieldValue } = this.props;
    updateShipmentFieldValue(id, date.toISOString().slice(0, 10));

  };

  handleSelectChange = (newValue, actionMeta) => {
    const action = actionMeta.action;
    const name = actionMeta.name;

    let value;

    if (action === "clear") {
      value = "";
    }
    else if (action === "select-option") {
      value = newValue.value;
    }
    else {
      return;
    }

    const { updateShipmentFieldValue } = this.props;
    updateShipmentFieldValue(name, value);
  };

  handleSave = e => {
    e.preventDefault();

    const { saveShipment } = this.props;
    saveShipment();
  };

  handleCancel = e => {
    e.preventDefault();

    const { cancelChanges } = this.props;
    cancelChanges();
  };

  render = () => {
    const {
      action,
      editFormIsOpen,
      isValid,
      isDirty,
      shipment,
      saveSuccess,
      saving,
      statuses
    } = this.props;

    logger.info("Shipment", shipment);

    return (
      <ReactModal isOpen={editFormIsOpen} ariaHideApp={false}>
        <Alert color="sucess" isOpen={saveSuccess}>
          Save Successful
        </Alert>
        <Alert color="dangery" isOpen={false}>
          Error Saving
        </Alert>

        <div className="editShipmentPages">
          <Form>
            <Container fluid className="form-header" style={editFormHeaderStyle}>
              <Row>
                <Col xs="6">
                  <h2>{action}</h2>
                </Col>
                <Col xs="3">
                  <Button
                    onClick={this.handleCancel}
                    style={editFormHeaderCancelButtonStyle}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={this.handleSave}
                    style={editFormHeaderActionButtonStyle}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Container>
            <Row>
              {shipment.objects.map(obj => (
                <Col sm={4} key={obj.objectId}>
                  <ViewRelatedItemImage
                    key={obj.objectId}
                    clientId={shipment.clientId}
                    objectId={obj.objectId}
                    inventoryId={obj.inventoryId}
                    imageNumber={ImageNumber.SearchImage}
                  />
                </Col>
              ))}
            </Row>

            <FormTextInput
              label="Description"
              id="description"
              required
              value={shipment.description}
              handleChange={this.handleChange}
            />

            <FormTextInput
              label="Requested By"
              id="requestedBy"
              value={shipment.requestedBy}
              handleChange={this.handleChange}
            />

            <FormDateInput
              label="Date Requested"
              id="dateRequested"
              required
              value={shipment.dateRequested}
              handleChange={this.handleDateChange}
            />

            <FormDateInput
              label="Date Shipped"
              id="dateShipped"
              value={shipment.dateShipped}
              handleChange={this.handleDateChange}
            />

            <FormTextInput
              label="Carrier"
              id="carrier"
              value={shipment.carrier}
              handleChange={this.handleChange}
            />

            <FormTextInput
              label="Shipped To"
              id="shippedTo"
              type="textarea"
              value={shipment.shippedTo}
              handleChange={this.handleChange}
            />

            <FormTextInput
              label="Tracking Number"
              id="trackingNumber"
              value={shipment.trackingNumber}
              handleChange={this.handleChange}
            />

            <FormDateInput
              label="Date Received"
              id="dateReceived"
              value={shipment.dateReceived}
              handleChange={this.handleDateChange}
            />

            <FormSelectInput
              label="Status"
              id="status"
              required
              isClearable
              placeholder="Select Shipment Status"
              options={makeOptionListFromStringArray(statuses)}
              value={makeOptionValueFromString(shipment.status)}
              handleChange={(newValue, actionMeta) => this.handleSelectChange(newValue, actionMeta)}
            />
          </Form>
        </div>
        <div className="Loading">
          {saving === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Saving...
            </span>
          ) : null}
        </div>
      </ReactModal>
    );
  };
}

EditShipmentPage.propTypes = {
  action: string.isRequired,
  editFormIsOpen: bool.isRequired,
  isValid: bool.isRequired,
  isDirty: bool.isRequired,
  saving: bool.isRequired,
  saveSuccess: bool.isRequired,
  shipment: shipmentShape.isRequired,
  updateShipmentFieldValue: func.isRequired,
  saveShipment: func.isRequired,
  cancelChanges: func.isRequired,
  statuses: arrayOf(string).isRequired

};

const mapStateToProps = state => ({
  action: state.shipment.action,
  editFormIsOpen: state.shipment.editFormIsOpen,
  isValid: state.shipment.isValid,
  isDirty: state.shipment.isDirty,
  saveSuccess: state.shipment.saveSuccess,
  shipment: state.shipment.editShipment,
  saving: state.shipment.isSaving,
  statuses: state.system.lists["shipmentStatuses"]
});

export default connect(
  mapStateToProps,
  {
    updateShipmentFieldValue,
    saveShipment,
    cancelChanges
  }
)(EditShipmentPage);
