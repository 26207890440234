import React from 'react';
import {
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import { string, func } from 'prop-types';
import SearchIcon from "../../assets/images/icons/search.png";

const searchBarStyle = {
  position: "relative"
}

const searchIconStyle = {
  position: "absolute",
  left: "10px",
  bottom: "10px",
  zIndex: 2,
  cursor: "pointer"
}

const searchInputStyle = {
  paddingLeft: "40px"
}

const SearchBar = ({searchTerm, doSearch, handleChange}) => (
  <div className="search-bar" style={searchBarStyle}>
    <img src={SearchIcon} alt="Search" style={searchIconStyle} onClick={doSearch} aria-hidden />
    <Form onSubmit={doSearch}>
      <FormGroup>
        <Input
          id="searchTerm"
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleChange}
          style={searchInputStyle}
        />
      </FormGroup>
    </Form>
  </div>
);

SearchBar.propTypes = {
  searchTerm: string.isRequired,
  doSearch: func.isRequired,
  handleChange: func.isRequired,
}

export default SearchBar;
