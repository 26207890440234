const DEV_APP_CLIENT_ID = "58va42k9opvi5t10pd23b70off";
const DEV_USER_POOL_ID = "us-east-1_5h1AQM342";
const DEV_COGNITO_REGION = "us-east-1";
const DEV_IDENTITY_POOL_ID = "us-east-1:d47da796-88e4-4b1d-bddb-1160421df7fc";
const DEV_FILE_UPLOAD_BUCKET = "test-digital-archivist-assets";
const DEV_FILE_UPLOAD_BUCKET_REGION = "us-east-1";
const DEV_API_GATEWAY_REGION = "us-east-1";
const DEV_API_GATEWAY_URL = "";
const DEV_API_GATEWAY_ENDPOINT_NAME = "";
const MANDATORY_SIGNIN = true;
const AUTHENTICATION_FLOW_TYPE = "USER_SRP_AUTH";

const STAGING_APP_CLIENT_ID = "7u5ptkuklv2juq521oqt8e2uqa";
const STAGING_USER_POOL_ID = "us-east-1_EBzNyOZF9";
const STAGING_COGNITO_REGION = "us-east-1";
const STAGING_IDENTITY_POOL_ID = "us-east-1:475e616d-b0fd-4c2d-b48f-78a5c4947b1c";
const STAGING_FILE_UPLOAD_BUCKET = "staging-digitalarchivist-assets";
const STAGING_FILE_UPLOAD_BUCKET_REGION = "us-east-1";
const STAGING_API_GATEWAY_REGION = "us-east-1";
const STAGING_API_GATEWAY_URL = "";
const STAGING_API_GATEWAY_ENDPOINT_NAME = "";

const PROD_APP_CLIENT_ID = "7nv1obd8qpj0hn7s1g4giur1dg";
const PROD_USER_POOL_ID = "us-east-1_oLYtOf5bc";
const PROD_COGNITO_REGION = "us-east-1";
const PROD_IDENTITY_POOL_ID = "us-east-1:7a1fca8f-e6cb-498b-82c5-118fc819d302";
const PROD_FILE_UPLOAD_BUCKET = "prod-digitalarchivist-assets";
const PROD_FILE_UPLOAD_BUCKET_REGION = "us-east-1";
const PROD_API_GATEWAY_REGION = "us-east-1";
const PROD_API_GATEWAY_URL = "";
const PROD_API_GATEWAY_ENDPOINT_NAME = "";

const dev = {
  Auth: {
    identityPoolId: DEV_IDENTITY_POOL_ID,
    region: DEV_COGNITO_REGION,
    userPoolId: DEV_USER_POOL_ID,
    userPoolWebClientId: DEV_APP_CLIENT_ID,
    mandatorySignIn: MANDATORY_SIGNIN,
    authenticationFlowType: AUTHENTICATION_FLOW_TYPE
  },
  Storage: {
    AWSS3: {
      region: DEV_FILE_UPLOAD_BUCKET_REGION,
      bucket: DEV_FILE_UPLOAD_BUCKET,
    }
  },
  API: {
    endpoints: [
      {
        name: DEV_API_GATEWAY_ENDPOINT_NAME,
        endpoint: DEV_API_GATEWAY_URL,
        region: DEV_API_GATEWAY_REGION
      }
    ]
  }
};

const staging = {
  Auth: {
    identityPoolId: STAGING_IDENTITY_POOL_ID,
    region: STAGING_COGNITO_REGION,
    userPoolId: STAGING_USER_POOL_ID,
    userPoolWebClientId: STAGING_APP_CLIENT_ID,
    mandatorySignIn: MANDATORY_SIGNIN,
    authenticationFlowType: AUTHENTICATION_FLOW_TYPE
  },
  Storage: {
    AWSS3: {
      region: STAGING_FILE_UPLOAD_BUCKET_REGION,
      bucket: STAGING_FILE_UPLOAD_BUCKET,
    }
  },
  API: {
    endpoints: [
      {
        name: STAGING_API_GATEWAY_ENDPOINT_NAME,
        endpoint: STAGING_API_GATEWAY_URL,
        region: STAGING_API_GATEWAY_REGION
      }
    ]
  }
};

const prod = {
  Auth: {
    identityPoolId: PROD_IDENTITY_POOL_ID,
    region: PROD_COGNITO_REGION,
    userPoolId: PROD_USER_POOL_ID,
    userPoolWebClientId: PROD_APP_CLIENT_ID,
    mandatorySignIn: MANDATORY_SIGNIN,
    authenticationFlowType: AUTHENTICATION_FLOW_TYPE
  },
  Storage: {
    AWSS3: {
      region: PROD_FILE_UPLOAD_BUCKET_REGION,
      bucket: PROD_FILE_UPLOAD_BUCKET,
    }
  },
  API: {
    endpoints: [
      {
        name: PROD_API_GATEWAY_ENDPOINT_NAME,
        endpoint: PROD_API_GATEWAY_URL,
        region: PROD_API_GATEWAY_REGION
      }
    ]
  }
}

const config = () => {
  // eslint-disable-next-line no-undef
  const stage = __STAGE__;
  if (stage === 'staging') {
    return staging;
  }
  else if (stage === 'production') {
    return prod;
  }
  else if (stage === 'development') {
    return dev;
  }
  else {
    return prod;
  }

}

export default {
  MAX_ATTACHMENT_SIZE: 10000000,
  ...config()
}
