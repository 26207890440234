export const downloadBlob = async (data, fileName) => {
  let blob, url;

  blob = new Blob([data.Body],
    {type: 'application/octet-stream'}
  );

  url = window.URL.createObjectURL(blob);
  downloadUrl(url, fileName);
  setTimeout(function() {
    return window.URL.revokeObjectURL(url);
  }, 1000);
}

export const downloadUrl = (url, fileName) => {
  let a = document.createElement('a');

  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.style = "display: none";
  a.click();
  a.remove();
}

//export default downloadBlob;
