export const filterHeaderStyle = {
  marginBottom: "30px"
};

export const filterPageActionButtonStyle = {
  backgroundColor: '#344D5D',
  textTransform: 'uppercase',
  padding: '16px',
  fontFamily: 'proxima-nova',
  fontWeight: '600px',
  cursor: "pointer"
}

export const dateInputFilterStyle = {
  display: "inline",
  width: "115px"
};

export const dateSplitterStyle = {
  display: "inline",
  marginLeft: "5px",
  marginRight: "5px"
};

export const filterPageHeaderStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px"
};


export const viewHeaderButtonStyle = {
  backgroundColor: "#344D5D",
  fontSize: "13px",
  textAlign: "center",
  textTransform: "uppercase",
  borderRadius: "3px",
  border: "1px solid #808080",
  height: "45px",
  width: "160px",
  fontFamily: "proxima-nova",
  fontWeight: "400px",
};

export const hiddenButtonStyle = {
  ...viewHeaderButtonStyle,
  visibility: "hidden"
}

// FILTER FORM STYLES
export const filterLabelStyle = {
  fontFamily: 'proxima-nova',
  fontSize: "13px",
  color: "#808080",
  display: "block"
};

export const filterFormButtonActionStyle = {
  backgroundColor: '#344D5D',
  textTransform: 'uppercase',
  padding: '16px',
  fontFamily: 'proxima-nova',
  fontWeight: '600px',
}

export const tableHeaderStyle = {
  backgroundColor: "#EBEBEB"
};

export const tableHeaderButtonStyle = {
  backgroundColor: "#EBEBEB",
  cursor: "pointer"
}

export const tableButtonStyle = {
  cursor: "pointer"
}

// EDIT FORM STYLES
export const editFormHeaderStyle = {
  marginBottom: "10px",
  top: 0,
  backgroundColor: "white",
  paddingTop: "30px",
  paddingBottom: "10px",
  zIndex: 2,
};

const editFormHeaderButtonStyle = {
  fontSize: "11px",
  textAlign: "center",
  textTransform: "uppercase",
  borderRadius: "3px",
  border: "1px solid #808080",
  height: "40px",
  width: "145px",
  fontFamily: "proxima-nova",
  fontWeight: "600px",
};

export const editFormHeaderActionButtonStyle = {
  ...editFormHeaderButtonStyle,
  color: "white",
  backgroundColor: "#344D5D"
};

export const editFormHeaderCancelButtonStyle = {
  ...editFormHeaderButtonStyle,
  color: "#808080",
  backgroundColor: "white"
};

export const editFormHeaderCheckboxStyle = {
  fontSize: "14px",
  color: "#838383"
};

export const editFormHeaderNoteStyle = {
  fontSize: "12px",
  color: "#707070",
  marginTop: "-0.5rem",
  marginBottom: "10px"
};

export const editFormRequiredFieldIndicatorStyle = {
  color: "#FF0000",
  paddingRight: ".5em"
};

export const urlLinkStyle = {
  color: "#808080",
}

export const buttonUrlLinkStyle = {
  color: "#808080",
  backgroundColor: "transparent",
  border: "none",
  textDecoration: "underline",
  padding: "0px"
}
