import "@babel/polyfill";
import {
  createLocation,
  persistLocation,
  fetchLocations
} from "../api/locationApi";
import objectsAreEqual from "../tools/objectChecker";

export const LOAD_LOCATIONS_REQUESTED = "LOAD_LOCATIONS_REQUESTED";
export const LOAD_LOCATIONS_SUCCESS = "LOAD_LOCATIONS_SUCCESS";
export const LOAD_LOCATIONS_FAILURE = "LOAD_LOCATIONS_FAILURE";

export const SAVE_LOCATION_REQUESTED = "SAVE_LOCATION_REQUESTED";
export const SAVE_LOCATION_SUCCESS = "SAVE_LOCATION_SUCCESS";
export const SAVE_LOCATION_FAILURE = "SAVE_LOCATION_FAILURE";

export const CHECK_LOCATION_IS_VALID = "CHECK_LOCATION_IS_VALID";
export const CHECK_LOCATION_IS_DIRTY = "CHECK_LOCATION_IS_DIRTY";
export const UPDATE_LOCATION_FIELD_VALUE = "UPDATE_LOCATION_FIELD_VALUE";
export const UPDATE_LOCATION_FORM_STATE = "UPDATE_LOCATION_FORM_STATE";

export const SHOW_LOCATION_EDIT_FORM = "SHOW_LOCATION_EDIT_FORM";
export const ADD_LOCATION_REQUESTED = "ADD_LOCATION_REQUESTED";
export const EDIT_LOCATION_REQUESTED = "EDIT_LOCATION_REQUESTED";
export const CHANGE_LOCATION_FILTER = "CHANGE_LOCATION_FILTER";
export const CANCEL_LOCATION_EDIT_FORM = "CANCEL_LOCATION_EDIT_FORM"
export const INITIALIZE_LOCATION_FILTER = "INITIALIZE_LOCATION_FILTER"

export const changeLocationFilterRequested = (id, value) => ({
  type: CHANGE_LOCATION_FILTER,
  id,
  value
});

export const changeLocationFilter = (id, value) => (dispatch) => {
  dispatch(changeLocationFilterRequested(id, value));
};

const saveLocationRequested = () => ({
  type: SAVE_LOCATION_REQUESTED
});

const saveLocationSuccess = location => ({
  type: SAVE_LOCATION_SUCCESS,
  payload: location
});

const saveLocationFailure = error => ({
  type: SAVE_LOCATION_FAILURE,
  payload: { ...error }
});

export const cancelLocationEdit = () => ({
  type: CANCEL_LOCATION_EDIT_FORM
});

export const addLocationRequested = location => ({
  type: ADD_LOCATION_REQUESTED,
  payload: location
});

export const editLocationRequested = location => ({
  type: EDIT_LOCATION_REQUESTED,
  payload: location
});

const showLocationIsValid = valid => ({
  type: CHECK_LOCATION_IS_VALID,
  payload: valid
});

const updateLocation = (id, value) => ({
  type: UPDATE_LOCATION_FIELD_VALUE,
  id,
  value
});

const loadLocationsRequested = () => ({
  type: LOAD_LOCATIONS_REQUESTED
});

const loadLocationsSuccess = locations => ({
  type: LOAD_LOCATIONS_SUCCESS,
  payload: locations
});

const loadLocationsFailure = error => ({
  type: LOAD_LOCATIONS_FAILURE,
  payload: error
});

const setformState = (id, value) => ({
  type: UPDATE_LOCATION_FORM_STATE,
  id,
  value
});

export const updateLocationFormState = (id, value) => (dispatch) => {
  dispatch(setformState(id, value));
}

export const updateLocationFieldValue = (id, value) => async (
  dispatch,
  getState
) => {
  await dispatch(updateLocation(id, value));

  const { editLocation } = getState().location;

  dispatch(showLocationIsValid(validateLocation(editLocation)));
  dispatch(locationIsDirty());
};

const locationIsDirty = () => async (dispatch, getState) => {
  const { editLocation, originalLocation } = getState().location;

  const isDirty = !objectsAreEqual(editLocation, originalLocation);

  await dispatch({
    type: CHECK_LOCATION_IS_DIRTY,
    isDirty
  });
};

export const loadLocations = () => async dispatch => {
  dispatch(loadLocationsRequested());
  try {
    const data = await fetchLocations();
    dispatch(loadLocationsSuccess(data));
  }
  catch (err) {
    dispatch(loadLocationsFailure(err));
  }
};

export const cancelChanges = () => async (dispatch) => {
  dispatch(cancelLocationEdit());
}

export const addLocation = () => async (dispatch, getState) => {
  const { editFormIsOpen } = getState().location.editFormIsOpen;
  const newLocation = createLocation();

  await dispatch(addLocationRequested(newLocation));
  await dispatch(validateLocation());
  if (!editFormIsOpen) {
    await dispatch(showEditForm());
  }
}

const showEditForm = () => ({
  type: SHOW_LOCATION_EDIT_FORM
});

export const editLocation = locationId => async (dispatch, getState) => {
  const { locations } = getState().location;
  const location = locations.filter(x => x.locationId === locationId)[0];
  if (location) {
    dispatch(editLocationRequested(location));
  }
};

export const saveLocation = () => async (dispatch, getState) => {
  const { editLocation, addAnother } = getState().location;

  dispatch(saveLocationRequested());

  try {
    const success = await persistLocation(editLocation);

    if (success) {
      dispatch(saveLocationSuccess(editLocation));
      if (addAnother) {
        dispatch(addLocation());
      }
      else {
        dispatch(loadLocations());
        dispatch(cancelLocationEdit());
      }

    }
    else {
      dispatch(saveLocationFailure("Error saving location"));
    }
  }
  catch (err) {
    dispatch(saveLocationFailure(err));
  }
};

const validateLocation = () => async (dispatch, getState) => {
  const { editLocation } = getState().location;

  const isValid = !!(editLocation.locationName) && editLocation.locationName.length > 0;

  dispatch(showLocationIsValid(isValid));

}
