import {
  FETCH_MYARCHIVE_REQUESTED,
  FETCH_MYARCHIVE_SUCCESS,
  FETCH_MYARCHIVE_FAILURE,
  REMOVE_MYARCHIVE_REQUESTED,
  REMOVE_MYARCHIVE_SUCCESS,
  REMOVE_MYARCHIVE_FAILURE,
  REMOVE_ALL_MYARCHIVE_REQUESTED,
  REMOVE_ALL_MYARCHIVE_SUCCESS,
  REMOVE_ALL_MYARCHIVE_FAILURE,
  SAVE_MYARCHIVE_SUCCESS,
} from "../actions/myArchiveActions";
import { LOGOUT_SUCCESS } from "../actions/authActions";

const initialState = {
  savedObjects: [],
  processing: [],
  error: null
};

const myArchiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MYARCHIVE_REQUESTED:
      return {
        ...state,
      };

    case FETCH_MYARCHIVE_SUCCESS:
      return {
        ...state,
        savedObjects: action.payload,
        error: null,
      };

    case FETCH_MYARCHIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case REMOVE_MYARCHIVE_REQUESTED:
      return {
        ...state,
        processing: true
      };

    case REMOVE_MYARCHIVE_SUCCESS:
      return {
        ...state,
        savedObjects: [
          ...state.savedObjects.filter(
            x => x.objectId !== action.objectId
          )
        ],
        error: null,
        processing: false
      };

    case REMOVE_MYARCHIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
        processing: false
      };

    case REMOVE_ALL_MYARCHIVE_REQUESTED:
      return {
        ...state,
        processing: true
      };

    case REMOVE_ALL_MYARCHIVE_SUCCESS:
      return {
        ...state,
        savedObjects: [],
        error: null,
        processing: false
      };

    case REMOVE_ALL_MYARCHIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
        processing: false
      };

    case SAVE_MYARCHIVE_SUCCESS:
      return {
        ...state,
        savedObjects: [
          ...state.savedObjects.filter(
            x => x.objectId !== action.payload.objectId
          ),
          action.payload
        ],
        error: null,
      };

    case LOGOUT_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default myArchiveReducer;
