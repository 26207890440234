import React from "react";
import { string, bool } from "prop-types";

const hiddenStyle = {
  display: "none"
};

const noteRowStyle = {
  display: "flex",
  marginBottom: "15px"
};

const archiveNoteRowStyle = {
  ...noteRowStyle,
  paddingTop: "30px",
  borderTop: "solid 1px #808080"
};

const notesLabelStyle = {
  color: "black",
  fontWeight: "600",
  width: "180px"
};

const notesValueStyle = {
  color: "black"
};


const NotePanel = ({ label, value, styleName, hidden }) => (
  <div style={hidden 
    ? hiddenStyle 
    : styleName === "archiveNote" 
    ? archiveNoteRowStyle 
    : noteRowStyle}
  >
    <div style={hidden ? hiddenStyle : notesLabelStyle}>{label}</div>
    <div style={hidden ? hiddenStyle : notesValueStyle}>{value}</div>
  </div>
);

NotePanel.propTypes = {
  label: string.isRequired,
  value: string,
  styleName: string,
  hidden: bool
};

NotePanel.defaultProps = {
  styleName: "",
  hidden: false,
  value: ""
};

export default NotePanel;
