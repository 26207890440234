import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { func, string, arrayOf, shape, bool } from "prop-types";
import { Table, Button, Input } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  loadRequestedObjects,
  selectObject,
  unselectObject,
  filterRequests,
  selectAll
} from "../../actions/requestActions";
import { removeObject } from "../../actions/searchActions";
import { addShipment } from "../../actions/shipmentActions";
import CheckBoxInput from "../common/CheckBoxInput";
import { NULL_UUID, ImageNumber } from "../../api/constants";
import { requestedObjectShape } from "../../models/requestedObject";
import EditShipmentPage from "../shipments/EditShipmentPage";
import RequestObjectImage from "./RequestObjectImage";
import removeIcon from "../../assets/images/remove-2.png";
import {
  filterPageHeaderStyle,
  filterLabelStyle,
  tableButtonStyle
} from "../../styles/formStyles";
import inventoryIdFormat from "../../tools/objectFormatters"

//const logger = new Logger("ObjectsRequestPage");

const clientFilterStyle = {
  maxWidth: "40%",
  marginBottom: "30px"
};


const createShipmentButtonStyle = {
  backgroundColor: "#344D5D",
  textTransform: "uppercase",
  padding: "16px",
  fontFamily: "proxima-nova",
  fontWeight: "600px"
};

const tableHeaderStyle = {
  backgroundColor: "#EBEBEB"
};

const rowCellStyle = {
  textAlign: "left",
  verticalAlign: "middle"
};

const rowButtonCellStyle = {
  textAlign: "center",
  verticalAlign: "middle"
};

class RequestObjectsPage extends Component {

  componentDidMount = () => {
    const { loadRequestedObjects } = this.props;
    loadRequestedObjects();

  };

  createShipment = () => {
    const { addShipment } = this.props;
    addShipment();
  };

  filterByClient = e => {
    const { filterRequests } = this.props;
    filterRequests(e.target.value);
  };

  handleSelectAll = async e => {
    const { selectAll } = this.props;

    const success = await selectAll(e.target.value);
    if (success === false) {
      toast.info("You may not select objects from different clients.  Please try again.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
    }
  };

  handleRemoveRequest = objectId => {
    const { removeObject } = this.props;
    removeObject(objectId);
  };

  handleSelectObject = (itemId, clientId, e) => {
    const {
      selectObject,
      unselectObject,
      selectedObjects
    } = this.props;

    for (const obj of selectedObjects) {
      if (clientId !== obj.clientId) {

        toast.info("You may not select objects from different clients.  Please try again.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
        });

        return;
      }
    }

    if (e.target.value) {
      selectObject(e.target.id);
    }
    else {
      unselectObject(e.target.id);
    }
  };

  render() {
    const {
      userRole,
      clientList,
      items,
      loading,
      clientId,
      allSelected,
      selectedObjects
    } = this.props;

    if (!(userRole === "System Administrator" || userRole === "Digital Archivist Administrator" || userRole === "Digital Archivist User")) {
      return (
        <div>
          <h2>Unauthorized</h2>
          Please contact the system administrator.
        </div>
      );
    }


    const hasObjectsSelected = selectedObjects.length > 0;

    return (
      <div className="requests">
        <div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
        </div>
        <div className="requests-header" style={filterPageHeaderStyle}>
          <h2>Object Requests</h2>
          <div>
            <Button
              disabled={!hasObjectsSelected}
              onClick={this.createShipment}
              style={createShipmentButtonStyle}
            >
              Create Shipment
            </Button>
          </div>
        </div>

        <div className="requests-filter" style={clientFilterStyle}>
          <div className="requests-filter-label" style={filterLabelStyle}>
            Filter by Client
          </div>
          <div className="requests-filter-select">
            <Input
              type="select"
              id="clientRequestFilter"
              onChange={this.filterByClient}
            >
              <option key="-999" value={NULL_UUID}>
                ALL CLIENTS
              </option>
              {clientList.map(x => (
                <option key={x.clientId} value={x.clientId}>
                  {x.clientName}
                </option>
              ))}
            </Input>
          </div>
        </div>

        <div className="requests-table">
          <Table responsive>
            <thead style={tableHeaderStyle}>
              <tr>
                <th>
                  <CheckBoxInput
                    id="selectAll"
                    name="selectAll"
                    checked={allSelected}
                    handleChange={this.handleSelectAll}
                  />
                </th>
                <th>{null}</th>
                <th>Inventory Id</th>
                <th>Client</th>
                <th>Object Type</th>
                <th>Location</th>
                <th>Request Date</th>
                <th style={rowButtonCellStyle}>Remove</th>
              </tr>
            </thead>
            <tbody>
              {items
                .filter(item => {
                  return item.clientId === clientId || clientId === NULL_UUID;
                })
                .map(item => (
                  <tr key={item.objectId}>
                    <td style={rowCellStyle}>
                      <CheckBoxInput
                        id={item.objectId}
                        checked={
                          selectedObjects.filter(
                            x => x.objectId === item.objectId
                          ).length > 0
                        }
                        handleChange={(e) => this.handleSelectObject(item.objectId, item.clientId, e)}
                      />
                    </td>
                    <td style={rowCellStyle}>
                      <RequestObjectImage
                        clientId={item.clientId}
                        objectId={item.objectId}
                        imageNumber={ImageNumber.SearchImage}
                      />
                    </td>
                    <td style={rowCellStyle}>{inventoryIdFormat(item.inventoryId)}</td>
                    <td style={rowCellStyle}>{item.clientName}</td>
                    <td style={rowCellStyle}>{item.objectType}</td>
                    <td style={rowCellStyle}>{item.location}</td>
                    <td style={rowCellStyle}>
                      {moment(new Date(item.dateCreated)).format("MM/DD/YYYY")}
                    </td>
                    <td style={rowButtonCellStyle}>
                      <div
                        className="removeButton"
                        role="button"
                        aria-hidden
                        style={tableButtonStyle}
                        onClick={e => {
                          e.preventDefault();
                          this.handleRemoveRequest(item.objectId);
                        }}
                      >
                        <img src={removeIcon} alt="Remove pending check-in" />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="Loading">
          {loading === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Loading...
            </span>
          ) : null}
        </div>

        <EditShipmentPage />
      </div>
    );
  }
}

RequestObjectsPage.propTypes = {
  userRole: string.isRequired,
  clientId: string,
  clientList: arrayOf(
    shape({
      tenantId: string,
      client: string
    })
  ).isRequired,
  items: arrayOf(requestedObjectShape).isRequired,
  loading: bool.isRequired,
  allSelected: bool,
  selectedObjects: arrayOf(requestedObjectShape).isRequired,
  loadRequestedObjects: func.isRequired,
  selectObject: func.isRequired,
  unselectObject: func.isRequired,
  filterRequests: func.isRequired,
  selectAll: func.isRequired,
  addShipment: func.isRequired,
  removeObject: func.isRequired
};

RequestObjectsPage.defaultProps = {
  allSelected: false,
  clientId: NULL_UUID
};

const mapStateToProps = state => {
  return {
    userRole: state.user.profile.role,
    allSelected: state.requests.allSelected,
    clientId: state.requests.clientId,
    items: state.requests.requestedObjects,
    selectedObjects: state.requests.selectedObjects,
    clientList: state.system.lists["clients"],
    loading: state.requests.loading
  };
};

export default connect(
  mapStateToProps,
  {
    loadRequestedObjects,
    selectObject,
    unselectObject,
    filterRequests,
    selectAll,
    addShipment,
    removeObject
  }
)(RequestObjectsPage);
