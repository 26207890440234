import {
  LOAD_SYSTEM_LISTS_REQUESTED,
  LOAD_SYSTEM_LISTS_SUCCESS,
  LOAD_SYSTEM_LISTS_FAILURE,
  LOAD_SYSTEM_CLIENT_LIST_REQUESTED,
  LOAD_SYSTEM_CLIENT_LIST_SUCCESS,
  LOAD_SYSTEM_CLIENT_LIST_FAILURE,
  SET_CLIENT_ID,
  SAVE_SYSTEM_LIST_SUCCESS,
  SAVE_SYSTEM_LIST_FAILURE,
  LOAD_LOCATION_LIST_SUCCESS,
  LOAD_LOCATION_LIST_FAILURE
} from "../actions/systemActions";
import {
  LOAD_SYSTEMUSER_LIST_REQUESTED,
  LOAD_SYSTEMUSER_LIST_SUCCESS,
  LOAD_SYSTEMUSER_LIST_FAILURE,
} from "../actions/systemUserActions";

import { SAVE_CLIENT_SUCCESS } from "../actions/clientActions";

import { LOGOUT_SUCCESS } from "../actions/authActions";
import { NULL_UUID } from "../api/constants";

export const initialLists = {
  colors: [],
  designers: [],
  objectTypes: [],
  clients: [],
  tags: [],
  shipmentStatuses: [],
  staff: []
};

const initialState = {
  lists: initialLists,
  tenantId: NULL_UUID,
  clientName: "",
  loading: false,
  error: null
};

const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SYSTEM_LISTS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      };

    case LOAD_SYSTEM_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        lists: {
          ...state.lists,
          ...action.payload
        }

      };

    case LOAD_SYSTEM_LISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };


    case LOAD_SYSTEM_CLIENT_LIST_REQUESTED:
        return {
          ...state,
          loading: true
        };

    case LOAD_SYSTEM_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        lists: {
          ...state.lists,
          clients: action.payload
        }

      };

    case LOAD_LOCATION_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          lists: {
            ...state.lists,
            locations: action.payload.map(x => x.locationName)
          }

        };
    case LOAD_SYSTEM_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        client: [],
        error: action.payload
      };

    case LOAD_LOCATION_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          locations: [],
          error: action.payload
        };

    case SET_CLIENT_ID:
      return {
        ...state,
        tenantId: action.tenantId,
        clientName: action.clientName
      };

    case SAVE_SYSTEM_LIST_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          [action.listName] : [
            ...action.listValues
          ]
        }
      }

    case LOAD_SYSTEMUSER_LIST_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_SYSTEMUSER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        lists: {
          ...state.lists,
          staff: [
            ...action.payload.map(x => ({
              userId: x.userId,
              userName: x.fullName
            }))
          ]
        }
      };

    case LOAD_SYSTEMUSER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case SAVE_SYSTEM_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      }

    case SAVE_CLIENT_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          clients: [
            ...state.lists.clients.filter(x => x.clientId !== action.payload.clientId),
            {
              clientId: action.payload.clientId,
              clientName: action.payload.clientName
            }
          ].sort((a, b) => {
            if (a.clientName > b.clientName) return 1;
            if (a.clientName < b.clientName) return -1;
            return 0;
          })
        }
      }
    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default systemReducer;
