import "@babel/polyfill";
import moment from "moment";
import { Logger } from "aws-amplify";
import {
  createShipment,
  persistShipment,
  fetchShipmentList,
  fetchShipment
} from "../api/shipmentApi";
import validateShipment from "../models/validateShipment";
import objectsAreEqual from "../tools/objectChecker";
import { loadRequestedObjects } from "./requestActions";

export const INITIALIZE_SHIPMENT_FILTER = "INITIALIZE_SHIPMENT_FILTER";
export const SHOW_SHIPMENT_EDIT_FORM = "SHOW_SHIPMENT_EDIT_FORM";
export const CANCEL_SHIPMENT_EDIT_FORM = "CANCEL_SHIPMENT_EDIT_FORM";

export const LOAD_SHIPMENT_REQUESTED = "LOAD_SHIPMENT_REQUESTED";
export const LOAD_SHIPMENT_SUCCESS = "LOAD_SHIPMENT_SUCCESS";
export const LOAD_SHIPMENT_FAILURE = "LOAD_SHIPMENT_FAILURE";

export const LOAD_SHIPMENT_LIST_REQUESTED = "LOAD_SHIPMENT_LIST_REQUESTED";
export const LOAD_SHIPMENT_LIST_SUCCESS = "LOAD_SHIPMENT_LIST_SUCCESS";
export const LOAD_SHIPMENT_LIST_FAILURE = "LOAD_SHIPMENT_LIST_FAILURE";

export const SAVE_SHIPMENT_REQUESTED = "SAVE_SHIPMENT_REQUESTED";
export const SAVE_SHIPMENT_SUCCESS = "SAVE_SHIPMENT_SUCCESS";
export const SAVE_SHIPMENT_FAILURE = "SAVE_SHIPMENT_FAILURE";

export const CHECK_SHIPMENT_IS_VALID = "CHECK_SHIPMENT_IS_VALID";
export const CHECK_SHIPMENT_IS_DIRTY = "CHECK_SHIPMENT_IS_DIRTY";
export const UPDATE_SHIPMENT_FIELD_VALUE = "UPDATE_SHIPMENT_FIELD_VALUE";

export const ADD_SHIPMENT_REQUESTED = "ADD_SHIPMENT_REQUESTED";
export const EDIT_SHIPMENT_REQUESTED = "EDIT_SHIPMENT_REQUESTED";

export const CHANGE_SHIPMENT_FILTER = "CHANGE_SHIPMENT_FILTER";

const logger = new Logger("shipmentActions");

const initializeShipmentFilterRequest = (fromDate, toDate) => ({
  type: INITIALIZE_SHIPMENT_FILTER,
  fromDate,
  toDate
});

const loadShipmentRequested = () => ({
  type: LOAD_SHIPMENT_REQUESTED
});

const loadShipmentSuccess = (shipment) => ({
  type: LOAD_SHIPMENT_SUCCESS,
  payload: shipment
});

const loadShipmentFailure = (err) => ({
  type: LOAD_SHIPMENT_FAILURE,
  payload: err
});

const saveShipmentRequested = () => ({
  type: SAVE_SHIPMENT_REQUESTED
});

const saveShipmentSuccess = shipment => ({
  type: SAVE_SHIPMENT_SUCCESS,
  payload: shipment
});

const saveShipmentFailure = error => ({
  type: SAVE_SHIPMENT_FAILURE,
  payload: { ...error }
});

export const changeShipmentFilterRequested = (id, value) => ({
  type: CHANGE_SHIPMENT_FILTER,
  id,
  value
});

export const cancelChanges = () => ({
  type: CANCEL_SHIPMENT_EDIT_FORM
});

export const addShipmentRequested = shipment => ({
  type: ADD_SHIPMENT_REQUESTED,
  payload: shipment
});

export const editShipmentRequested = shipment => ({
  type: EDIT_SHIPMENT_REQUESTED,
  payload: shipment
});

const showShipmentIsValid = valid => ({
  type: CHECK_SHIPMENT_IS_VALID,
  payload: valid
});

const updateShipment = (id, value) => ({
  type: UPDATE_SHIPMENT_FIELD_VALUE,
  id,
  value
});

const loadShipmentsRequested = () => ({
  type: LOAD_SHIPMENT_LIST_REQUESTED
});

const loadShipmentsSuccess = shipments => ({
  type: LOAD_SHIPMENT_LIST_SUCCESS,
  payload: shipments
});

const loadShipmentsFailure = error => ({
  type: LOAD_SHIPMENT_LIST_FAILURE,
  payload: error
});

export const updateShipmentFieldValue = (id, value) => async (
  dispatch,
  getState
) => {

  await dispatch(updateShipment(id, value));
  if (id === "dateShipped" && moment(value, "YYYY-MM-DD", true).isValid()) {
    dispatch(updateShipment("status", "Shipped"));
  }

  if (id === "dateReceived" && moment(value, "YYYY-MM-DD", true).isValid()) {
    dispatch(updateShipment("status", "Received"));
  }


  const { editShipment } = getState().shipment;
  logger.info("about to validate", editShipment);
  dispatch(showShipmentIsValid(validateShipment(editShipment)));
  dispatch(shipmentIsDirty());
};

const shipmentIsDirty = () => async (dispatch, getState) => {
  const { editShipment, originalShipment } = getState().shipment;

  const isDirty = !objectsAreEqual(editShipment, originalShipment);

  await dispatch({
    type: CHECK_SHIPMENT_IS_DIRTY,
    isDirty
  });
};

export const loadShipments = (fromDate, toDate) => async dispatch => {
  dispatch(loadShipmentsRequested());
  try {
    const data = await fetchShipmentList(fromDate, toDate);

    dispatch(loadShipmentsSuccess(data));
  }
  catch (err) {
    dispatch(loadShipmentsFailure(err));
  }
};

export const addShipment = () => async (dispatch, getState) => {
  const { selectedObjects } = getState().requests;
  const newShipment = createShipment(selectedObjects);

  dispatch(addShipmentRequested(newShipment));
};

export const editShipment = shipmentId => async (dispatch) => {
  dispatch(loadShipmentRequested());

  try{
    const shipment = await fetchShipment(shipmentId);
    await dispatch(loadShipmentSuccess(shipment));
    dispatch(editShipmentRequested(shipment));
  }
  catch (err) {
    dispatch(loadShipmentFailure(err));
  }

};

export const saveShipment = () => async (dispatch, getState) => {
  const { editShipment } = getState().shipment;

  dispatch(saveShipmentRequested());

  try {
    logger.info("About to save", editShipment);
    const success = await persistShipment(editShipment);

    if (success) {
      await dispatch(saveShipmentSuccess(editShipment));
      dispatch(loadShipmentList());
      dispatch(loadRequestedObjects());
    }
  }
  catch (err) {
    dispatch(saveShipmentFailure(err));
  }
};

export const initializeShipmentFilter = () => dispatch => {
  const fromDate = moment(new Date())
    .subtract(30, "days")
    .toISOString()
    .slice(0, 10);
  const toDate = moment(new Date())
    .toISOString()
    .slice(0, 10);

  dispatch(initializeShipmentFilterRequest(fromDate, toDate));
};

export const changeShipmentFilter = (id, value) => (dispatch) => {
  dispatch(changeShipmentFilterRequested(id, value));

  if (!(id === "fromDate" || id === "toDate")) {
    return;
  }

  dispatch(loadShipmentList());
};

const loadShipmentList = () => (dispatch, getState) => {
  const { fromDate, toDate } = getState().shipment.filter;

  // check if incomplete date field
  if (
    !moment(toDate, "YYYY-MM-DD").isValid() ||
    !moment(fromDate, "YYYY-MM-DD").isValid()
  ) {
    return;
  }

  dispatch(loadShipments(fromDate, toDate));
}
