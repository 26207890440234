import React from "react";
import { Button } from "reactstrap";
import { bool, string, func } from "prop-types";
import {
  lightButtonStyle,
  darkButtonStyle,
  requestLightButtonStyle,
  requestDarkButtonStyle
} from "../../styles/buttonStyles";


const ProcessingButton = ({
  id,
  value,
  processing,
  buttonLabel,
  processingLabel,
  handleClick,
  isDisabled,
  buttonStyle
}) => (
  <Button
    id={id}
    value={value}
    disabled={isDisabled || processing}
    style={
      buttonStyle === "light"
        ? lightButtonStyle
        : buttonStyle === "dark"
        ? darkButtonStyle
        : buttonStyle === "requestLight"
        ? requestLightButtonStyle
        : requestDarkButtonStyle
    }
    onClick={handleClick}
  >
    {processing ? (
      <span>
        <i className="fa fa-spin fa-spinner" style={{ marginRight: "0.3em" }} />
        {processingLabel}
      </span>
    ) : (
      <span>{buttonLabel}</span>
    )}
  </Button>
);

ProcessingButton.propTypes = {
  id: string,
  value: string,
  processing: bool,
  isDisabled: bool,
  buttonStyle: string,
  buttonLabel: string.isRequired,
  processingLabel: string.isRequired,
  handleClick: func.isRequired
};

ProcessingButton.defaultProps = {
  id: "",
  value: "",
  buttonStyle: "light",
  processing: false,
  isDisabled: false
};

export default ProcessingButton;
