import React from "react";
import { string } from "prop-types";

const noClientSelectedPageStyle = {
  height: '100%',
  textAlign: 'center',
}

const welcomeSubtitleStyle = {
  fontFamily: 'proxima-nova',
  fontSize: '16px',
  fontWeight: '600',
  color: '#707070',
  textTransform: 'uppercase',
}

const noClientPanelStyle = {
  marginTop: '20%',
}

const NoClientSelectedPage = ({ firstName }) => (
  <div className="no-client-page" style={noClientSelectedPageStyle}>
    <div className="no-client-panel" style={noClientPanelStyle}>
      <h2 className="home-title">
        {`Welcome, ${firstName}.`}
      </h2>
      <div className="home-subtitle" style={welcomeSubtitleStyle}>Please select a client to continue.</div>
    </div>
  </div>
);

NoClientSelectedPage.propTypes = {
  firstName: string.isRequired,
}

export default NoClientSelectedPage;
