import "@babel/polyfill";
import { Auth } from "aws-amplify";
import { API_HOST_NAME } from "./constants";


export const delay = async ms => new Promise(resolve => setTimeout(resolve, ms));

const getRequestPath = (path) => {
  return API_HOST_NAME + path;
}

export const getJwt = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.idToken.jwtToken;
};

export const doFetch = async (path, params) => {
  const token = await getJwt();
  params.headers.Authorization = token;

  const requestPath = getRequestPath(path);

  const response = await fetch(requestPath, params);

  if (response.status === 200){
    return await response.json();
  }
  else {
    return null;
  }

};



