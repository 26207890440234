import { doFetch } from "./index";
import { user as newUser } from "../models/user";
import { NULL_UUID } from "./constants"

export const createUser = (client) => {
  return {
    ...newUser,
    clientId: client.clientId,
    clientName: client.clientName,
    role: "User",
    tier: "Standard",
    status: "Active",
  };
};

export const createSystemUser = () => {
  return {
    ...newUser,
    clientId: NULL_UUID,
    clientName: "Digital Archivist",
    role: "Digital Archivist User",
    tier: "Standard",
  };
};

export const fetchUser = async (userId, clientId) => {
  const path = `/users/${userId}?clientId=${clientId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);
  return response;

}

export const fetchSystemUsers = async () => {
  const path = "/system-users";

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response.sort((a, b) => {
    if (a.fullName > b.fullName) return 1;
    if (a.fullName < b.fullName) return -1;
    return 0;
  });
};

export const persistUser = async user => {
  if (!user) {
    return false;
  }

  let method = "POST";
  const path = `/users`;

  const params = {
    method: method,
    headers: {},
    body: JSON.stringify(user)
  };

  const response = await doFetch(path, params);

  return response;
};

export const disableCognitoUser = async user => {
  if (!user) {
    return false;
  }

  let method = "POST";
  const path = `/users/${user.userId}/disable?clientId=${user.clientId}`;

  const params = {
    method: method,
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
}


export const enableCognitoUser = async user => {
  if (!user) {
    return false;
  }

  let method = "POST";
  const path = `/users/${user.userId}/enable?clientId=${user.clientId}`;

  const params = {
    method: method,
    headers: {}
  };


  const response = await doFetch(path, params);

  return response;
}
