import { Storage } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, bool, arrayOf, shape } from "prop-types";
import { Table, Input, Label, Col, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  changeImageManagementFilter,
  loadObjectsWithoutImages
} from "../../actions/imageManagementActions";
import NoClientSelectedPage from "../common/NoClientSelectedPage";
import { NULL_UUID, imageCustomPrefix } from "../../api/constants";
import {
  filterPageHeaderStyle,
  filterHeaderStyle,
  filterLabelStyle,
  dateInputFilterStyle,
  dateSplitterStyle,
  tableHeaderStyle
} from "../../styles/formStyles";
import { lightButtonStyle } from "../../styles/buttonStyles";
import ArchivistDropZone from "./ArchivistDropZone";
import inventoryIdFormat from "../../tools/objectFormatters";

const imageMgmtRowStyle = {
  verticalAlign: "middle",
  fontSize: "13px"
};

const refreshButtonStyle = {
  ...lightButtonStyle,
  marginTop: "20px",
  marginLeft: "50px"
}

class ImageManagementPage extends Component {
  componentDidMount() {
    this.loadPage();
  }

  loadPage = () => {
    const { loadObjectsWithoutImages } = this.props;

    loadObjectsWithoutImages();
  };

  handleFilterChange = e => {
    e.preventDefault();

    const { changeImageManagementFilter } = this.props;
    changeImageManagementFilter(e.target.id, e.target.value);
  };

  handleDateFilterChange = (id, date) => {
    const { changeImageManagementFilter } = this.props;
    changeImageManagementFilter(id, date.toISOString().slice(0, 10));
  };

  handleFileUpload = (file, objectId, clientId) => {
    const { notifySearchImageUpdated } = this.props;

    const fileKey = `${clientId}/${objectId}-0.jpg`;

    Storage.put(fileKey, file, {
      customPrefix: imageCustomPrefix
    }).then(() => notifySearchImageUpdated(objectId));
    // .catch(err => console.log("ERROR!!", err))
  };

  render = () => {
    const {
      userRole,
      firstName,
      tenantId,
      filter,
      objects,
      staffList,
      reloadList
    } = this.props;

    if (
      !(
        userRole === "System Administrator" ||
        userRole === "Digital Archivist Administrator" ||
        userRole === "Digital Archivist User"
      )
    ) {
      return (
        <div>
          <h2>Unauthorized</h2>
          Please contact the system administrator.
        </div>
      );
    }

    if (reloadList) {
      this.loadPage();
    }

    if (tenantId === NULL_UUID) {
      return <NoClientSelectedPage firstName={firstName} />;
    }
    else {
      return (
        <div className="obj-no-images">
          <div className="obj-no-images-header" style={filterPageHeaderStyle}>
            <h2>Image Management</h2>
          </div>

          <div className="obj-no-images-filter" style={filterHeaderStyle}>
            <Row>
              <Col sm={3}>
                <Label className="small-filter-label" style={filterLabelStyle}>
                  Filter by Date Entered
                </Label>
                <DatePicker
                  id="fromDate"
                  className="image-management-date-filter"
                  selected={moment(filter.fromDate).toDate()}
                  onChange={date =>
                    this.handleDateFilterChange("fromDate", date)
                  }
                  style={dateInputFilterStyle}
                />
                <div className="dash" style={dateSplitterStyle}>
                  -
                </div>

                <DatePicker
                  id="toDate"
                  className="image-management-date-filter"
                  selected={moment(filter.toDate).toDate()}
                  onChange={date => this.handleDateFilterChange("toDate", date)}
                  style={dateInputFilterStyle}
                />
              </Col>
              <Col sm={2} className="px-1">
                <Label className="small-filter-label" style={filterLabelStyle}>
                  Division
                </Label>
                <Input
                  type="text"
                  id="division"
                  defaultValue={filter.division}
                  onBlur={this.handleFilterChange}
                />
              </Col>
              <Col sm={1} className="px-1">
                <Label className="small-filter-label" style={filterLabelStyle}>
                  Year
                </Label>
                <Input
                  type="text"
                  id="year"
                  defaultValue={filter.year}
                  onBlur={this.handleFilterChange}
                />
              </Col>
              <Col sm={2} className="px-1">
                <Label className="small-filter-label" style={filterLabelStyle}>
                  Season
                </Label>
                <Input
                  type="select"
                  id="season"
                  value={filter.season}
                  onChange={this.handleFilterChange}
                >
                  <option key="" value="" />
                  <option key="Autumn-Winter" value="Autumn-Winter">
                    Autumn-Winter
                  </option>
                  <option key="N/A" value="N/A">
                    N/A
                  </option>
                  <option key="Pre-Fall" value="Pre-Fall">
                    Pre-Fall
                  </option>
                  <option key="Pre-Spring" value="Pre-Spring">
                    Pre-Spring
                  </option>
                  <option key="Spring-Summer" value="Spring-Summer">
                    Spring-Summer
                  </option>
                </Input>
              </Col>
              <Col sm={2} className="px-1">
                <Label className="small-filter-label" style={filterLabelStyle}>
                  Filter by Staff
                </Label>
                <Input
                  type="select"
                  id="staff"
                  value={filter.staff}
                  onChange={this.handleFilterChange}
                >
                  <option key="-999" value="">
                    ALL USERS
                  </option>
                  {staffList.map(staff => (
                    <option key={staff.userId} value={staff.userId}>
                      {staff.userName}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col sm={1} className="px-1">
                <Button
                  id="refresh"
                  style={refreshButtonStyle}
                  onClick={this.loadPage}
                >
                  Refresh
                </Button>
              </Col>
            </Row>
          </div>

          <div className="obj-no-images-table">
            <Table hover responsive>
              <thead style={tableHeaderStyle}>
                <tr>
                  <th>Image</th>
                  <th>Inventory Id</th>
                  <th>Designer</th>
                  <th>Division</th>
                  <th>Year</th>
                  <th>Season</th>
                  <th>Look #</th>
                  <th>Object Type</th>
                  <th>Date Added</th>
                  <th>By</th>
                </tr>
              </thead>
              <tbody>
                {objects
                  .filter(
                    item =>
                      // (item.clientId === filter.clientId ||
                      //   filter.clientId === NULL_UUID) &&
                      item.createdBySub === filter.staff || filter.staff === ""
                  )
                  .map(item => (
                    <tr key={item.objectId}>
                      <td style={imageMgmtRowStyle}>
                        <ArchivistDropZone
                          multiple={false}
                          uploadFile={file =>
                            this.handleFileUpload(
                              file,
                              item.objectId,
                              item.clientId
                            )
                          }
                        />
                      </td>
                      <td style={imageMgmtRowStyle}>
                        <Link to={`/object/${item.objectId}`}>
                          {inventoryIdFormat(item.inventoryId)}
                        </Link>
                      </td>
                      <td style={imageMgmtRowStyle}>{item.designer}</td>
                      <td style={imageMgmtRowStyle}>{item.division}</td>
                      <td style={imageMgmtRowStyle}>{item.year}</td>
                      <td style={imageMgmtRowStyle}>{item.season}</td>
                      <td style={imageMgmtRowStyle}>{item.lookNumber}</td>
                      <td style={imageMgmtRowStyle}>{item.objectType}</td>
                      <td style={imageMgmtRowStyle}>
                        {moment(item.dateCreated)
                          .utc()
                          .local()
                          .format("L LT")}
                      </td>
                      <td style={imageMgmtRowStyle}>{item.createdBy}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      );
    }
  };
}

ImageManagementPage.propTypes = {
  userRole: string.isRequired,
  firstName: string.isRequired,
  tenantId: string.isRequired,
  filter: shape({
    clientId: string,
    fromDate: string,
    toDate: string,
    year: string,
    season: string,
    staff: string
  }).isRequired,
  reloadList: bool.isRequired,
  objects: arrayOf(
    shape({
      objectId: string,
      inventoryId: string,
      designer: string,
      objectType: string,
      dateAdded: string,
      enteredBy: string
    })
  ).isRequired,
  staffList: arrayOf(
    shape({
      userId: string,
      userName: string
    })
  ).isRequired,
  changeImageManagementFilter: func.isRequired,
  loadObjectsWithoutImages: func.isRequired,
  notifySearchImageUpdated: func.isRequired
};

const mapStateToProps = state => {
  return {
    userRole: state.user.profile.role,
    firstName: state.user.profile.first_name,
    reloadList: state.imageManagement.reloadList,
    tenantId: state.system.tenantId,
    filter: state.imageManagement.filter,
    staffList: state.system.lists["staff"],
    objects: state.imageManagement.objects
  };
};

export default connect(
  mapStateToProps,
  {
    changeImageManagementFilter,
    loadObjectsWithoutImages
  }
)(ImageManagementPage);
