import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, arrayOf, shape, bool } from "prop-types";
import { Table, Input, Label, Col, Row } from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  loadShipments,
  editShipment,
  changeShipmentFilter
} from "../../actions/shipmentActions";
import { NULL_UUID } from "../../api/constants";
import EditShipmentPage from "./EditShipmentPage";
import { shipmentShape } from "../../models/shipment";
import editIcon from "../../assets/images/edit-icon.png";
import {
  tableButtonStyle,
  filterPageHeaderStyle,
  filterHeaderStyle,
  dateInputFilterStyle,
  dateSplitterStyle,
  filterLabelStyle
} from "../../styles/formStyles";

class ShipmentsPage extends Component {
  componentDidMount = () => {
    const { filter, loadShipments } = this.props;
    loadShipments(filter.fromDate, filter.toDate);
  };

  handleDateFilterChange = (id, date) => {
    const { changeShipmentFilter } = this.props;
    changeShipmentFilter(id, date.toISOString().slice(0, 10));
  };

  handleFilterChange = (e) => {
    e.preventDefault();
    const { changeShipmentFilter } = this.props;
    changeShipmentFilter(e.target.id, e.target.value);
  };

  handleEditShipment = shipmentId => {
    const { editShipment } = this.props;
    editShipment(shipmentId);
  };

  render() {
    const {
      userRole,
      filter,
      shipments,
      staffList,
      clientList,
      shipmentStatusList,
      loading
    } = this.props;

    if (
      !(
        userRole === "System Administrator" ||
        userRole === "Digital Archivist Administrator" ||
        userRole === "Digital Archivist User"
      )
    ) {
      return (
        <div>
          <h2>Unauthorized</h2>
          Please contact the system administrator.
        </div>
      );
    }

    return (
      <div className="shipments">
        <div className="shipments-header" style={filterPageHeaderStyle}>
          <h2>Shipments</h2>
        </div>

        <div className="shipments-filter" style={filterHeaderStyle}>
          <Row>
            <Col sm={4}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Request Date
              </Label>
              <DatePicker
                id="fromDate"
                selected={moment(filter.fromDate).toDate()}
                onChange={date => this.handleDateFilterChange("fromDate", date)}
                style={dateInputFilterStyle}
              />
              <div className="dash" style={dateSplitterStyle}>
                -
              </div>

              <DatePicker
                id="toDate"
                selected={moment(filter.toDate).toDate()}
                onChange={date => this.handleDateFilterChange("toDate", date)}
                style={dateInputFilterStyle}
              />
            </Col>
            <Col sm={3}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Staff
              </Label>
              <Input
                type="select"
                id="staff"
                value={filter.staff}
                onChange={this.handleFilterChange}
              >
                <option key="-999" value="">
                  ALL USERS
                </option>
                {staffList.map(staff => (
                  <option key={staff.userId} value={staff.userId}>
                    {staff.userName}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm={3}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Client
              </Label>
              <Input
                type="select"
                id="clientId"
                value={filter.clientId}
                onChange={this.handleFilterChange}
              >
                <option key="-999" value={NULL_UUID}>
                  ALL CLIENTS
                </option>
                {clientList.map(x => (
                  <option key={x.clientId} value={x.clientId}>
                    {x.clientName}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm={2}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Status
              </Label>
              <Input
                type="select"
                id="status"
                value={filter.status}
                onChange={this.handleFilterChange}
              >
                <option key="-999" value="">
                  ALL STATUSES
                </option>
                {shipmentStatusList.map(status => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </div>

        <div className="shipments-table">
          <Table hover responsive>
            <thead>
              <tr>
                <th>Client</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Entered By</th>
                <th>Date Shipped</th>
                <th>Status</th>
                <th>{null}</th>
              </tr>
            </thead>
            <tbody>
              {shipments
                .filter(
                  item =>
                    (item.clientId === filter.clientId ||
                      filter.clientId === NULL_UUID) &&
                    (item.createdBySub === filter.staff || filter.staff === "") &&
                    (item.status === filter.status || filter.status === "")
                )
                .sort((a, b) => {
                  if (!a.dateShipped) return -1;
                  if (!b.dateShipped) return 1;
                  if (a.dateShipped > b.dateShipped) return 1;
                  if (a.dateShipped < b.dateShipped) return -1;
                  return 0;
                })
                .map(item => (
                  <tr key={item.shipmentId}>
                    <td>{item.clientName}</td>
                    <td>{item.description}</td>
                    <td>{item.objectCount || null}</td>
                    <td>{item.createdBy}</td>
                    <td>{item.dateShipped}</td>
                    <td>{item.status}</td>
                    <td>
                      <div
                        className="editButton"
                        role="button"
                        aria-hidden
                        style={tableButtonStyle}
                        onClick={e => {
                          e.preventDefault();
                          this.handleEditShipment(item.shipmentId);
                        }}
                      >
                        <img src={editIcon} alt="Edit Shipment" />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="Loading">
          {loading === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Loading...
            </span>
          ) : null}
        </div>
        <EditShipmentPage />
      </div>
    );
  }
}

ShipmentsPage.propTypes = {
  userRole: string.isRequired,
  clientList: arrayOf(
    shape({
      tenantId: string,
      client: string
    })
  ).isRequired,
  filter: shape({
    fromDate: string,
    toDate: string,
    staff: string,
    clientId: string
  }).isRequired,
  loading: bool.isRequired,
  shipmentStatusList: arrayOf(string).isRequired,
  staffList: arrayOf(
    shape({
      userId: string,
      userName: string,
    })
  ).isRequired,
  shipments: arrayOf(shipmentShape).isRequired,
  changeShipmentFilter: func.isRequired,
  loadShipments: func.isRequired,
  editShipment: func.isRequired
};

const mapStateToProps = state => {
  return {
    userRole: state.user.profile.role,
    filter: state.shipment.filter,
    shipments: state.shipment.shipments,
    clientList: state.system.lists["clients"],
    shipmentStatusList: state.system.lists["shipmentStatuses"],
    staffList: state.system.lists["staff"],
    loading: state.shipment.loading
  };
};

export default connect(
  mapStateToProps,
  {
    loadShipments,
    editShipment,
    changeShipmentFilter
  }
)(ShipmentsPage);
