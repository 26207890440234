import { string, shape } from "prop-types";

export const location = {
  locationId: "",
  locationName: "",
};

export const locationShape = shape({
  locationId: string,
  locationName: string,
});
