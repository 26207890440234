/* eslint-disable no-undef */
import path from "path";
import { Storage } from "aws-amplify";
import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label
} from "reactstrap";
import { string, bool, func, arrayOf, shape } from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FormTextInput from "../common/FormTextInput";
import FormSelectInput from "./components/FormSelectInput";
import FormCheckBox from "./components/FormCheckBox";

import ViewRelatedItemImage from "./ViewRelatedItemImage";
import {
  cancelEdit,
  updateObjectFieldValue,
  addObjectMultiSelectValue,
  createObjectMultiSelectValue,
  createObjectSelectValue,
  removeObjectMultiSelectValue,
  clearObjectMultiSelectValue,
  addRelatedObject,
  addRelatedObjectByObjectId,
  removeRelatedObject,
  saveObject,
  updateFormState,
  lookupLocationFromId
} from "../../actions/objectActions";

import archiveObjectPropType from "../../models/archiveObjectPropType";
import ObjectImageDropZone from "./ObjectImageDropZone";
import { imageCustomPrefix, ImageNumber } from "../../api/constants";
import {
  makeOptionListFromStringArray,
  makeOptionValueFromString
} from "../../tools/dropdownListUtils";

import {
  editFormHeaderStyle,
  editFormHeaderCheckboxStyle,
  editFormHeaderActionButtonStyle,
  editFormHeaderCancelButtonStyle,
  editFormHeaderNoteStyle,
  editFormRequiredFieldIndicatorStyle
} from "../../styles/formStyles";
import { clientShape } from "../../models/client";
import inventoryIdFormat from "../../tools/objectFormatters";

const imageStyle = {
  width: "150px",
  height: "225px",
  marginBottom: "10px"
};

let labelPrinter;

class EditObjectPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image1: null,
      image2: null,
      newImage1: null,
      newImageKey1: null,
      newImage2: null,
      newImageKey2: null
    };
  }

  handleAfterOpen = async () => {
    const { image1, image2 } = this.props;

    this.setState({
      image1,
      image2
    });
  };

  handlePrintBarcode = object => {
    const printScript = document.getElementById("barcodeScriptId");
    if (object.inventoryId === null) {
      alert("Object Inventory Id is not properly set.  Please refresh the page and try again.");
      return;
    }

    if (!printScript) {
      const script = document.createElement("script");
      //script.src = "/BrowserPrint-2.0.0.75.min.js";
      script.src = "https://s3.amazonaws.com/digitalarchivist.com/BrowserPrint-2.0.0.75.min.js";
      script.id = "barcodeScriptId";
      document.body.appendChild(script);

      script.onload = () => {
        this.printBarcode(object);
      };
    }

    if (printScript) {
      this.printBarcode(object);
    }
  };

  printBarcode = object => {
    const { clients, clientId } = this.props;
    const client = clients.find(x => x.clientId === clientId)
    let clientType = "";
    if (client) {
      clientType = client.clientType;
    }

    let objectDescription = "";
    if (clientType === "Corporate") {
      objectDescription = `${object.season || ""} - ${object.lookNumber || ""} - ${object.year || ""}`;
    }
    else {
      objectDescription = object.designer || "";
    }

    const dataToWrite = `
      ^XA
      ^FO50,20^ADN,12,12^FH^FD${objectDescription}^FS
      ^FO50,50^BY2
      ^BCN,100,Y,N,N
      ^FD>:${inventoryIdFormat(object.inventoryId)}^FS
      ^XZ
      `;

    this.printLabel(dataToWrite);
  };

  printLabel = dataToWrite => {
    BrowserPrint.getDefaultDevice(
      "printer",
      function(device) {
        //Add device to list of devices and to html select element
        labelPrinter = device;
        labelPrinter.send(dataToWrite, undefined, function(error) {
          alert(error);
        });
      },
      function(error) {
        alert(error);
      }
    );
  };

  sleep = time => {
    return new Promise(resolve => setTimeout(resolve, time));
  };

  handleSave = event => {
    event.preventDefault();

    const { editObject, saveObject, printBarcode } = this.props;
    const { newImage1, newImageKey1, newImage2, newImageKey2 } = this.state;

    if (printBarcode) {
      this.handlePrintBarcode(editObject);
    }

    if (newImageKey1 !== null) {
      Storage.put(newImageKey1, newImage1, {
        customPrefix: imageCustomPrefix
      });
    }

    if (newImageKey2 !== null) {
      Storage.put(newImageKey2, newImage2, {
        customPrefix: imageCustomPrefix
      });
    }

    if (newImageKey1 !== null || newImage2 !== null) {
      this.sleep(1500)
        .then(() => {
          saveObject(editObject);
        });
    }
    else {
      saveObject(editObject);
    }

    toast.success("Object saved", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  };

  handleRelatedInputBlur = e => {
    const { addRelatedObjectByObjectId } = this.props;
    addRelatedObjectByObjectId(e.target.value);
  };

  handleRemoveRelatedObject = objectId => {
    const { removeRelatedObject } = this.props;
    removeRelatedObject(objectId);
  };

  handleAddRelatedObject = e => {
    e.preventDefault();

    const { editObject, addRelatedObject } = this.props;

    this.setState({
      image1: null,
      image2: null
    });

    addRelatedObject(editObject);
  };

  handleEnterRelatedObject = e => {
    if (e.key !== "Enter") {
      return;
    }

    const { addRelatedObjectByObjectId } = this.props;
    addRelatedObjectByObjectId(e.target.value);
  };

  handleFileDropped = (file, imageNumber) => {
    const { editObject } = this.props;
    const {
      clientId,
      objectId
     } = editObject;

    const extention = path.extname(file.name).toLowerCase();
    const fileKey = `${clientId}/${objectId}-${imageNumber}${extention}`;
    let mediaKey;

    const { updateObjectFieldValue } = this.props;
    if (imageNumber === ImageNumber.OriginalPrimaryImage) {
      this.setState({
        newImageKey1: fileKey,
        newImage1: file
      });

      mediaKey = `media/${clientId}/${objectId}-${
        ImageNumber.ViewPrimaryImage
      }${extention}`;
      updateObjectFieldValue("primaryImage", mediaKey);

    }
    else if (imageNumber === ImageNumber.OriginalSecondaryImage) {
      this.setState({
        newImageKey2: fileKey,
        newImage2: file
      });

      mediaKey = `media/${clientId}/${objectId}-${
        ImageNumber.ViewSecondaryImage
      }${extention}`;
      updateObjectFieldValue("secondaryImage", mediaKey);
    }
  };

  handleLocationSelectChange = (newValue, actionMeta, id, listName) => {
    if (actionMeta.action === "create-option") {
      const lookupInventoryId = newValue.label.replace(/\D/g,'');
      const validId = /^[0-9]{9}/i.test(lookupInventoryId);

      if (validId) {
        const { lookupLocationFromId } = this.props;
        lookupLocationFromId(lookupInventoryId);
        return;
      }

    }

    this.handleSelectChange(newValue, actionMeta, id, listName);

  }

  handleSelectChange = (newValue, actionMeta, id, listName) => {
    let value;

    if (actionMeta.action === "create-option") {
      const { createObjectSelectValue } = this.props;
      createObjectSelectValue(id, newValue.value, listName);
      return;
    }

    if (actionMeta.action === "clear") {
      value = null;
    }
    else if (actionMeta.action === "select-option") {
      value = newValue.value;
    }
    else {
      return;
    }

    const { updateObjectFieldValue } = this.props;
    updateObjectFieldValue(actionMeta.name, value);
  };

  handleMultiSelectChange = (newValue, actionMeta, id, listName) => {
    if (actionMeta.action === "create-option") {
      const { createObjectMultiSelectValue } = this.props;
      createObjectMultiSelectValue(id, newValue.map(x => x.label), listName);
    }
    else if (actionMeta.action === "remove-value") {
      const { removeObjectMultiSelectValue } = this.props;
      removeObjectMultiSelectValue(actionMeta.name, newValue.map(x => x.label));
    }
    else if (actionMeta.action === "select-option") {
      const { addObjectMultiSelectValue } = this.props;
      addObjectMultiSelectValue(actionMeta.name, newValue.map(x => x.label));
    }
    else if (actionMeta.action === "clear") {
      const { clearObjectMultiSelectValue } = this.props;
      clearObjectMultiSelectValue(actionMeta.name);
    }
  };

  handleChange = e => {
    e.preventDefault();

    let value;
    if (e.target.type == "checkbox") {
      value = !!(e.target.type === "checked");
    }
    else {
      value = e.target.value;
    }

    const { updateObjectFieldValue } = this.props;

    updateObjectFieldValue(e.target.id, value);
  };

  handleFormStateChange = event => {
    const { updateFormState } = this.props;
    updateFormState(event.target.id, !!event.target.value);
  };

  handleAddRelatedObjectChange = event => {
    const { updateFormState } = this.props;

    updateFormState(event.target.id, event.target.value);
  };

  onCancelClick = e => {
    e.preventDefault();
    const { cancelEdit } = this.props;

    cancelEdit();
  };

  render() {
    const {
      action,
      isOpen,
      lists,
      editObject,
      isValid,
      isDirty,
      addAnother,
      addInventoryId,
      printBarcode
    } = this.props;

    const { image1, image2 } = this.state;

    if (editObject.objectId === "") {
      return null;
    }

    return (
      <ReactModal
        isOpen={isOpen}
        ariaHideApp={false}
        onAfterOpen={this.handleAfterOpen}
      >
        <div className="edit-object">
          <Form>
            <ToastContainer
              position="top-center"
              autoClose={1000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover={false}
            />
            <Container
              fluid
              className="form-header"
              style={editFormHeaderStyle}
            >
              <Row>
                <Col xs="3" style={editFormHeaderCheckboxStyle}>
                  {action === "ADD" ? (
                    <FormCheckBox
                      id="addAnother"
                      label="Add Another Object"
                      checked={addAnother}
                      handleChange={this.handleFormStateChange}
                    />
                  ) : null}
                  <FormCheckBox
                    id="printBarcode"
                    label="Print Barcode"
                    checked={printBarcode}
                    handleChange={this.handleFormStateChange}
                  />
                </Col>
                <Col xs="3">
                  <Button
                    onClick={this.onCancelClick}
                    style={editFormHeaderCancelButtonStyle}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={!isValid}
                    style={editFormHeaderActionButtonStyle}
                    onClick={this.handleAddRelatedObject}
                  >
                    Add Related Object
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={this.handleSave}
                    style={editFormHeaderActionButtonStyle}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Container>

            <div className="form-header-title">
              <h2>{action === "EDIT" ? "Edit Object" : "Add New Object"}</h2>
              <div className="title-note" style={editFormHeaderNoteStyle}>
                <span
                  className="required-field-indicator"
                  style={editFormRequiredFieldIndicatorStyle}
                >
                  *
                </span>
                Indicates required field
              </div>
            </div>

            <Container fluid>
              <FormGroup row>
                <Col sm={3}>Inventory Id</Col>
                <Col sm={4}>
                  <Label>{inventoryIdFormat(editObject.inventoryId)}</Label>
                </Col>
                <Col
                  sm={5}
                  style={{ ...editFormHeaderCheckboxStyle, textAlign: "right" }}
                >
                  <FormCheckBox
                    id="flagForMissingInfo"
                    label="Flag for Missing Info"
                    checked={editObject.flagForMissingInfo === "Flagged"}
                    handleChange={this.handleChange}
                  />
                </Col>
              </FormGroup>

              {/* <FormTextInput
                label="Location"
                id="location"
                value={editObject.location}
                handleChange={this.handleChange}
              /> */}

              <FormSelectInput
                label="Location"
                id="location"
                isSearchable
                isClearable
                isCreatable
                placeholder="Enter Location"
                options={makeOptionListFromStringArray(lists["locations"])}
                value={makeOptionValueFromString(editObject.location)}
                handleChange={(newValue, actionMeta, id) =>
                  this.handleLocationSelectChange(newValue, actionMeta, id, "locations")
                }
              />

              <FormSelectInput
                label="Object Type"
                id="objectType"
                required
                isSearchable
                isClearable
                placeholder="Select Object Type"
                options={makeOptionListFromStringArray(lists["objectTypes"])}
                value={makeOptionValueFromString(editObject.objectType)}
                handleChange={(newValue, actionMeta) =>
                  this.handleSelectChange(newValue, actionMeta)
                }
              />

              <FormSelectInput
                label="Designer"
                id="designer"
                required
                placeholder="Select Designer"
                isSearchable
                isClearable
                isCreatable
                options={makeOptionListFromStringArray(lists["designers"])}
                value={makeOptionValueFromString(editObject.designer)}
                handleChange={(newValue, actionMeta, id) =>
                  this.handleSelectChange(newValue, actionMeta, id, "designers")
                }
              />

              <FormTextInput
                label="Secondary Line"
                id="secondaryLine"
                value={editObject.secondaryLine}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Divsion"
                id="division"
                value={editObject.division}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Year"
                id="year"
                value={editObject.year}
                handleChange={this.handleChange}
                autoComplete="XYZ"
              />

              <FormTextInput
                label="Season"
                id="season"
                value={editObject.season}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Look Number"
                id="lookNumber"
                value={editObject.lookNumber}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Internal Reference Number"
                id="internalReferenceNumber"
                value={editObject.internalReferenceNumber}
                handleChange={this.handleChange}
              />

              <FormSelectInput
                label="Primary Color"
                id="primaryColor"
                isSearchable
                isClearable
                placeholder="Select Primary Color"
                options={makeOptionListFromStringArray(lists["colors"])}
                value={makeOptionValueFromString(editObject.primaryColor)}
                handleChange={(newValue, actionMeta) =>
                  this.handleSelectChange(newValue, actionMeta)
                }
              />

              <FormSelectInput
                label="Secondary Colors"
                id="secondaryColors"
                isSearchable
                isClearable
                isMulti
                placeholder="Select Secondary Colors"
                options={makeOptionListFromStringArray(lists["colors"])}
                multivalue={makeOptionListFromStringArray(
                  editObject.secondaryColors
                )}
                handleChange={(newValue, actionMeta) =>
                  this.handleMultiSelectChange(newValue, actionMeta)
                }
              />

              <FormTextInput
                label="Material"
                id="material"
                value={editObject.material}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Description"
                id="description"
                type="textarea"
                value={editObject.description}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Event"
                id="event"
                value={editObject.event}
                handleChange={this.handleChange}
              />

              {/* TO-DO:  Add date picker */}
              <FormTextInput
                label="Event Date"
                id="eventDate"
                value={editObject.eventDate}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Stylist/Editor"
                id="stylistEditor"
                value={editObject.stylistEditor}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Archive Notes"
                id="archiveNotes"
                type="textarea"
                value={editObject.archiveNotes}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Conservation Notes"
                id="conservationNotes"
                type="textarea"
                value={editObject.conservationNotes}
                handleChange={this.handleChange}
              />

              <FormTextInput
                label="Size"
                id="size"
                value={editObject.size}
                handleChange={this.handleChange}
              />

              <FormGroup row>
                <Label sm={3}>Related Items</Label>
                <Col sm={9}>
                  <Input
                    id="addInventoryId"
                    type="text"
                    value={addInventoryId || ""}
                    placeholder="Enter Inventory Id"
                    onChange={this.handleAddRelatedObjectChange}
                    onKeyDown={e => this.handleEnterRelatedObject(e)}
                    onBlur={this.handleRelatedInputBlur}
                  />
                  <div style={{ display: "inline-flex" }}>
                    {editObject.relatedObjects.map(obj => (
                      <div
                        key={obj.objectId}
                        style={{ display: "inline-block" }}
                      >
                        <ViewRelatedItemImage
                          key={obj.objectId}
                          clientId={editObject.clientId}
                          objectId={obj.objectId}
                          inventoryId={obj.inventoryId}
                          imageNumber={ImageNumber.SearchImage}
                          showXButton
                          handleRemove={e => {
                            e.preventDefault();
                            this.handleRemoveRelatedObject(obj.objectId);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </Col>
              </FormGroup>

              <FormSelectInput
                label="Tags"
                id="tags"
                isSearchable
                isClearable
                isMulti
                isCreatable
                placeholder="Select Tags"
                options={makeOptionListFromStringArray(lists["tags"])}
                multivalue={makeOptionListFromStringArray(editObject.tags)}
                handleChange={(newValue, actionMeta, id) =>
                  this.handleMultiSelectChange(newValue, actionMeta, id, "tags")
                }
              />

              <FormGroup row>
                <Label sm={3}>Upload Images</Label>
                <Col sm={4}>
                  {image1 !== null ? (
                    <img
                      src={image1}
                      style={imageStyle}
                      className="search-item-image"
                      alt="object"
                    />
                  ) : null}
                  <ObjectImageDropZone
                    multiple={false}
                    header="Primary Image"
                    uploadFile={file =>
                      this.handleFileDropped(
                        file,
                        ImageNumber.OriginalPrimaryImage
                      )
                    }
                  />
                </Col>
                <Col sm={4}>
                  {image2 !== null ? (
                    <img
                      src={image2}
                      style={imageStyle}
                      className="search-item-image"
                      alt="object"
                    />
                  ) : null}
                  <ObjectImageDropZone
                    multiple={false}
                    header="Secondary Image"
                    uploadFile={file =>
                      this.handleFileDropped(
                        file,
                        ImageNumber.OriginalSecondaryImage
                      )
                    }
                  />
                </Col>
              </FormGroup>
            </Container>
          </Form>
        </div>
      </ReactModal>
    );
  }
}

EditObjectPage.propTypes = {
  action: string,
  image1: string,
  image2: string,
  addAnother: bool.isRequired,
  addInventoryId: string.isRequired,
  printBarcode: bool.isRequired,
  isOpen: bool.isRequired,
  isValid: bool.isRequired,
  isDirty: bool.isRequired,
  lists: shape({
    colors: arrayOf(string),
    designers: arrayOf(string),
    objectTypes: arrayOf(string),
    locations: arrayOf(string),
    tags: arrayOf(string)
  }).isRequired,
  editObject: shape(archiveObjectPropType).isRequired,
  saveObject: func.isRequired,
  addRelatedObject: func.isRequired,
  addRelatedObjectByObjectId: func.isRequired,
  removeRelatedObject: func.isRequired,
  cancelEdit: func.isRequired,
  updateObjectFieldValue: func.isRequired,
  addObjectMultiSelectValue: func.isRequired,
  createObjectMultiSelectValue: func.isRequired,
  createObjectSelectValue: func.isRequired,
  removeObjectMultiSelectValue: func.isRequired,
  clearObjectMultiSelectValue: func.isRequired,
  lookupLocationFromId: func.isRequired,
  updateFormState: func.isRequired,
  clientId: string.isRequired,
  clients: arrayOf(clientShape).isRequired,
};

EditObjectPage.defaultProps = {
  action: "",
  image1: null,
  image2: null
};

const mapStateToProps = state => {
  return {
    action: state.object.action,
    addAnother: state.object.addAnother,
    addInventoryId: state.object.addInventoryId,
    printBarcode: state.object.printBarcode,
    isValid: state.object.isValid,
    isDirty: state.object.isDirty,
    editObject: state.object.editObject,
    isOpen: state.object.editFormIsOpen,
    lists: state.system.lists,
    clientId: state.system.tenantId,
    clients: state.clients.clients,

  };
};

export default connect(
  mapStateToProps,
  {
    saveObject,
    addRelatedObject,
    addRelatedObjectByObjectId,
    removeRelatedObject,
    updateObjectFieldValue,
    addObjectMultiSelectValue,
    createObjectMultiSelectValue,
    createObjectSelectValue,
    removeObjectMultiSelectValue,
    clearObjectMultiSelectValue,
    lookupLocationFromId,
    cancelEdit,
    updateFormState
  }
)(EditObjectPage);
