import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
} from "reactstrap";
import { bool, string, func, arrayOf, shape } from "prop-types";
import FormTextInput from "../common/FormTextInput";
import FormDateInput from "../common/FormDateInput";
import FormSelectInput from "../main/components/FormSelectInput";
import {
  makeOptionListFromStringArray,
  makeOptionValueFromString
} from "../../tools/dropdownListUtils";

import { shipmentLogShape } from "../../models/shipmentLog";
import {
  saveShipmentLog,
  cancelChanges,
  updateShipmentLogFieldValue
} from "../../actions/shipmentLogActions";
import {
  editFormHeaderStyle,
  editFormHeaderActionButtonStyle,
  editFormHeaderCancelButtonStyle,
} from "../../styles/formStyles";

class EditShipmentLogPage extends Component {
  handleChange = e => {
    e.preventDefault();
    let value;
    if (event.target.type == "checkbox") {
      value = !!(event.target.type === "checked");
    }
    else {
      value = event.target.value;
    }

    const { updateShipmentLogFieldValue } = this.props;
    updateShipmentLogFieldValue(e.target.id, value);
  };

  handleSelectChange = (newValue, actionMeta) => {
    const action = actionMeta.action;
    const name = actionMeta.name;

    let value;

    if (action === "clear") {
      value = "";
    }
    else if (action === "select-option") {
      value = newValue.value;
    }
    else {
      return;
    }

    const { updateShipmentLogFieldValue } = this.props;
    updateShipmentLogFieldValue(name, value);
  };

  handleDateChange = (id, date) => {
    const { updateShipmentLogFieldValue } = this.props;
    updateShipmentLogFieldValue(id, date.toISOString().slice(0, 10));

  };

  handleSave = e => {
    e.preventDefault();

    const { saveShipmentLog } = this.props;
    saveShipmentLog();
  };

  handleCancel = e => {
    e.preventDefault();

    const { cancelChanges } = this.props;
    cancelChanges();
  };

  render = () => {
    const {
      clientList,
      action,
      editFormIsOpen,
      isValid,
      isDirty,
      shipmentLog,
      saveSuccess,
      saving,
      statuses
    } = this.props;

    return (
      <ReactModal isOpen={editFormIsOpen} ariaHideApp={false}>
        <Alert color="sucess" isOpen={saveSuccess}>
          Save Successful
        </Alert>
        <Alert color="dangery" isOpen={false}>
          Error Saving
        </Alert>

        <div className="editShipmentLogPages">
          <Form>
            <Container
              fluid
              className="form-header"
              style={editFormHeaderStyle}
            >
              <Row>
                <Col xs="6">
                  <h2>{action}</h2>
                </Col>
                <Col xs="3">
                  <Button
                    onClick={this.handleCancel}
                    style={editFormHeaderCancelButtonStyle}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={this.handleSave}
                    style={editFormHeaderActionButtonStyle}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Container>

            <FormSelectInput
              label="Client"
              id="clientName"
              required
              placeholder="Select Status"
              options={clientList.map(x => ({
                label: x.clientName,
                value: x.clientName,
              }))}
              value={{
                label: shipmentLog.clientName,
                value: shipmentLog.clientName,
              }}
              handleChange={(newValue, actionMeta) => this.handleSelectChange(newValue, actionMeta)}
            />

            <FormTextInput
              label="Sender"
              id="sender"
              required
              value={shipmentLog.sender}
              handleChange={this.handleChange}
            />

            <FormTextInput
              label="Carrier"
              id="carrier"
              required
              value={shipmentLog.carrier}
              handleChange={this.handleChange}
            />

            <FormDateInput
              label="Date Shipped"
              id="dateShipped"
              required
              value={shipmentLog.dateShipped}
              handleChange={this.handleDateChange}
            />

            <FormTextInput
              label="Items in Shipment"
              id="itemsInShipment"
              type="textarea"
              required
              value={shipmentLog.itemsInShipment}
              handleChange={this.handleChange}
            />

            <FormTextInput
              label="Tracking Number"
              id="trackingNumber"
              value={shipmentLog.trackingNumber}
              handleChange={this.handleChange}
            />

            <FormDateInput
              label="Date Received"
              id="dateReceived"
              value={shipmentLog.dateReceived}
              handleChange={this.handleDateChange}
            />

            <FormSelectInput
              label="Status"
              id="status"
              required
              placeholder="Select Status"
              options={makeOptionListFromStringArray(statuses)}
              value={makeOptionValueFromString(shipmentLog.status)}
              handleChange={(newValue, actionMeta) => this.handleSelectChange(newValue, actionMeta)}
            />
          </Form>
        </div>
        <div className="Loading">
          {saving === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Saving...
            </span>
          ) : null}
        </div>
      </ReactModal>
    );
  };
}

EditShipmentLogPage.propTypes = {
  clientList: arrayOf(
    shape({
      tenantId: string,
      client: string
    })
  ).isRequired,
  action: string.isRequired,
  editFormIsOpen: bool.isRequired,
  isValid: bool.isRequired,
  isDirty: bool.isRequired,
  saving: bool.isRequired,
  saveSuccess: bool.isRequired,
  shipmentLog: shipmentLogShape.isRequired,
  updateShipmentLogFieldValue: func.isRequired,
  saveShipmentLog: func.isRequired,
  cancelChanges: func.isRequired,
  statuses: arrayOf(string).isRequired
};

const mapStateToProps = state => ({
  clientList: state.system.lists["clients"],
  action: state.shipmentLog.action,
  editFormIsOpen: state.shipmentLog.editFormIsOpen,
  isValid: state.shipmentLog.isValid,
  isDirty: state.shipmentLog.isDirty,
  saveSuccess: state.shipmentLog.saveSuccess,
  shipmentLog: state.shipmentLog.editShipmentLog,
  saving: state.shipmentLog.isSaving,
  statuses: state.system.lists["shipmentStatuses"]
});

export default connect(
  mapStateToProps,
  {
    updateShipmentLogFieldValue,
    saveShipmentLog,
    cancelChanges
  }
)(EditShipmentLogPage);
