import React, { Component } from 'react';
import { string, func, object, oneOfType } from 'prop-types';
import { Auth, Logger, JS } from 'aws-amplify';
import AppSignInForm from './AppSignInForm';
//import AppRequireNewPassword from './AppRequireNewPasswordForm';

const logger = new Logger('AppSignIn');


class AppSignIn extends Component {
  constructor(props) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);
    this.changeState = this.changeState.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];

    this.inputs = {};
    this.state = {
      username: '',
      password: '',
      processing: false,
      error: '',
    }
  }

  handleChange = (event) => {
    event.preventDefault();

    this.setState({[event.target.id]: event.target.value });
  }

  formIsValid = () => {
    const { username, password } = this.state;

    return username != null &&
      password != null &&
      password.length >= 8;
  }

  forgotPasswordRequest = () => {
    const {
      user,
    } = this.state;
    this.changeState('forgotPassword', user);
  }

  changeState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(state, data);
    }
  }

  checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    Auth.verifiedContact(user)
      .then(data => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState('signedIn', user);
        }
        else {
          // user is unverified.  Verify email or phone in case account needs to be recovered in future
          user = Object.assign(user, data);
          this.changeState('verifyContact', user);
        }
      });
  }


  signIn() {
    const { username, password } = this.state;

    this.setState({ processing: true, error: '' });

    Auth.signIn(username.trim().toLowerCase(), password.trim())
      .then(user => {
        this.setState({ processing: false});
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          logger.debug('confirm user with ' + user.challengeName);
          this.changeState('confirmSignIn', user);
        }
        else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          logger.debug('require new password', user.challengeParam);
          this.changeState('requireNewPassword', user);
        }
        else if (user.challengeName === 'MFA_SETUP') {
          logger.debug('TOTP setup', user.challengeParam);
          this.changeState('TOTPSetup', user);
        }
        else {
          this.checkContact(user);
        }
      })
      .catch(err => {
        if (err.code === 'UserNotConfirmedException') {
            logger.debug('the user is not confirmed');
            this.changeState('confirmSignUp');
        }
        else if (err.code === 'PasswordResetRequiredException') {
            logger.debug('the user requires a new password');
            this.changeState('requireNewPassword');
        }
        else {
          this.setState({ processing: false, error: err.message || err });
            //this.error(err);
        }
    });
  }

  render() {
    const { authState, authData } = this.props;

    if (!this._validAuthStates.includes(authState)) {
      return null;
    }

    const { error, processing } = this.state;
    const formIsValid = this.formIsValid();

    return (
      <AppSignInForm
        authData={authData}
        error={error}
        handleChange={this.handleChange}
        signIn={this.signIn}
        forgotPassword={this.forgotPasswordRequest}
        processing={processing}
        formIsValid={formIsValid}
      />
    );

  }
}

AppSignIn.propTypes = {
  authState: string,
  authData: oneOfType([
    string,
    object
  ]),
  onStateChange: func,
}

AppSignIn.defaultProps = {
  authState: 'default',
  authData: '',
  onStateChange: null,
}

export default AppSignIn;
