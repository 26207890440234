import React from "react";
import { Container, Row, Col } from "reactstrap";
import { func, string, arrayOf, shape } from "prop-types";
import SearchItem from "./SearchItem";

const AdminSearchResults = ({
  items,
  requestObject,
  requestedObjects,
  processing
}) => (
  <Container fluid className="search-results">
    <Row>
      {items.map(item => (
        <Col key={item.objectId} xs="12" sm="6" md="3">
          <SearchItem
            key={item.objectId}
            item={item}
            requestObject={requestObject}
            processing={processing.includes(item.objectId)}
            action={
              requestedObjects.includes(item.objectId) ? "Remove" : "Request"
            }
          />
        </Col>
      ))}
    </Row>
  </Container>
);

AdminSearchResults.propTypes = {
  items: arrayOf(
    shape({
      objectId: string,
      objectType: string,
      inventoryId: string,
      designer: string
    })
  ).isRequired,
  requestedObjects: arrayOf(string).isRequired,
  processing: arrayOf(string).isRequired,
  requestObject: func.isRequired,
};

export default AdminSearchResults;
