import { initialLists } from "../reducers/systemReducer";
import { doFetch } from "./index";

export const fetchSystemLists = async tenantId => {
  if (!tenantId) {
    return initialLists;
  }

  const path = `/system-lists`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;

};


export const persistSystemList = async (listName, values) => {
  const path = `/system-lists?listName=${listName}`;

  const params = {
    method: "POST",
    headers: {},
    body: JSON.stringify(values)
  };

  const response = await doFetch(path, params);

  return response;
}
