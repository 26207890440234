import { string, shape, arrayOf } from "prop-types";

export const client = {
  clientId: "",
  clientName: "",
  clientType: "",
  users: []
};

export const clientShape = shape({
  clientId: string,
  clientName: string,
  clientType: string,
  users: arrayOf(shape({
    userId: string,
    firstName: string,
    lastName: string,
    fullName: string,
    role: string,
    tier: string,
    clientId: string,
    clientName: string
  }))
});
