import {
  INITIALIZE_NOIMAGES_FILTER,
  CHANGE_NOIMAGES_FILTER,
  LOAD_OBJECTS_NOIMAGES_REQUESTED,
  LOAD_OBJECTS_NOIMAGES_SUCCESS,
  LOAD_OBJECTS_NOIMAGES_FAILURE
} from "../actions/imageManagementActions";
import { SET_CLIENT_ID} from "../actions/systemActions";
import { LOGOUT_SUCCESS } from "../actions/authActions";
import { NULL_UUID } from "../api/constants";

const initialFilter = {
  clientId: NULL_UUID,
  fromDate: "",
  toDate: "",
  division: "",
  year: "",
  season: "",
  staff: ""
};

const initialState = {
  filter: { ...initialFilter },
  reloadList: false,
  loading: false,
  objects: [],
  error: null
};

const imageManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_NOIMAGES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          fromDate: action.fromDate,
          toDate: action.toDate
        }
      };

    case CHANGE_NOIMAGES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.id]: action.value
        }
      };

    case LOAD_OBJECTS_NOIMAGES_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_OBJECTS_NOIMAGES_SUCCESS:
      return {
        ...state,
        reloadList: false,
        loading: false,
        objects: [...action.payload]
      };

    case LOAD_OBJECTS_NOIMAGES_FAILURE:
      return {
        ...state,
        reloadList: false,
        loading: false,
        error: { ...action.payload }
      };

    case SET_CLIENT_ID:
      return {
        ...state,
        reloadList: true
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default imageManagementReducer;
