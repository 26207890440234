import React from "react";
import { string, bool } from "prop-types";

const hiddenRowStyle = {
  display: "none"
};

const viewObjectRowSyle = {
  color: "black",
  borderStyle: "none",
  padding: ".25rem"
}


const viewObjectLabelStyle = {
  ...viewObjectRowSyle,
  fontWeight: "600",
  minWidth: "180px",
};

const viewObjectValueStyle = {
  ...viewObjectRowSyle,
};

const viewHighlightedObjectValueStyle = {
  ...viewObjectRowSyle,
  color: "red"
};

const ObjectViewRow = ({ label, value, highlighted, hidden }) => (
  <tr key={label} style={hidden ? hiddenRowStyle : null}>
    <td style={viewObjectLabelStyle}>
      {label}
      :
    </td>
    <td
      style={
        highlighted ? viewHighlightedObjectValueStyle : viewObjectValueStyle
      }
    >
      {value}
    </td>
  </tr>
);

ObjectViewRow.propTypes = {
  label: string.isRequired,
  value: string,
  highlighted: bool,
  hidden: bool
};

ObjectViewRow.defaultProps = {
  highlighted: false,
  hidden: false,
  value: ""
};

export default ObjectViewRow;
