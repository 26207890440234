import React from "react";
import { Link } from "react-router-dom";
import Menu from "react-burger-menu/lib/menus/slide";
import { Button, Navbar, NavbarBrand } from "reactstrap";
import { string, bool, func } from "prop-types";
import logo from "../../assets/images/da-logo-white.png";
import digitalArchivistIcon from "../../assets/images/icons/Digital-Archivist-Icon.png";
import searchObjectsIcon from "../../assets/images/icons/Search-Objects-Icon.png";
import objectRequestsIcon from "../../assets/images/icons/Object-Requests-Icon.png";
import changePasswordIcon from "../../assets/images/icons/Change-Password-Icon.png";
import logOutIcon from "../../assets/images/icons/Log-Out-Icon.png";

import {
  appHeaderStyle,
  navbarContentsStyle,
  navbarBrandStyle,
  navbarLogoStyle,

  burgerMenuStyles,
  menuUserIconStyle,
  menuUsernameStyle,
  menuIconStyle,
  menuLogoutStyle,
  menuLogoutButtonStyle
} from "../../styles/menuStyles";

const StandardUserMenu = ({
  userName,
  sideBarMenuIsOpen,
  isMenuOpen,
  signMeOut,
  closeSideNav
}) => (
  <Navbar className="navbar-fixed-top" expand="md" style={appHeaderStyle}>
    <div style={navbarContentsStyle}>
      <NavbarBrand tag={Link} to="/" style={navbarBrandStyle}>
        <img src={logo} alt="The Digital Archivist" style={navbarLogoStyle} />
      </NavbarBrand>

      <div id="outer-container">
        <Menu
          right
          customCrossIcon={false}
          isOpen={sideBarMenuIsOpen}
          styles={burgerMenuStyles(sideBarMenuIsOpen || false)}
          pageWrapId="page-wrap"
          outerContainerId="outer-container"
          onStateChange={isMenuOpen}
        >
          <div>
            <Link to="/" className="menu-item" onClick={closeSideNav}>
              <img
                src={digitalArchivistIcon}
                alt="User Icon"
                style={menuUserIconStyle}
              />
              <span style={menuUsernameStyle}>{userName}</span>
            </Link>
          </div>

          <Link to="/" className="menu-item" onClick={closeSideNav}>
            <img
              src={searchObjectsIcon}
              alt="Your Archive"
              style={menuIconStyle}
            />
            Your Archive
          </Link>

          <Link to="/my-saved-objects" className="menu-item" onClick={closeSideNav}>
            <img
              src={objectRequestsIcon}
              alt="Your Saved Objects"
              style={menuIconStyle}
            />
            Your Saved Objects
          </Link>

          <Link
            to="/change-password"
            className="menu-item"
            onClick={closeSideNav}
          >
            <img
              src={changePasswordIcon}
              alt="Change Password"
              style={menuIconStyle}
            />
            Change Password
          </Link>

          <div style={menuLogoutStyle}>
            <Button
              onClick={signMeOut}
              color="link"
              style={menuLogoutButtonStyle}
            >
              <img src={logOutIcon} alt="Logout" style={menuIconStyle} />
              Logout
            </Button>
          </div>
        </Menu>
      </div>
    </div>
  </Navbar>
);

StandardUserMenu.propTypes = {
  userName: string.isRequired,
  sideBarMenuIsOpen: bool,
  isMenuOpen: func.isRequired,
  signMeOut: func.isRequired,
  closeSideNav: func.isRequired
}

StandardUserMenu.defaultProps = {
  sideBarMenuIsOpen: false,
}



export default StandardUserMenu;
