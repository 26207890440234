import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  FormGroup,
  Label
} from "reactstrap";
import { bool, string, func } from "prop-types";
import FormTextInput from "../common/FormTextInput";
import FormSelectInput from "../main/components/FormSelectInput";

import { userShape } from "../../models/user";
import {
  saveUser,
  cancelChanges,
  updateUserFieldValue,
  updateUserFormState,
  disableUser,
  enableUser
} from "../../actions/userActions";
import FormCheckBox from "../main/components/FormCheckBox";
import {
  editFormHeaderStyle,
  editFormHeaderCheckboxStyle,
  editFormHeaderActionButtonStyle,
  editFormHeaderCancelButtonStyle,
  editFormHeaderNoteStyle,
  editFormRequiredFieldIndicatorStyle
} from "../../styles/formStyles";
import { makeOptionValueFromString, makeOptionListFromStringArray } from "../../tools/dropdownListUtils";
import { NULL_UUID } from "../../api/constants";


class EditUserPage extends Component {
  handleChange = e => {
    e.preventDefault();

    let value;
    if (event.target.type == "checkbox") {
      value = !!(event.target.type === "checked");
    }
    else {
      if (event.target.id == "email") {
        value = event.target.value.toLowerCase();
      }
      else {
        value = event.target.value;
      }

    }

    const { updateUserFieldValue } = this.props;
    updateUserFieldValue(e.target.id, value);
  };

  handleSelectChange = (newValue, actionMeta) => {
    const action = actionMeta.action;
    const name = actionMeta.name;

    let value;

    if (action === "clear") {
      value = "";
    }
    else if (action === "select-option") {
      value = newValue.value;
    }
    else {
      return;
    }

    const { updateUserFieldValue } = this.props;
    updateUserFieldValue(name, value);
  };

  handleFormStateChange = event => {
    const { updateUserFormState } = this.props;
    updateUserFormState(event.target.id, !!event.target.value);
  };

  handleSave = e => {
    e.preventDefault();

    const { saveUser } = this.props;
    saveUser();
  };

  handleCancel = e => {
    e.preventDefault();

    const { cancelChanges } = this.props;
    cancelChanges();
  };

  handleAddUser = e => {
    e.preventDefault();
  };

  changeUserStatus = user => {
    const { disableUser, enableUser } = this.props;

    if (user.status === "Active") {
      disableUser(user);
    }
    else if (user.status === "Disabled") {
      enableUser(user);
    }
  }

  render = () => {
    const {
      action,
      editFormIsOpen,
      isValid,
      isDirty,
      user,
      saveSuccess,
      saving,
      addAnother
    } = this.props;

    return (
      <ReactModal isOpen={editFormIsOpen} ariaHideApp={false}>
        <Alert color="sucess" isOpen={saveSuccess}>
          Save Successful
        </Alert>
        <Alert color="dangery" isOpen={false}>
          Error Saving
        </Alert>

        <div className="editUserPages">
          <Form>
            <Container fluid className="form-header" style={editFormHeaderStyle}>
              <Row>
                <Col xs="3" style={editFormHeaderCheckboxStyle}>
                  <FormCheckBox
                    id="addAnother"
                    label="Add Another"
                    checked={addAnother}
                    handleChange={this.handleFormStateChange}
                  />
                </Col>
                <Col xs="3">
                  <Button
                    onClick={this.handleCancel}
                    style={editFormHeaderCancelButtonStyle}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={user.userId === ""}
                    onClick={e => {
                      e.preventDefault();
                      this.changeUserStatus(user)
                    }}
                    style={editFormHeaderActionButtonStyle}
                  >
                    {user.status === "Active" ? "Disable User" : "Enable User"}
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={this.handleSave}
                    style={editFormHeaderActionButtonStyle}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Container>

            <div className="form-header-title">
              <h2>{action}</h2>
              <div className="title-note" style={editFormHeaderNoteStyle}>
                <span
                  className="required-field-indicator"
                  style={editFormRequiredFieldIndicatorStyle}
                >
                *
                </span>
                Indicates required field
              </div>
            </div>

            <FormGroup row>
              <Label sm={3}>Client</Label>
              <Label sm={9}>{user.clientName}</Label>
            </FormGroup>

            <FormTextInput
              label="First Name"
              id="firstName"
              required
              value={user.firstName}
              handleChange={this.handleChange}
            />

            <FormTextInput
              label="Last Name"
              id="lastName"
              required
              value={user.lastName}
              handleChange={this.handleChange}
            />

            <FormTextInput
              label="Full Name"
              id="fullName"
              required
              value={user.fullName}
              handleChange={this.handleChange}
            />

            <FormTextInput
              label="Email"
              id="email"
              required
              disabled={user.userId !== NULL_UUID}
              value={user.email}
              handleChange={this.handleChange}
            />

            <FormSelectInput
              label="Role"
              id="role"
              required
              options={
                user.clientName == "Digital Archivist" ?
                  makeOptionListFromStringArray(["Digital Archivist User", "Digital Archivist Administrator", "System Administrator"]) :
                  makeOptionListFromStringArray(["User"])
              }
              value={makeOptionValueFromString(user.role)}
              handleChange={(newValue, actionMeta) => this.handleSelectChange(newValue, actionMeta)}
            />

            <FormSelectInput
              label="Tier"
              id="tier"
              required
              options={makeOptionListFromStringArray(["Standard"])}
              value={makeOptionValueFromString(user.tier)}
              handleChange={(newValue, actionMeta) => this.handleSelectChange(newValue, actionMeta)}
            />
          </Form>
        </div>
        <div className="Loading">
          {saving === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Saving...
            </span>
          ) : null}
        </div>
      </ReactModal>
    );
  };
}

EditUserPage.propTypes = {
  action: string.isRequired,
  editFormIsOpen: bool.isRequired,
  isValid: bool.isRequired,
  isDirty: bool.isRequired,
  saving: bool.isRequired,
  addAnother: bool.isRequired,
  saveSuccess: bool.isRequired,
  user: userShape.isRequired,
  updateUserFieldValue: func.isRequired,
  saveUser: func.isRequired,
  cancelChanges: func.isRequired,
  updateUserFormState: func.isRequired,
  disableUser: func.isRequired,
  enableUser: func.isRequired,
};

const mapStateToProps = state => ({
  addAnother: state.users.addAnother,
  action: state.users.action,
  editFormIsOpen: state.users.editFormIsOpen,
  isValid: state.users.isValid,
  isDirty: state.users.isDirty,
  saveSuccess: state.users.saveSuccess,
  user: state.users.editUser,
  saving: state.users.isSaving
});

export default connect(
  mapStateToProps,
  {
    updateUserFieldValue,
    updateUserFormState,
    saveUser,
    cancelChanges,
    disableUser,
    enableUser
  }
)(EditUserPage);
