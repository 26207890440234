import moment from "moment";
import {
  UPDATE_CHECKIN_FORM_STATE,
  CHECKIN_LOOKUP_OBJECT_REQUESTED,
  CHECKIN_LOOKUP_OBJECT_SUCCESS,
  CHECKIN_LOOKUP_OBJECT_FAILURE,
  CHECKIN_LOOKUP_LOCATION_REQUESTED,
  CHECKIN_LOOKUP_LOCATION_SUCCESS,
  CHECKIN_LOOKUP_LOCATION_FAILURE,
  CHECKIN_OBJECT_REQUESTED,
  CHECKIN_OBJECT_SUCCESS,
  CHECKIN_OBJECT_FAILURE,
  REFRESH_CHECKEDIN_OBJECTS,
  DELETE_CHECKIN_REQUESTED,
  DELETE_CHECKIN_SUCCESS,
  DELETE_CHECKIN_FAILURE,
  REMOVE_PENDING_CHECKIN,
  INITIALIZE_CHECKINS
} from "../actions/checkInActions";
import { SET_CLIENT_ID } from "../actions/systemActions";
import { createCheckInObject } from "../api/checkinApi";


import { LOGOUT_SUCCESS } from "../actions/authActions";


const initialState = {
  locationId: "",
  objectId: "",
  object: {},
  location: {},
  checkInDate: moment(new Date()).format("YYYY-MM-DD"),
  currentCheckInObject: createCheckInObject(),
  checkedInObjects: [],
  loading: false,
  saveSuccess: false,
  isSaving: false,
  error: null,
  objectFound: false
};

const checkinReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_CHECKINS:
      return {
        ...initialState
      }

    case UPDATE_CHECKIN_FORM_STATE:
      return {
        ...state,
        [action.id]: action.value
      };

    case CHECKIN_LOOKUP_OBJECT_REQUESTED:
      return {
        ...state,
        loading: true

      };

    case CHECKIN_LOOKUP_OBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        objectId: "",
        object: action.payload,
        currentCheckInObject: {
          ...state.currentCheckInObject,
          objectId: action.payload.objectId,
          designer: action.payload.designer,
          inventoryId: action.payload.inventoryId,
          objectType: action.payload.objectType,
          checkInDate: action.checkInDate
        },
        objectFound: true
      };

    case REFRESH_CHECKEDIN_OBJECTS:
      return {
        ...state,
        checkedInObjects: [
          ...state.checkedInObjects.filter(
            obj => obj.objectId !== action.payload.objectId
          ),
          action.payload
        ]
      };
    case CHECKIN_LOOKUP_OBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case CHECKIN_LOOKUP_LOCATION_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case CHECKIN_LOOKUP_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locationId: "",
        currentCheckInObject: {
          ...state.currentCheckInObject,
          locationId: action.payload.locationId,
          locationName: action.payload.locationName,
        },
        objectFound: false
      };

    case CHECKIN_LOOKUP_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case CHECKIN_OBJECT_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case CHECKIN_OBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        locationId: "",
        objectId: "",
        location: {},
        object: {},
        currentCheckInObject: {...initialState.currentCheckInObject},
        checkedInObjects: [
          ...state.checkedInObjects.filter(
            obj => obj.objectId !== action.payload.objectId
          ),
          action.payload
        ]
      };

    case CHECKIN_OBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case REMOVE_PENDING_CHECKIN:
      return {
        ...state,
        currentCheckInObject: {...initialState.currentCheckInObject},
      }

    case DELETE_CHECKIN_REQUESTED:
      return {
        ...state,
       deleteCheckInId: action.checkInId
      }

    case DELETE_CHECKIN_SUCCESS:
      return {
        ...state,
        checkedInObjects: [
          ...state.checkedInObjects.filter(x => x.checkInId !== action.checkInId)
        ],
        deleteCheckInId: null
      }

    case DELETE_CHECKIN_FAILURE:
      return {
        ...state,
       error: action.payload,
       deleteCheckInId: null
      }

    case SET_CLIENT_ID:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default checkinReducer;
