import { Logger } from "aws-amplify";
import "@babel/polyfill";
import {
  fetchObject,
  persistObject,
  createArchiveObject
} from "../api/objectApi";
import { saveSystemList } from "./systemActions";
import findObject from "../api/lookupObjectApi";
import objectsAreEqual from "../tools/objectChecker";
import { isValidInventoryId, isValidV4UUID } from "../tools/dataValidator";
import { loadLocations } from "./locationActions";

export const SHOW_EDIT_FORM = "SHOW_EDIT_FORM";
export const CANCEL_EDIT_FORM = "CANCEL_EDIT_FORM";

export const CLEAR_OBJECT = "CLEAR_OBJECT";
export const URL_CHANGE_RELOAD_OBJECT = "@@router/LOCATION_CHANGE"

export const ADD_OBJECT_REQUESTED = "ADD_OBJECT_REQUESTED";
export const EDIT_OBJECT_REQUESTED = "EDIT_OBJECT_REQUESTED";

export const SAVE_OBJECT_REQUESTED = "SAVE_OBJECT_REQUESTED";
export const SAVE_OBJECT_SUCCESS = "SAVE_OBJECT_SUCCESS";
export const SAVE_OBJECT_FAILURE = "SAVE_OBJECT_FAILURE";

export const LOAD_OBJECT_REQUESTED = "LOAD_OBJECT_REQUESTED";
export const LOAD_OBJECT_SUCCESS = "LOAD_OBJECT_SUCCESS";
export const LOAD_OBJECT_FAILURE = "LOAD_OBJECT_FAILURE";

export const ADD_RELATED_OBJECT_REQUESTED = "ADD_RELATED_OBJECT_REQUESTED";
export const ADD_RELATED_OBJECT_SUCCESS = "ADD_RELATED_OBJECT_SUCCESS";
export const ADD_RELATED_OBJECT_FAILURE = "ADD_RELATED_OBJECT_FAILURE";

export const OBJECT_IMAGE_REFRESH_REQUESTED = "OBJECT_IMAGE_REFRESH_REQUESTED";
export const OBJECT_IMAGE_REFRESH_SUCCESS = "OBJECT_IMAGE_REFRESH_SUCCESS";

export const REMOVE_RELATED_OBJECT = "REMOVE_RELATED_OBJECT";

export const UPDATE_OBJECT_FIELD_VALUE = "UPDATE_OBJECT_FIELD_VALUE";

export const ADD_OBJECT_MULTISELECT_VALUE = "ADD_OBJECT_MULTISELECT_VALUE";
export const REMOVE_OBJECT_MULTISELECT_VALUE = "REMOVE_OBJECT_MULTISELECT_VALUE";
export const CLEAR_OBJECT_MULTISELECT_VALUE = "CLEAR_OBJECT_MULTISELECT_VALUE";

export const UPDATE_FORM_STATE = "UPDATE_FORM_STATE";
export const CHECK_OBJECT_IS_VALID = "CHECK_OBJECT_IS_VALID";
export const CHECK_OBJECT_IS_DIRTY = "CHECK_OBJECT_IS_DIRTY";
export const FLAG_OBJECT_CORE_FIELD_DIRTY = "FLAG_OBJECT_CORE_FIELD_DIRTY";
export const ASSIGN_RELATED_OBJECTS = "ASSIGN_RELATED_OBJECTS";

const logger = new Logger("objectAction");

const assignRelatedObjects = relatedObject => ({
  type: ASSIGN_RELATED_OBJECTS,
  payload: relatedObject
});

export const addObjectRequested = newArchiveObject => ({
  type: ADD_OBJECT_REQUESTED,
  payload: newArchiveObject
});

const addRelatedObjectRequested = () => ({
  type: ADD_RELATED_OBJECT_REQUESTED
})

const addRelatedObjectSuccess = (relatedObject) => ({
  type: ADD_RELATED_OBJECT_SUCCESS,
  payload: relatedObject
});

export const removeRelatedObject = (objectId) => ({
  type: REMOVE_RELATED_OBJECT,
  objectId
})

const addRelatedObjectFailure = () => ({
  type: ADD_RELATED_OBJECT_FAILURE
});

const editObjectRequested = () => ({
  type: EDIT_OBJECT_REQUESTED
});

const showEditForm = () => ({
  type: SHOW_EDIT_FORM
});

const cancelEditForm = () => ({
  type: CANCEL_EDIT_FORM
});

const loadObjectRequested = () => ({
  type: LOAD_OBJECT_REQUESTED
});

const loadObjectSuccess = archiveObject => ({
  type: LOAD_OBJECT_SUCCESS,
  payload: archiveObject
});

const loadObjectFailure = error => ({
  type: LOAD_OBJECT_FAILURE,
  payload: { error }
});

const saveObjectRequested = () => ({
  type: SAVE_OBJECT_REQUESTED
});

const saveObjectSuccess = archiveObject => ({
  type: SAVE_OBJECT_SUCCESS,
  payload: archiveObject,
  saveSuccess: true
});

const saveObjectFailure = error => ({
  type: SAVE_OBJECT_FAILURE,
  payload: { error }
});

const updateObject = (id, value) => ({
  type: UPDATE_OBJECT_FIELD_VALUE,
  id,
  value
});

const flagCoreFieldAsDirty = () => ({
  type: FLAG_OBJECT_CORE_FIELD_DIRTY,
});

const addMultiSelectValue = (id, value) =>  ({
  type: ADD_OBJECT_MULTISELECT_VALUE,
  id,
  value
})

const removeMultiSelectValue = (id, value) =>  ({
  type: REMOVE_OBJECT_MULTISELECT_VALUE,
  id,
  value
})

const clearMultiSelectValue = (id) =>  ({
  type: CLEAR_OBJECT_MULTISELECT_VALUE,
  id
})

const setformState = (id, value) => ({
  type: UPDATE_FORM_STATE,
  id,
  value
});

export const refreshObjectImage = () => ({
  type: OBJECT_IMAGE_REFRESH_REQUESTED,
})

export const refreshObjectImageSuccess = () => ({
  type: OBJECT_IMAGE_REFRESH_SUCCESS,
})

export const clearObject = () => ({
  type: CLEAR_OBJECT
});

export const updateFormState = (id, value) => dispatch => {
  dispatch(setformState(id, value));
};

export const updateObjectFieldValue = (id, value) => async dispatch => {
  if (id === "flagForMissingInfo") {
    value = (value === true) ? "Flagged" : null;
  }

  await dispatch(updateObject(id, value));
  if (id === "designer" || id === "objectType" || id === "inventoryId") {
    dispatch(flagCoreFieldAsDirty());
  }

  if (id === "primaryImage" || id === "secondaryImage") {
    dispatch(updateObject("imageUpdated", new Date().toLocaleString()));
  }
  if (id === "primaryImage") {
    if (value && value.length > 0) {
      dispatch(updateObject("primaryImageStatus", "PRIMARY_IMAGE_SET"));
    }
    else {
      dispatch(updateObject("primaryImageStatus", "MISSING_PRIMARY_IMAGE"));
    }
  }

  dispatch(validateObject());
  dispatch(objectIsDirty());
};

export const addObjectMultiSelectValue = (id, value) => async dispatch => {
  dispatch(addMultiSelectValue(id, value));
  dispatch(validateObject());
  dispatch(objectIsDirty());
}

export const removeObjectMultiSelectValue = (id, value) => async dispatch => {
  dispatch(removeMultiSelectValue(id, value));
  dispatch(validateObject());
  dispatch(objectIsDirty());
}

export const clearObjectMultiSelectValue = (id) => async dispatch => {
  dispatch(clearMultiSelectValue(id));
  dispatch(validateObject());
  dispatch(objectIsDirty());
}

const validateObject = () => async (dispatch, getState) => {
  const { editObject } = getState().object;
  let isValid = false;

  // TO-DO: put this in its own functions
  isValid =
    !!editObject.inventoryId &&
    editObject.inventoryId.length > 0 &&
    !!editObject.objectType &&
    editObject.objectType.length > 0 &&
    !!editObject.designer &&
    editObject.designer.length > 0 &&
    !!editObject.clientId &&
    editObject.clientId.length === 36;

  await dispatch({
    type: CHECK_OBJECT_IS_VALID,
    isValid
  });
};

const objectIsDirty = () => async (dispatch, getState) => {
  const { editObject, originalObject } = getState().object;
  const isDirty = !objectsAreEqual(editObject, originalObject);

  dispatch({
    type: CHECK_OBJECT_IS_DIRTY,
    isDirty
  });

};


export const addRelatedObject = (archiveObject) => async (dispatch, getState) => {
  //this may be refactorable into save object.
  // will need to move edit form logic out of save object
  const {
    isDirty,
    isValid
  } = getState().object;

  if (isDirty && isValid) {

    dispatch(saveObjectRequested());
    try {
      const success = await persistObject(archiveObject);
      if (success) {
        dispatch(saveObjectSuccess(archiveObject));
        dispatch(loadObjectSuccess(archiveObject));
      }
    }
    catch (err) {
      dispatch(saveObjectFailure(err));
      return;
    }
  }
  dispatch(addObject(archiveObject));
}

export const addObject = (relatedObject = null) => async (dispatch, getState) => {
  const { tenantId, clientName } = getState().system;
  const { editFormIsOpen } = getState().object.editFormIsOpen;
  const newArchiveObject = createArchiveObject(tenantId, clientName);

  await dispatch(addObjectRequested(newArchiveObject));

  if (relatedObject) {
    dispatch(assignRelatedObjects(relatedObject));
  }


  await dispatch(validateObject());
  if (!editFormIsOpen) {
    await dispatch(showEditForm());
  }
};

export const editObject = () => async dispatch => {
  dispatch(editObjectRequested());
  dispatch(validateObject());
  dispatch(showEditForm());
};

export const saveObject = archiveObject => async (dispatch, getState) => {
  const { viewObject, addAnother } = getState().object;

  dispatch(saveObjectRequested());

  try {

    const success = await persistObject(archiveObject);

    if (success) {
      logger.info("object successfully persisted to database");
      dispatch(saveObjectSuccess(archiveObject));

      if (viewObject.objectId === archiveObject.objectId) {
        logger.info("resetting viewObject to match saved object");

        dispatch(loadObjectSuccess(archiveObject));

        if (archiveObject.imageUpdated) {
            dispatch(refreshObjectImage());
          }
      }

      if (addAnother) {
        logger.info("Request to add a new object after save");
        dispatch(addObject());
      }
      else {
        logger.info("Reques to close the edit form after save");
        dispatch(cancelEditForm());
      }
    }
  }
  catch (err) {
    dispatch(saveObjectFailure(err));
  }
};

export const loadObject = objectId => async (dispatch, getState) => {
  dispatch(loadObjectRequested());

  try {
    const { tenantId } = getState().system;
    const data = await fetchObject(objectId, tenantId);
    dispatch(loadObjectSuccess(data));
  }
  catch (err) {
    dispatch(loadObjectFailure(err));
  }
};

export const cancelEdit = () => dispatch => {
  dispatch(cancelEditForm());
};

export const addRelatedObjectByObjectId = (id) => async (dispatch, getState) => {
  if (!id || id == null || id === "") {
    return;
  }

  dispatch(addRelatedObjectRequested());

  const { editObject } = getState().object;
  const { tenantId: clientId } = getState().system;

  const lookupInventoryId= id.replace(/\D/g,'');
  let object;
  let objectId = id;

  if (isValidInventoryId(lookupInventoryId)  ) {

    if (editObject.relatedObjects.some(obj => obj.inventoryId === lookupInventoryId)) {

      dispatch(addRelatedObjectFailure("the object is already in related list"));
      return;
    }
    object = await findObject(lookupInventoryId, clientId);
    objectId = object.objectId
  }

  if (isValidV4UUID(objectId)) {
    if (editObject.relatedObjects.some(obj => obj.objectId === objectId)) {
      dispatch(addRelatedObjectFailure("the object is already here UUID"));
      return;
    }
    object = await fetchObject(objectId, clientId);
  }

  if (object == null) {
    dispatch(addRelatedObjectFailure("null object returned"));
    return;
  }

  if (Object.keys(object).length === 0) {
    dispatch(addRelatedObjectFailure("No object keys"));
    return;
  }

  dispatch(addRelatedObjectSuccess(object));

}

export const createObjectMultiSelectValue = (id, value, listName) => async (dispatch, getState) => {
  const { lists } = getState().system;
  const origList = lists[listName];

  const newList = [
    ...origList,
    ...value.filter(x => {
      if (!origList.includes(x)) {
        return x;
      }
    })
  ].sort((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });


  dispatch(saveSystemList(listName, newList));

  dispatch(addObjectMultiSelectValue(id, value));

}

export const lookupLocationFromId = locationId => async (dispatch, getState) => {
  let { locations } = getState().location;

  if (locations.length === 0) {
    await dispatch(loadLocations());
    locations = getState().location.locations;
  }
  const location = locations.filter(x => x.locationId === locationId);
  if (location.length > 0) {
    dispatch(updateObjectFieldValue("location", location[0].locationName));
  }

}

export const createObjectSelectValue = (id, value, listName) => async (dispatch, getState) => {
  const { lists } = getState().system;
  const origList = lists[listName];

  const newList = [
    ...origList.filter(x => x != value),
    value
  ].sort((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });


  dispatch(saveSystemList(listName, newList));

  dispatch(updateObjectFieldValue(id, value));

}

