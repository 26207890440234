import React from 'react';
import { bool, string, arrayOf } from 'prop-types';
import {
  noteRowStyle,
  notesLabelStyle,
  tagsValueStyle
} from "../../styles/viewObjectStyles";

const ViewObjectTags = ({tags, hideOnClientPortal}) => {
  if (hideOnClientPortal && (tags.length === 0) || !tags) {
    return null;
  }

  return (
    <div style={noteRowStyle}>
      <div style={notesLabelStyle}>Tags:</div>
      <div style={tagsValueStyle}>
        {tags
          .map(
            s =>
              s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()
          )
          .join(", ")}
      </div>
    </div>
  )

}

ViewObjectTags.propTypes = {
  tags: arrayOf(string),
  hideOnClientPortal: bool.isRequired,
}

ViewObjectTags.defaultProps = {
  tags: []
}

export default ViewObjectTags;
