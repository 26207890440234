import React from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';
import ScrollToTop from "./common/ScrollToTop";
//import DevTools from './DevTools'

const Root = ({ store, history, persistor }) => (
  <Provider store={store}>
    <div>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </ConnectedRouter>
      </PersistGate>
      {/* <DevTools /> */}
    </div>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,  // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  persistor: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Root;
