import { doFetch } from './index';
// import fakeDatabase from './mockDatabase'

const search = async (type, searchTerm, from, clientId) => {
  if (!searchTerm) {
    return [];
  }

  const path = `/search/${type}?clientId=${clientId}&from=${from}&searchTerm=${searchTerm}`;
  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
};

export default search;
