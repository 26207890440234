import "@babel/polyfill";
import {
  createClient,
  persistClient,
  fetchClientList,
  fetchClient
} from "../api/clientApi";
import objectsAreEqual from "../tools/objectChecker";

export const LOAD_CLIENT_LIST_REQUESTED = "LOAD_CLIENT_LIST_REQUESTED";
export const LOAD_CLIENT_LIST_SUCCESS = "LOAD_CLIENT_LIST_SUCCESS";
export const LOAD_CLIENT_LIST_FAILURE = "LOAD_CLIENT_LIST_FAILURE";

export const LOAD_CLIENT_REQUESTED = "LOAD_CLIENT_REQUESTED";
export const LOAD_CLIENT_SUCCESS = "LOAD_CLIENT_SUCCESS";
export const LOAD_CLIENT_FAILURE = "LOAD_CLIENT_FAILURE";

export const SAVE_CLIENT_REQUESTED = "SAVE_CLIENT_REQUESTED";
export const SAVE_CLIENT_SUCCESS = "SAVE_CLIENT_SUCCESS";
export const SAVE_CLIENT_FAILURE = "SAVE_CLIENT_FAILURE";

export const CHECK_CLIENT_IS_VALID = "CHECK_CLIENT_IS_VALID";
export const CHECK_CLIENT_IS_DIRTY = "CHECK_CLIENT_IS_DIRTY";
export const UPDATE_CLIENT_FIELD_VALUE = "UPDATE_CLIENT_FIELD_VALUE";
export const UPDATE_CLIENT_FORM_STATE = "UPDATE_CLIENT_FORM_STATE";

export const SHOW_CLIENT_EDIT_FORM = "SHOW_CLIENT_EDIT_FORM";
export const ADD_CLIENT_REQUESTED = "ADD_CLIENT_REQUESTED";
export const EDIT_CLIENT_REQUESTED = "EDIT_CLIENT_REQUESTED";
export const CHANGE_CLIENT_FILTER = "CHANGE_CLIENT_FILTER";
export const CANCEL_CLIENT_EDIT_FORM = "CANCEL_CLIENT_EDIT_FORM";
export const INITIALIZE_CLIENT_FILTER = "INITIALIZE_CLIENT_FILTER";

export const changeClientFilterRequested = (id, value) => ({
  type: CHANGE_CLIENT_FILTER,
  id,
  value
});

export const changeClientFilter = (id, value) => dispatch => {
  dispatch(changeClientFilterRequested(id, value));
};

const saveClientRequested = () => ({
  type: SAVE_CLIENT_REQUESTED
});

const saveClientSuccess = client => ({
  type: SAVE_CLIENT_SUCCESS,
  payload: client
});

const saveClientFailure = error => ({
  type: SAVE_CLIENT_FAILURE,
  payload: { ...error }
});

export const cancelClientEdit = () => ({
  type: CANCEL_CLIENT_EDIT_FORM
});

export const addClientRequested = client => ({
  type: ADD_CLIENT_REQUESTED,
  payload: client
});

export const editClientRequested = () => ({
  type: EDIT_CLIENT_REQUESTED,
});

const showClientIsValid = valid => ({
  type: CHECK_CLIENT_IS_VALID,
  payload: valid
});

const updateClient = (id, value) => ({
  type: UPDATE_CLIENT_FIELD_VALUE,
  id,
  value
});

const loadClientListRequested = () => ({
  type: LOAD_CLIENT_LIST_REQUESTED
});

const loadClientListSuccess = clients => ({
  type: LOAD_CLIENT_LIST_SUCCESS,
  payload: clients
});

const loadClientListFailure = error => ({
  type: LOAD_CLIENT_LIST_FAILURE,
  payload: error
});

const loadClientRequested = () => ({
  type: LOAD_CLIENT_REQUESTED
});

const loadClientSuccess = client => ({
  type: LOAD_CLIENT_SUCCESS,
  payload: client
});

const loadClientFailure = error => ({
  type: LOAD_CLIENT_FAILURE,
  payload: error
});

const setformState = (id, value) => ({
  type: UPDATE_CLIENT_FORM_STATE,
  id,
  value
});

export const updateClientFormState = (id, value) => dispatch => {
  dispatch(setformState(id, value));
};

export const updateClientFieldValue = (id, value) => async (
  dispatch,
  getState
) => {
  await dispatch(updateClient(id, value));

  const { editClient } = getState().clients;

  dispatch(validateClient(editClient));

  //dispatch(showClientIsValid(isValid));
  dispatch(clientIsDirty());
};

const clientIsDirty = () => async (dispatch, getState) => {
  const { editClient, originalClient } = getState().clients;

  const isDirty = !objectsAreEqual(editClient, originalClient);

  await dispatch({
    type: CHECK_CLIENT_IS_DIRTY,
    isDirty
  });
};

export const loadClients = () => async dispatch => {
  dispatch(loadClientListRequested());
  try {
    const data = await fetchClientList();
    dispatch(loadClientListSuccess(data));
  }
  catch (err) {
    dispatch(loadClientListFailure(err));
  }
};

export const cancelChanges = () => async dispatch => {
  dispatch(cancelClientEdit());
};

export const addClient = () => async (dispatch, getState) => {
  const { editFormIsOpen } = getState().clients.editFormIsOpen;
  const newClient = createClient();

  await dispatch(addClientRequested(newClient));
  await dispatch(validateClient());
  if (!editFormIsOpen) {
    await dispatch(showEditForm(true));
  }
};

const showEditForm = showEditForm => ({
  type: SHOW_CLIENT_EDIT_FORM,
  showEditForm,
});

export const editClient = clientId => async dispatch => {

  dispatch(loadClientRequested());

  try {
    const client = await fetchClient(clientId);
    await dispatch(loadClientSuccess(client));

    if (client) {
      dispatch(editClientRequested());
    }
  }
  catch (err) {
    dispatch(loadClientFailure(err));
  }
};

export const saveClient = () => async (dispatch, getState) => {
  const { editClient, addAnother } = getState().clients;

  dispatch(saveClientRequested());

  try {
    const success = await persistClient(editClient);

    if (success) {
      dispatch(saveClientSuccess(editClient));
      if (addAnother) {
        dispatch(addClient());
      }
      else {
        dispatch(showEditForm(false));
      }
    }
    else {
      dispatch(saveClientFailure("Error saving client"));
    }
  }
  catch (err) {
    dispatch(saveClientFailure(err));
  }
};

const validateClient = () => async (dispatch, getState) => {
  const { editClient } = getState().clients;

  const isValid =
    !!editClient.clientName &&
    editClient.clientName.length > 0 &&
    !!editClient.clientType &&
    editClient.clientType.length > 0;

  dispatch(showClientIsValid(isValid));
};
