import { createStore, compose, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { createLogger  } from 'redux-logger';
import createHistory from 'history/createBrowserHistory';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootReducer from '../reducers';

export const history = createHistory();
const connectRouterHistory = connectRouter(history);

const persistConfig = {
  key: 'root',
  storage,
}

const logger = createLogger();

const persistedReducer = persistReducer(persistConfig, rootReducer)

function configureStoreProd() {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    thunk,
    reactRouterMiddleware
  ];

  return createStore(
    connectRouterHistory(persistedReducer),
    compose(applyMiddleware(...middlewares))
  );
}

function configureStoreDev() {
  const reactRouterMiddleware = routerMiddleware(history);

  const middlewares = [
    reduxImmutableStateInvariant(),
    thunk,
    reactRouterMiddleware,
    logger,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    connectRouterHistory(persistedReducer),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(
        connectRouterHistory(persistReducer(persistConfig, nextRootReducer)));
    });
  }

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;

