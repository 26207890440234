import React from "react";
import { string, bool, func, shape } from "prop-types";
import SearchItemImage from "./SearchItemImage";
import ProcessingButton from "../common/ProcessingButton";
import inventoryIdFormat from "../../tools/objectFormatters";

const searchItemStyle = {
  textAlign: "center",
  marginBottom: "35px"
};

const inventoryIdStyle = {
  fontWeight: "700",
  fontSize: "17px",
  marginTop: "15px"
};

const designerStyle = {
  fontSize: "15px"
};

const objectTypeStyle = {
  fontSize: "13px",
  color: "#707070",
  marginBottom: "15px",
  marginTop: "5px"
};

const SearchItem = ({
  item = {},
  requestObject,
  action,
  processing,
}) => {
  const {
    objectId = "",
    inventoryId = "",
    objectType = "",
    designer = ""
  } = item;

  let processingAction = "";
  if (action === "Request") {
    processingAction = "Requesting";
  }
  else if (action === "Remove") {
    processingAction = "Removing";
  }
  else if (action === "Save") {
    processingAction = "Saving";
  }
  else if (action === "Saved!") {
    processingAction === null;
  }

  const objectUrl = `/object/${objectId}`;

  return (
    <div className="search-item" style={searchItemStyle}>
      <SearchItemImage
        objectUrl={objectUrl}
        clientId={item.clientId}
        objectId={item.objectId}
        imageNumber={4}
      />

      <div style={inventoryIdStyle}>{inventoryIdFormat(inventoryId)}</div>
      <div style={designerStyle}>{designer}</div>
      <div style={objectTypeStyle}>{objectType}</div>

      <div>
        <ProcessingButton
          id={objectId}
          value={action}
          handleClick={requestObject}
          clientId={item.clientId}
          objectId={item.objectId}
          buttonLabel={action}
          processing={processing}
          processingLabel={processingAction}
          buttonStyle={
            action === "Request" || action === "Save"
              ? "requestLight"
              : "requestDark"
          }
          isDisabled={action === "Saved!"}
        />
      </div>
    </div>
  );
};

SearchItem.propTypes = {
  item: shape({
    objectId: string.isRequired,
    inventoryId: string.isRequired,
    objectType: string.isRequired,
    designer: string.isRequired
  }).isRequired,
  processing: bool.isRequired,
  action: string.isRequired,
  requestObject: func.isRequired,
};

export default SearchItem;
