import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';

const primaryFooterStyle = {
  width: '100%',
  height: '55px',
  backgroundColor: '#EBEBEB',
  textAlign: 'center',
  flexShrink: '0',
  position: 'fixed',
  bottom: '0'
}

const footerListStyle = {
  listStyleType: 'none',
  margin: '0 auto',
  paddingLeft: 0,
  marginTop: '16px',
}

const footerListFirstItemStyle = {
  display: 'inline',
  paddingLeft: '20px',
  paddingRight: '20px',
  fontFamily: 'proxima-nova',
  fontSize: '14px',
  color: '#707070',
}

const footerListItemStyle = {
  display: 'inline',
  paddingLeft: '20px',
  paddingRight: '20px',
  fontFamily: 'proxima-nova',
  fontSize: '14px',
  color: '#707070',
  borderLeft: '1px solid #707070',
}

const PrimaryFooter = () => (
  <div className="Footer" style={primaryFooterStyle}>
    <ul style={footerListStyle}>
      <li style={footerListFirstItemStyle}>
        ©
        {moment().year()}
        {' '}
        The Wardrobe
      </li>
      <li style={footerListItemStyle}><a href="mailto:support@thewardrobellc.com">Contact Us</a></li>
      <li style={footerListItemStyle}>
        <Link to="/terms-of-use">
          Terms of Use
        </Link>
      </li>
      <li style={footerListItemStyle}>
        <Link to="/privacy-policy">
          Privacy Policy
        </Link>
      </li>
    </ul>
  </div>
)

export default PrimaryFooter;
