import React from "react";
import { Link } from "react-router-dom";
import Menu from "react-burger-menu/lib/menus/slide";
import Select from "react-select";
import { Button, Navbar, NavbarBrand } from "reactstrap";
import { string, bool, shape, func, arrayOf } from "prop-types";
import logo from "../../assets/images/da-logo-white.png";
import digitalArchivistIcon from "../../assets/images/icons/Digital-Archivist-Icon.png";
import searchObjectsIcon from "../../assets/images/icons/Search-Objects-Icon.png";
import objectRequestsIcon from "../../assets/images/icons/Object-Requests-Icon.png";
import shipmentsIcon from "../../assets/images/icons/Shipments-Icon.png";
import shipmentLogIcon from "../../assets/images/icons/Shipping-Log-Icon.png";
import imageManagementIcon from "../../assets/images/icons/Img-Mgmt-Icon.png";
import checkInItemsIcon from "../../assets/images/icons/Check-In-Items-Icon.png";
import settingsIcon from "../../assets/images/icons/Settings-Icon.png";
import userMaintenanceIcon from "../../assets/images/icons/User-Maintenance-Icon.png";
import clientMaintenanceIcon from "../../assets/images/icons/Client-Maintenance-Icon.png";
import locationsIcon from "../../assets/images/icons/Locations-Icon.png";
import changePasswordIcon from "../../assets/images/icons/Change-Password-Icon.png";
import logOutIcon from "../../assets/images/icons/Log-Out-Icon.png";
import { NULL_UUID } from "../../api/constants";

import {
  appHeaderStyle,
  navbarContentsStyle,
  navbarBrandStyle,
  navbarLogoStyle,
  navbarInputStyle,
  burgerMenuStyles,
  menuUserIconStyle,
  menuUsernameStyle,
  menuIconStyle,
  menuLogoutStyle,
  menuLogoutButtonStyle,
  menuButtonStyle,
  subMenuButtonStyle,
  subMenuListItemStyle,
} from "../../styles/menuStyles";

const clientDropdownListStyle = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#FFF" : provided.color,
    backgroundColor: state.isSelected ? "#344D5D" : provided.color,
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#344D5D",
      opacity: 0.45
    }
  }),
  control: base => ({
    ...base,
    width: "419px",
    maxWidth: "419px",
    background: "black",
    borderColor: "gray",
    '&:hover': { borderColor: 'none' },
    boxShadow: "none"

  }),
  singleValue: provided => ({ ...provided, color: "white" })
};

const DigitalArchivistUserMenu = ({
  clients,
  selectedClient,
  userName,
  sideBarMenuIsOpen,
  handleChangeClient,
  isMenuOpen,
  signMeOut,
  closeSideNav,
  settingsSubMenuIsOpen,
  toggleSettingsSubMenu
}) => (
  <Navbar className="navbar-fixed-top" expand="md" style={appHeaderStyle}>
    <div style={navbarContentsStyle}>
      <NavbarBrand tag={Link} to="/" style={navbarBrandStyle}>
        <img src={logo} alt="The Digital Archivist" style={navbarLogoStyle} />
      </NavbarBrand>
      <div className="client-chooser-wrapper" style={navbarInputStyle}>
        <Select
          id="clients"
          name="clients"
          placeholder="SELECT CLIENT"
          value={{label:selectedClient.clientName, value: selectedClient.clientId}}
          onChange={(newValue, actionMeta) => handleChangeClient(newValue, actionMeta )}
          isSearchable
          options={clients.map(x => ({
            label: x.clientName,
            value: x.clientId
          }))}
          styles={clientDropdownListStyle}
        />
      </div>
      <div id="outer-container">
        <Menu
          right
          customCrossIcon={false}
          isOpen={sideBarMenuIsOpen}
          styles={burgerMenuStyles(sideBarMenuIsOpen || false)}
          pageWrapId="page-wrap"
          outerContainerId="outer-container"
          onStateChange={isMenuOpen}
        >
          <div>
            <Link to="/" className="menu-item" onClick={closeSideNav}>
              <img
                src={digitalArchivistIcon}
                alt="User Icon"
                style={menuUserIconStyle}
              />
              <span style={menuUsernameStyle}>{userName}</span>
            </Link>
          </div>
          <Link to="/" className="menu-item" onClick={closeSideNav}>
            <img
              src={searchObjectsIcon}
              alt="Search Objects"
              style={menuIconStyle}
            />
            Search Objects
          </Link>
          <Link to="/requests" className="menu-item" onClick={closeSideNav}>
            <img
              src={objectRequestsIcon}
              alt="Object Requests"
              style={menuIconStyle}
            />
            Object Requests
          </Link>
          <Link to="/shipments" className="menu-item" onClick={closeSideNav}>
            <img src={shipmentsIcon} alt="Shipments" style={menuIconStyle} />
            Shipments
          </Link>
          <Link to="/shipment-log" className="menu-item" onClick={closeSideNav}>
            <img
              src={shipmentLogIcon}
              alt="Shipment Log"
              style={menuIconStyle}
            />
            Shipment Log
          </Link>
          <Link to="/images" className="menu-item" onClick={closeSideNav}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img
              src={imageManagementIcon}
              alt="Image Management"
              style={menuIconStyle}
            />
            Image Management
          </Link>
          <Link to="/check-in" className="menu-item" onClick={closeSideNav}>
            <img
              src={checkInItemsIcon}
              alt="Check-In Objects"
              style={menuIconStyle}
            />
            Check-In Objects
          </Link>
          <button type="button" className="menu-item" onClick={toggleSettingsSubMenu} style={menuButtonStyle}>
            <img src={settingsIcon} alt="Settings" style={menuIconStyle} />
            Settings
          </button>
          <ul id="settings-sub-menu" style={{listStyleType: "none", paddingLeft: "30px", display: settingsSubMenuIsOpen ? 'block' : 'none'}}>
            <li style={subMenuListItemStyle}>
              <Link
                to="/system-users"
                className="menu-item"
                onClick={closeSideNav}
                style={subMenuButtonStyle}
              >
                <img
                  src={userMaintenanceIcon}
                  alt="System Users"
                  style={menuIconStyle}
                />
                User Maintenance
              </Link>
            </li>
            <li style={subMenuListItemStyle}>
              <Link
                to="/clients"
                className="menu-item"
                onClick={closeSideNav}
                style={subMenuButtonStyle}
              >
                <img
                  src={clientMaintenanceIcon}
                  alt="Clients"
                  style={menuIconStyle}
                />
                Client Maintenance
              </Link>
            </li>
            <li style={subMenuListItemStyle}>
              <Link
                to="/locations"
                className="menu-item"
                onClick={closeSideNav}
                style={subMenuButtonStyle}
              >
                <img src={locationsIcon} alt="Locations" style={menuIconStyle} />
                Locations
              </Link>
            </li>
          </ul>
          <Link
            to="/change-password"
            className="menu-item"
            onClick={closeSideNav}
          >
            <img
              src={changePasswordIcon}
              alt="Change Password"
              style={menuIconStyle}
            />
            Change Password
          </Link>
          <div style={menuLogoutStyle}>
            <Button
              onClick={signMeOut}
              color="link"
              style={menuLogoutButtonStyle}
            >
              <img src={logOutIcon} alt="Logout" style={menuIconStyle} />
              Logout
            </Button>
          </div>
        </Menu>
      </div>
    </div>
  </Navbar>
);

DigitalArchivistUserMenu.propTypes = {
  clients: arrayOf(
    shape({
      clientId: string,
      client: string
    })
  ).isRequired,
  selectedClient: shape({
    clientId: string,
    clientName: string,
  }),
  userName: string.isRequired,
  sideBarMenuIsOpen: bool,
  settingsSubMenuIsOpen: bool,
  handleChangeClient: func.isRequired,
  isMenuOpen: func.isRequired,
  signMeOut: func.isRequired,
  closeSideNav: func.isRequired,
  toggleSettingsSubMenu: func.isRequired,
};

DigitalArchivistUserMenu.defaultProps = {
  selectedClient: {
    clientId: NULL_UUID,
    clientName: ""
  },
  sideBarMenuIsOpen: false,
  settingsSubMenuIsOpen: false
};

export default DigitalArchivistUserMenu;
