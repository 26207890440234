import React, { Component } from 'react';
import { ConfirmSignIn, ConfirmSignUp, SignUp, withAuthenticator } from 'aws-amplify-react';
import { Container } from 'reactstrap';
import AppSignIn from './auth/AppSignIn';
import AppVerifyContact from './auth/AppVerifyContact';
import AppForgotPassword from './auth/AppForgotPassword';
import AppRequireNewPassword from './auth/AppRequireNewPassword';
import './App.css';
import PrimaryHeader from './common/PrimaryHeader';
import PrimaryBody from './common/PrimaryBody';
import PrimaryFooter from './common/PrimaryFooter';


const formsArray = [
  <AppSignIn key='SignIn' />,
  <ConfirmSignIn key='ConfirmSignIn' />,
  <AppVerifyContact key='VerifyContact' />,
  <SignUp key='SignUp' />,
  <ConfirmSignUp key='ConfirmSignUp' />,
  <AppForgotPassword key='ForgotPassword' />,
  <AppRequireNewPassword key='RequireNewPassword' />,
];

const appContainerStyle = {
  minHeight: '100vh',
  display: "flex",
  flexWrap: "wrap",
  backgroundColor: 'white',
  padding: 0,
  overflowX: 'hidden',
}

class App extends Component {

  render() {
    return (
      <Container id="app-container" fluid style={appContainerStyle}>
        <React.Fragment>
          <PrimaryHeader signOut={this.signOut} />
          <PrimaryBody />
          <PrimaryFooter />
        </React.Fragment>
      </Container>
    );
  }

}

export default withAuthenticator(App, false, formsArray);
