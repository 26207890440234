export const baseButtonStyle = {
  width: "115px",
  height: "45px",
  textTransform: "uppercase",
  borderRadius: "3px",
  border: "1px solid #A2A2A2",
  fontSize: "14px",
  marginRight: "15px"
};

export const lightButtonStyle = {
  ...baseButtonStyle,
  backgroundColor: "white",
  color: "#707070"
};

export const darkButtonStyle = {
  ...baseButtonStyle,
  backgroundColor: "#344D5D",
  color: "white"
};

export const requestLightButtonStyle = {
  ...baseButtonStyle,
  backgroundColor: "white",
  color: "#707070",
  marginRight: "0px"
};

export const requestDarkButtonStyle = {
  ...baseButtonStyle,
  backgroundColor: "#344D5D",
  color: "white",
  marginRight: "0px"
};
