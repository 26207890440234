import runwayImage from "../assets/images/runway.jpg";

export const authBackgroundStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  backgroundImage: `url(${runwayImage})`,
  backgroundSize: "cover"
};

export const authFormStyle = {
  alignItems: "center",
  backgroundColor: "white",
  minHeight: "400px",
  marginTop: "auto",
  marginBottom: "auto",
  width: "25vw",
  minWidth: "480px",
  fontFamily: "proxima-nova, regular",
  fontSize: "16px",
  color: "#838383",
  borderRadius: "3px",
  border: "1px solid #C9C9C9",
  paddingTop: "30px",
  paddingBottom: "30px",
  paddingLeft: "20px",
  paddingRight: "20px"
};

export const authFormTitleStyle = {
  width: "100%",
  textAlign: "center",
  marginBottom: "30px",
};

export const logoStyle = {
  maxWidth: "100%"
};

const authButtonStyle = {
  fontFamily: "proxima-nova",
  fontWeight: "400",
  fontSize: "13px",
  textTransform: "uppercase",
  width: "126px",
  height: "45px",
  marginTop: "30px",
  marginRight: "15px"
};

export const actionButtonStyle = {
  ...authButtonStyle,
  backgroundColor: "#344D5D",
};

export const cancelButtonStyle = {
  ...authButtonStyle,
  color: "#808080",
  backgroundColor: "white"
};
