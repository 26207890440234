import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, bool, arrayOf, shape } from "prop-types";
import AddNew from "./AddNew";
import SearchBar from "./SearchBar";
import AdminSearchResults from "./AdminSearchResults";
import { NULL_UUID } from "../../api/constants";
import {
  doSearch,
  changeSearchTerm,
  requestObject,
  removeObject,
} from "../../actions/searchActions";
import { addObject } from "../../actions/objectActions";
import EditObjectPage from "./EditObjectPage";
import NoClientSelectedPage from "../common/NoClientSelectedPage";

class AdminDashboard extends Component {
  componentDidMount = () => {
    const { searchTerm } = this.props;


    doSearch(searchTerm);
  };

  handleRequestObject = e => {
    e.preventDefault();

    const action = e.currentTarget.value;
    const objectId = e.currentTarget.id;

    const { requestObject, removeObject } = this.props;

    if (action === "Request") {
      requestObject(objectId);
    }
    else if (action === "Remove") {
      removeObject(objectId);
    }
  };

  handleChange = e => {
    const { changeSearchTerm } = this.props;
    changeSearchTerm(e.target.value);
  };

  handleSearch = e => {
    e.preventDefault();

    const { doSearch } = this.props;

    doSearch();
  };

  handleAddObject = () => {
    const { addObject } = this.props;
    addObject();
  };


  render() {
    const {
      firstName,
      items,
      tenantId,
      searchTerm,
      requestedObjects,
      processing,
      loading
    } = this.props;

    if (tenantId === NULL_UUID) {
      return <NoClientSelectedPage firstName={firstName} />;
    }
    else {
      return (
        <div className="home-page">
          <AddNew handleAddObjectClick={this.handleAddObject} />
          <SearchBar
            searchTerm={searchTerm}
            doSearch={this.handleSearch}
            handleChange={this.handleChange}
          />
          <AdminSearchResults
            items={items}
            requestedObjects={requestedObjects}
            requestObject={this.handleRequestObject}
            processing={processing}
          />
          <div className="Loading">
            {loading === true ? (
              <span>
                <i className="fa fa-spin fa-spinner" />
                Loading...
              </span>
            ) : null}
          </div>
          <EditObjectPage />
        </div>
      );
    }
  }
}

AdminDashboard.propTypes = {
  firstName: string,
  searchTerm: string.isRequired,
  doSearch: func.isRequired,
  tenantId: string.isRequired,
  items: arrayOf(
    shape({
      objectId: string,
      objectType: string,
      inventoryId: string,
      designer: string
    })
  ).isRequired,
  loading: bool.isRequired,
  requestedObjects: arrayOf(string).isRequired,
  processing: arrayOf(string).isRequired,
  addObject: func.isRequired,
  changeSearchTerm: func.isRequired,
  requestObject: func.isRequired,
  removeObject: func.isRequired,
};

AdminDashboard.defaultProps = {
  firstName: ""
};

const mapStateToProps = state => {
  return {
    userName: state.user.profile.name,
    firstName: state.user.profile.first_name,
    searchTerm: state.search.searchTerm,
    items: state.search.items,
    requestedObjects: state.search.requestedObjects,
    processing: state.search.processing,
    refreshObjectImages: state.search.refreshObjectImages,
    tenantId: state.system.tenantId,
    loading: state.search.loading
  };
};

export default connect(
  mapStateToProps,
  {
    doSearch,
    addObject,
    changeSearchTerm,
    requestObject,
    removeObject
  }
)(AdminDashboard);
