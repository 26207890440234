import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, bool, arrayOf, shape } from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap"
import SearchBar from "./SearchBar";
import ClientSearchResults from "./MyArchiveSearchResults";
import {
  doSearch,
  changeSearchTerm,

} from "../../actions/searchActions";
import { saveToMyArchive } from "../../actions/myArchiveActions";

const saveMyObjectStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '30px',
}

const saveMyObjectButtonStyle = {
  backgroundColor: '#344D5D',
  textTransform: 'uppercase',
  padding: '16px',
  fontFamily: 'proxima-nova',
  fontWeight: '600px',
}

const welcomeTextStyle = {
  color: '#a6a6a6',
  fontFamily: 'proxima-nova',
  fontSize: '20px',
  fontWeight: '600px',
  textTransform: 'uppercase',
  letterSpacing: '2px',
}


class MyArchivePage extends Component {
  componentDidMount = () => {
    const { searchTerm } = this.props;

    doSearch(searchTerm);
  };

  handleSaveToMyArchive = e => {
    e.preventDefault();

    const objectId = e.currentTarget.id;

    const { saveToMyArchive } = this.props;

    saveToMyArchive(objectId);

  };

  handleChange = e => {
    const { changeSearchTerm } = this.props;
    changeSearchTerm(e.target.value);
  };

  handleSearch = e => {
    e.preventDefault();

    const { doSearch } = this.props;

    doSearch();
  };


  render() {
    const {
      firstName,
      items,
      searchTerm,
      savedObjects,
      processing,
      loading
    } = this.props;

    return (
      <div className="my-archive">

        <div className="save-my-object" style={saveMyObjectStyle}>
          <div>
            <h3 style={welcomeTextStyle}>
              {`Welcome, ${firstName}.`}
            </h3>
            <h2>View Your Archive</h2>
          </div>
          <div>
            <Link to="/my-saved-objects">
              <Button style={saveMyObjectButtonStyle}>View Saved Objects</Button>
            </Link>
          </div>
        </div>
        <SearchBar
          searchTerm={searchTerm}
          doSearch={this.handleSearch}
          handleChange={this.handleChange}
        />
        <ClientSearchResults
          items={items}
          savedObjects={savedObjects}
          saveMyObject={this.handleSaveToMyArchive}
          processing={processing}
        />
        <div className="Loading">
          {loading === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Loading...
            </span>
          ) : null}
        </div>
      </div>
    );

  }
}

MyArchivePage.propTypes = {
  firstName: string,
  searchTerm: string.isRequired,
  doSearch: func.isRequired,
  items: arrayOf(
    shape({
      objectId: string,
      objectType: string,
      inventoryId: string,
      designer: string
    })
  ).isRequired,
  loading: bool.isRequired,
  savedObjects:arrayOf(
    shape({
      objectId: string,
      objectType: string,
      inventoryId: string,
      designer: string
    })
  ).isRequired,
  processing: arrayOf(string).isRequired,
  changeSearchTerm: func.isRequired,
  saveToMyArchive: func.isRequired,
};

MyArchivePage.defaultProps = {
  firstName: ""
};

const mapStateToProps = state => {
  return {
    userName: state.user.profile.name,
    firstName: state.user.profile.first_name,
    searchTerm: state.search.searchTerm,
    items: state.search.items,
    savedObjects: state.myArchive.savedObjects,
    processing: state.search.processing,
    tenantId: state.system.tenantId,
    loading: state.search.loading
  };
};

export default connect(
  mapStateToProps,
  {
    doSearch,
    saveToMyArchive,
    changeSearchTerm
  }
)(MyArchivePage);
