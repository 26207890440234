import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { Auth, Logger } from 'aws-amplify';
import { Container, Button, Form, FormGroup,  Col } from 'reactstrap';

const logger = new Logger('AppVerifyContact');


class AppVerifyContact extends Component {
  constructor(props){
    super(props);
    this.verifyContact = this.verifyContact.bind(this);
    this.state = { error: '' }
  }

  changeState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(state, data);
    }
  }

  verifyContact() {
    Auth.verifyCurrentUserAttributes("email")
        .then(() => {
            logger.info('a verification code is sent');
        }).catch((e) => {
            logger.info('failed with error', e);
        });
  }

  render() {
    const { authState } = this.props;

    if (!['verifyContact'].includes(authState)) {
      return null;
    }

    const { error } = this.state;
    logger.info(error);
    return (
      <Container className="VerifyContact">
        <h2>Verify Contact</h2>
        <Form>
          <Col>
            <FormGroup>
              You must verify your email address before logging in for the first time.
            </FormGroup>

          </Col>
          <Button outline color="primary" size="lg" onClick={this.verifyContact}>Verify</Button>
        </Form>
      </Container>
    )

  }
}

AppVerifyContact.propTypes = {
  authState: string,
  onStateChange: func,
}

AppVerifyContact.defaultProps = {
  authState: 'default',
  onStateChange: null,
}


export default AppVerifyContact;
