import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Table, Button, Alert } from "reactstrap";
import { bool, string, func } from "prop-types";
import FormTextInput from "../common/FormTextInput";
import FormSelectInput from "../main/components/FormSelectInput";

import {
  makeOptionListFromStringArray,
  makeOptionValueFromString
} from "../../tools/dropdownListUtils";


import { clientShape } from "../../models/client";
import {
  saveClient,
  cancelChanges,
  updateClientFieldValue,
  updateClientFormState
} from "../../actions/clientActions";
import {
  addUser,
  editUser,
  enableUser,
  disableUser
} from "../../actions/userActions";

import FormCheckBox from "../main/components/FormCheckBox";
import {
  editFormHeaderStyle,
  editFormHeaderCheckboxStyle,
  editFormHeaderActionButtonStyle,
  editFormHeaderCancelButtonStyle,
  editFormHeaderNoteStyle,
  editFormRequiredFieldIndicatorStyle,
  tableHeaderStyle,
  tableButtonStyle
} from "../../styles/formStyles";
import editIcon from "../../assets/images/edit-icon.png";

class EditClientPage extends Component {
  handleChange = e => {
    e.preventDefault();

    let value;
    if (event.target.type == "checkbox") {
      value = !!(event.target.type === "checked");
    }
    else {
      value = event.target.value;
    }

    const { updateClientFieldValue } = this.props;
    updateClientFieldValue(e.target.id, value);
  };

  handleSelectChange = (newValue, actionMeta) => {
    const action = actionMeta.action;
    const name = actionMeta.name;

    let value;

    if (action === "clear") {
      value = "";
    }
    else if (action === "select-option") {
      value = newValue.value;
    }
    else {
      return;
    }

    const { updateClientFieldValue } = this.props;
    updateClientFieldValue(name, value);
  };

  handleFormStateChange = event => {
    const { updateClientFormState } = this.props;
    updateClientFormState(event.target.id, !!event.target.value);
  };

  handleSave = e => {
    e.preventDefault();
    const { saveClient } = this.props;
    saveClient();
  };

  handleCancel = e => {
    e.preventDefault();

    const { cancelChanges } = this.props;
    cancelChanges();
  };

  handleAddUser = e => {
    e.preventDefault();

    const { addUser } = this.props;
    addUser();
  };

  handleEditUser = userId => {

    const {
      editUser,
      client
    } = this.props;
    editUser(userId, client.clientId);
  };

  changeUserStatus = user => {
    const { disableUser, enableUser } = this.props;

    if (user.status === "Active") {
      disableUser(user);
    }
    else if (user.status === "Disabled") {
      enableUser(user);
    }
  }

  render = () => {
    const {
      action,
      editFormIsOpen,
      isValid,
      isDirty,
      client,
      saveSuccess,
      saving,
      addAnother
    } = this.props;

    return (
      <ReactModal isOpen={editFormIsOpen} ariaHideApp={false}>
        <Alert color="sucess" isOpen={saveSuccess}>
          Save Successful
        </Alert>
        <Alert color="dangery" isOpen={false}>
          Error Saving
        </Alert>

        <div className="editClientPages">
          <Form>
            <Container
              fluid
              className="form-header"
              style={editFormHeaderStyle}
            >
              <Row>
                <Col xs="3" style={editFormHeaderCheckboxStyle}>
                  <FormCheckBox
                    id="addAnother"
                    label="Add Another"
                    checked={addAnother}
                    handleChange={this.handleFormStateChange}
                  />
                </Col>
                <Col xs="3">
                  <Button
                    onClick={this.handleCancel}
                    style={editFormHeaderCancelButtonStyle}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    onClick={this.handleAddUser}
                    disabled={!isValid}
                    style={editFormHeaderActionButtonStyle}
                  >
                    Add User
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={this.handleSave}
                    style={editFormHeaderActionButtonStyle}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Container>

            <div className="form-header-title">
              <h2>{action}</h2>
              <div className="title-note" style={editFormHeaderNoteStyle}>
                <span
                  className="required-field-indicator"
                  style={editFormRequiredFieldIndicatorStyle}
                >
                  *
                </span>
                Indicates required field
              </div>
            </div>

            <FormTextInput
              label="Client"
              id="clientName"
              required
              value={client.clientName}
              handleChange={this.handleChange}
            />

            <FormSelectInput
              label="Client Type"
              id="clientType"
              required
              options={makeOptionListFromStringArray(["Celebrity", "Corporate", "Private"])}
              value={makeOptionValueFromString(client.clientType)}
              handleChange={(newValue, actionMeta) => this.handleSelectChange(newValue, actionMeta)}
            />
          </Form>
          <Table responsive>
            <thead style={tableHeaderStyle}>
              <tr>
                <th>Name</th>
                <th>Email/Login</th>
                <th>{null}</th>
                <th>{null}</th>
              </tr>
            </thead>
            <tbody>
              {client.users.map(user => (
                <tr key={user.userId}>
                  <td>{user.fullName}</td>
                  <td>{user.email}</td>
                  <td>
                    <Button
                      style={tableButtonStyle}
                      onClick={e => {
                        e.preventDefault();
                        this.changeUserStatus(user)
                      }}
                    >
                      {user.status === "Active"
                        ? "Disable"
                        : "Enable"
                      }
                    </Button>
                  </td>
                  <td>
                    <div
                      className="editButton"
                      role="button"
                      aria-hidden
                      style={tableButtonStyle}
                      onClick={() => {
                        this.handleEditUser(user.userId);
                      }}
                    >
                      <img src={editIcon} alt="Edit User" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="Loading">
          {saving === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Saving...
            </span>
          ) : null}
        </div>
      </ReactModal>
    );
  };
}

EditClientPage.propTypes = {
  action: string.isRequired,
  editFormIsOpen: bool.isRequired,
  isValid: bool.isRequired,
  isDirty: bool.isRequired,
  saving: bool.isRequired,
  addAnother: bool.isRequired,
  saveSuccess: bool.isRequired,
  client: clientShape.isRequired,
  updateClientFieldValue: func.isRequired,
  saveClient: func.isRequired,
  cancelChanges: func.isRequired,
  updateClientFormState: func.isRequired,
  addUser: func.isRequired,
  editUser: func.isRequired,
  disableUser: func.isRequired,
  enableUser: func.isRequired,
};

const mapStateToProps = state => ({
  addAnother: state.clients.addAnother,
  action: state.clients.action,
  editFormIsOpen: state.clients.editFormIsOpen,
  isValid: state.clients.isValid,
  isDirty: state.clients.isDirty,
  saveSuccess: state.clients.saveSuccess,
  client: state.clients.editClient,
  saving: state.clients.isSaving,
  statuses: state.system.lists["clientStatuses"]
});

export default connect(
  mapStateToProps,
  {
    updateClientFieldValue,
    updateClientFormState,
    saveClient,
    cancelChanges,
    addUser,
    editUser,
    enableUser,
    disableUser
  }
)(EditClientPage);
