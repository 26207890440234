import moment from "moment";
import { doFetch } from "./index";

export const fetchObjectsWithoutPrimaryImage = async (fromDate, toDate, year, season, division, clientId) => {

  if (!clientId || !fromDate || !toDate) {
    return [];
  }

  const path = `/missing-images/?clientId=${clientId}&fromDate=${moment(fromDate).format(
    "YYYY-MM-DD"
  )}&toDate=${moment(toDate).format("YYYY-MM-DD")}&year=${year}&season=${season}&division=${division}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response.sort((a, b) => {
    if (a.dateCreated > b.dateCreated) return 1;
    if (a.dateCreated < b.dateCreated) return -1;
    return 0;
  });

};

export const persistSaveImage = async ()  => {

}
