import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, arrayOf, shape, bool } from "prop-types";
import { Table, Input, Label, Col, Row, Button } from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  loadShipmentLogs,
  addShipmentLog,
  editShipmentLog,
  changeShipmentLogFilter
} from "../../actions/shipmentLogActions";
import { NULL_UUID } from "../../api/constants";
import EditShipmentLogPage from "./EditShipmentLogPage";
import { shipmentLogShape } from "../../models/shipmentLog";
import editIcon from "../../assets/images/edit-icon.png";
import {
  tableHeaderStyle,
  tableButtonStyle,
  filterPageHeaderStyle,
  filterPageActionButtonStyle,
  filterHeaderStyle,
  dateInputFilterStyle,
  dateSplitterStyle,
  filterLabelStyle
} from "../../styles/formStyles";

class ShipmentLogsPage extends Component {
  componentDidMount = () => {
    const { filter, loadShipmentLogs } = this.props;
    loadShipmentLogs(filter.fromDate, filter.toDate);
  };

  handleFilterChange = e => {
    e.preventDefault();
    const { changeShipmentLogFilter } = this.props;
    changeShipmentLogFilter(e.target.id, e.target.value);
  };

  handleDateFilterChange = (id, date) => {
    const { changeShipmentLogFilter } = this.props;
    changeShipmentLogFilter(id, date.toISOString().slice(0, 10));
  };

  handleEdit = shipmentLogId => {
    const { editShipmentLog } = this.props;
    editShipmentLog(shipmentLogId);
  };

  handleAddShipmentLog = e => {
    e.preventDefault();

    const { addShipmentLog } = this.props;
    addShipmentLog();
  };

  render() {
    const {
      userRole,
      filter,
      shipmentLogs,
      staffList,
      clientList,
      shipmentLogStatusList,
      loading
    } = this.props;

    if (
      !(
        userRole === "System Administrator" ||
        userRole === "Digital Archivist Administrator" ||
        userRole === "Digital Archivist User"
      )
    ) {
      return (
        <div>
          <h2>Unauthorized</h2>
          Please contact the system administrator.
        </div>
      );
    }

    return (
      <div className="shipmentLogs">
        <div className="shipment-logs-header" style={filterPageHeaderStyle}>
          <h2>Shipment Logs</h2>
          <div>
            <Button
              onClick={this.handleAddShipmentLog}
              style={filterPageActionButtonStyle}
            >
              Add Shipment Log
            </Button>
          </div>
        </div>

        <div className="shipmentLogs-filter" style={filterHeaderStyle}>
          <Row>
            <Col sm={4}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Date Shipped
              </Label>
              <DatePicker
                id="fromDate"
                selected={moment(filter.fromDate).toDate()}
                onChange={date => this.handleDateFilterChange("fromDate", date)}
                style={dateInputFilterStyle}
              />
              <div className="dash" style={dateSplitterStyle}>
                -
              </div>

              <DatePicker
                id="toDate"
                selected={moment(filter.toDate).toDate()}
                onChange={date => this.handleDateFilterChange("toDate", date)}
                style={dateInputFilterStyle}
              />
            </Col>
            <Col sm={3}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Staff
              </Label>
              <Input
                type="select"
                id="staff"
                value={filter.staff}
                onChange={this.handleFilterChange}
              >
                <option key="-999" value="">
                  ALL USERS
                </option>
                {staffList.map(staff => (
                  <option key={staff.userId} value={staff.userId}>
                    {staff.userName}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm={3}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Client
              </Label>
              <Input
                type="select"
                id="clientId"
                value={filter.clientId}
                onChange={this.handleFilterChange}
              >
                <option key="-999" value={NULL_UUID}>
                  ALL CLIENTS
                </option>
                {clientList.map(x => (
                  <option key={x.clientName} value={x.clientName}>
                    {x.clientName}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm={2}>
              <Label className="small-filter-label" style={filterLabelStyle}>
                Filter by Status
              </Label>
              <Input
                type="select"
                id="status"
                value={filter.status}
                onChange={this.handleFilterChange}
              >
                <option key="-999" value="">
                  ALL STATUSES
                </option>
                {shipmentLogStatusList.map(status => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </div>

        <div className="shipmentLogs-table">
          <Table responsive>
            <thead style={tableHeaderStyle}>
              <tr>
                <th>Client</th>
                <th>Sender</th>
                <th>Carrier</th>
                <th>Tracking #</th>
                <th>Date Shipped</th>
                <th>Date Received</th>
                <th>Status</th>
                <th>{null}</th>
              </tr>
            </thead>
            <tbody>
              {shipmentLogs
                .filter(
                  item =>
                    (item.clientId === filter.clientId ||
                      filter.clientId === NULL_UUID) &&
                    (item.createdBySub === filter.staff || filter.staff === "") &&
                    (item.status === filter.status || filter.status === "")
                )
                .sort((a, b) => {
                  if (a.dateShipped > b.dateShipped) return 1;
                  if (a.dateShipped < b.dateShipped) return -1;
                  return 0;
                })
                .map(item => (
                  <tr key={item.shipmentLogId}>
                    <td>{item.clientName}</td>
                    <td>{item.sender}</td>
                    <td>{item.carrier}</td>
                    <td>{item.trackingNumber}</td>
                    <td>{item.dateShipped}</td>
                    <td>{item.dateReceived}</td>
                    <td>{item.status}</td>
                    <td>
                      <div
                        className="editButton"
                        role="button"
                        aria-hidden
                        style={tableButtonStyle}
                        onClick={e => {
                          e.preventDefault();
                          this.handleEdit(item.shipmentLogId);
                        }}
                      >
                        <img src={editIcon} alt="Edit Shipment log" />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="Loading">
          {loading === true ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              Loading...
            </span>
          ) : null}
        </div>
        <EditShipmentLogPage />
      </div>
    );
  }
}

ShipmentLogsPage.propTypes = {
  userRole: string.isRequired,
  clientList: arrayOf(
    shape({
      tenantId: string,
      client: string
    })
  ).isRequired,
  filter: shape({
    fromDate: string,
    toDate: string,
    staff: string,
    clientId: string
  }).isRequired,
  loading: bool.isRequired,
  shipmentLogStatusList: arrayOf(string).isRequired,
  staffList: arrayOf(
    shape({
      userId: string,
      userName: string,
    })
  ).isRequired,
  shipmentLogs: arrayOf(shipmentLogShape).isRequired,
  changeShipmentLogFilter: func.isRequired,
  loadShipmentLogs: func.isRequired,
  addShipmentLog: func.isRequired,
  editShipmentLog: func.isRequired
};

const mapStateToProps = state => {
  return {
    userRole: state.user.profile.role,
    filter: state.shipmentLog.filter,
    shipmentLogs: state.shipmentLog.shipmentLogs,
    clientList: state.system.lists["clients"],
    shipmentLogStatusList: state.system.lists["shipmentStatuses"],
    staffList: state.system.lists["staff"],
    loading: state.shipmentLog.loading
  };
};

export default connect(
  mapStateToProps,
  {
    loadShipmentLogs,
    addShipmentLog,
    editShipmentLog,
    changeShipmentLogFilter
  }
)(ShipmentLogsPage);
