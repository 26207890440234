import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, arrayOf, shape } from "prop-types";
import { Button, Container, Row, Col } from "reactstrap";
import { filterPageActionButtonStyle } from "../../styles/formStyles";
import MySavedObjectsList from "./MySavedObjectList";
import {
  getMyArchive,
  removeFromMyArchive,
  removeAllFromMyArchive
} from "../../actions/myArchiveActions";

const headerTextStyle = {
  fontFamily: "proxima-nova",
  fontSize: "17px",
  color: "#1D1D1D",
  marginBottom: "47px",
};

const headerTextLinkStyle = {
  fontWeight: 600,
  textDecoration: "underline",
  color: "#1D1D1D"
};

const headerStyle = {
  marginBottom: 0,
}

const mySavedObjectsStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "39px"
};

class MySavedObjectsPage extends Component {
  componentDidMount = () => {
    const { getMyArchive } = this.props;
    getMyArchive();
  };

  handleRemoveFromMyArchive = objectId => {
    const { removeFromMyArchive } = this.props;

    removeFromMyArchive(objectId);
  };

  handleRemoveAllFromMyArchive = () => {
    const { removeAllFromMyArchive } = this.props;
    removeAllFromMyArchive();
  };

  render() {
    const { savedObjects, clientId } = this.props;

    return (
      <div className="my-archive">
        <div className="my-saved-objects" style={mySavedObjectsStyle}>
          <div>
            <h2 style={headerStyle}>Your Saved Objects</h2>
          </div>
          <div>
            <Button
              onClick={this.handleRemoveAllFromMyArchive}
              style={filterPageActionButtonStyle}
            >
              Remove All Objects
            </Button>
          </div>
        </div>
        <Container fluid className="p-0">
          <Row>
            <Col xs={12} sm={9}>
              <p style={headerTextStyle}>
                To request items, please contact
                {" "}
                <a
                  style={headerTextLinkStyle}
                  href="mailto:requests@thewardrobellc.com"
                >
                  requests@thewardrobellc.com
                </a>
                {" "}
                along with your shipping address and product ID numbers. We will
                respond to your request within 24 hours.
              </p>
            </Col>
          </Row>
        </Container>
        <MySavedObjectsList
          savedObjects={savedObjects}
          removeObject={this.handleRemoveFromMyArchive}
          clientId={clientId}
        />
      </div>
    );
  }
}

MySavedObjectsPage.propTypes = {
  savedObjects: arrayOf(
    shape({
      objectId: string,
      objectType: string,
      inventoryId: string,
      designer: string
    })
  ).isRequired,
  clientId: string.isRequired,
  getMyArchive: func.isRequired,
  removeFromMyArchive: func.isRequired,
  removeAllFromMyArchive: func.isRequired
};

const mapStateToProps = state => {
  return {
    savedObjects: state.myArchive.savedObjects,
    clientId: state.system.tenantId
  };
};

export default connect(
  mapStateToProps,
  {
    getMyArchive,
    removeFromMyArchive,
    removeAllFromMyArchive
  }
)(MySavedObjectsPage);
