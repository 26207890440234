import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Amplify from 'aws-amplify';
import { persistStore } from 'redux-persist';
import AmplifyBridge from './tools/AmplifyBridge';
import config from "../awsconfig/aws-exports";
import configureStore, { history } from './store/configureStore';
import Root from './modules/Root';


//require('./favicon.ico'); // Tell webpack to load favicon.ico
const store = configureStore();
new AmplifyBridge(store);

const persistor = persistStore(store);

Amplify.Logger.LOG_LEVEL = 'INFO'; // We write INFO level logs throughout app

Amplify.configure(config);

render(
  <AppContainer>
    <Root store={store} history={history} persistor={persistor} />
  </AppContainer>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept('./modules/Root', () => {
    const NewRoot = require('./modules/Root').default;
    render (
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('root')
    );
  });
}
