import React, { Component } from "react";
import { connect } from "react-redux";
import { string, bool, func, shape, arrayOf } from "prop-types";
import { Container, Row, Col, Button, Table } from "reactstrap";
import { Link } from "react-router-dom";
import {
  loadObject,
  editObject,
  clearObject,
  refreshObjectImageSuccess
} from "../../actions/objectActions";
import ObjectViewRow from "./components/ObjectViewRow";
import ViewObjectImagePanel from "./ViewObjectImagePanel";
//import RelatedObjectsView from './components/RelatedObjectsView';
import EditObjectPage from "./EditObjectPage";
import ViewRelatedItemImage from "./ViewRelatedItemImage";
import ViewCheckout from "./ViewCheckout";
import ViewCheckin from "./ViewCheckin";
import NotePanel from "./NotePanel";
import ViewObjectTags from "./ViewObjectTags";
import archiveObjectPropType from "../../models/archiveObjectPropType";
import { ImageNumber } from "../../api/constants";
import { fetchImage, downloadOriginalImage } from "../../api/imagesApi";
import { viewHeaderButtonStyle, urlLinkStyle, hiddenButtonStyle } from "../../styles/formStyles";
import inventoryIdFormat from "../../tools/objectFormatters";
import { isEmpty } from "../../tools/dataValidator";
import {
  backToSearchResultsStyle,
  viewObjectRowStyle,
  viewObjectTitleStyle,
  notesColStyle,
  notesLabelStyle,
  relatedItemsRowStyle

} from "../../styles/viewObjectStyles";
import { clientShape } from "../../models/client";
import { loadClients } from "../../actions/clientActions";

let labelPrinter;

const hiddenObjectRowStyle = {
  display: "none"
}

class ViewObjectPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image1: null,
      image2: null,
      thumbnailNumber: 1
    };
  }

  componentDidMount = () => {
    const { loadClients } = this.props;
    loadClients();
  }

  componentWillUnmount = () => {
    const { clearObject } = this.props;

    clearObject();
  };

  sleep = time => {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  handleEditObject = e => {
    e.preventDefault();

    const { editObject } = this.props;
    editObject();
  };

  handlePrintBarcode = (object, e) => {
    e.preventDefault();
    if (object.inventoryId === null) {
      alert("Object Inventory Id is not properly set.  Please refresh the page and try again.");
      return;
    }

    const printScript = document.getElementById("barcodeScriptId");

    if (!printScript) {
      const script = document.createElement("script");
      //script.src = "https://digitalarchivist.com/BrowserPrint-2.0.0.75.min.js";
      script.src = "https://s3.amazonaws.com/digitalarchivist.com/BrowserPrint-2.0.0.75.min.js";
      script.id = "barcodeScriptId";
      document.body.appendChild(script);

      script.onload = () => {
        this.printBarcode(object);
      };
    }

    if (printScript) {
      this.printBarcode(object);
    }
  };

  printBarcode = object => {
    const { clients, clientId } = this.props;
    const client = clients.find(x => x.clientId === clientId)
    let clientType = "";
    if (client) {
      clientType = client.clientType;
    }

    let objectDescription = "";
    if (clientType === "Corporate") {
      objectDescription = `${object.season || ""} - ${object.lookNumber || ""} - ${object.year || ""}`;
    }
    else if (clientType === "Celebrity") {
      objectDescription =  `${object.designer || ""} - ${object.event || ""}`;
    }
    else {
      objectDescription = object.designer || "";
    }

    const dataToWrite = `
    ^XA
    ^FO50,20^ADN,12,12^FH^FD${objectDescription}^FS
    ^FO50,50^BY2
    ^BCN,100,Y,N,N
    ^FD>:${inventoryIdFormat(object.inventoryId)}^FS
    ^XZ
    `;

    this.printLabel(dataToWrite);
  };

  printLabel = dataToWrite => {
    /* eslint-disable-next-line no-undef */
    BrowserPrint.getDefaultDevice(
      "printer",
      function(device) {
        //Add device to list of devices and to html select element
        /* eslint-disable-next-line no-undef */
        labelPrinter = device;
        /* eslint-disable-next-line no-undef */
        labelPrinter.send(dataToWrite, undefined, function(error) {
          alert(error);
        });
      },
      function(error) {
        alert(error);
      }
    );
  };

  hideField = value => {
    const { role } = this.props;
    return role === "User" && isEmpty(value);
  };

  hideFieldOnClientPortal = () => {
    const { role } = this.props;
    return role === "User";
  };

  handleThumbnailClick = thumbnailNumber => {
    this.setState({
      thumbnailNumber: thumbnailNumber
    });
  };

  handleDownloadImage = async () => {
    const { thumbnailNumber } = this.state;

    const { object, clientId } = this.props;

    await downloadOriginalImage(
      clientId,
      object.objectId,
      object.inventoryId,
      thumbnailNumber
    );
  };

  doReloadObject = async () => {
    const {
      match: { params },
      loadObject
    } = this.props;

    await loadObject(params.objectId);

    this.setState({
      thumbnailNumber: 1
    });

    await this.getObjectImages();
  };

  getObjectImages = async () => {
    const { object } = this.props;

    if (object.primaryImage !== null) {
      let objImage1 = null;

      for (let i = 0; i < 5; i++){
        objImage1 = await fetchImage(
          object.clientId,
          object.objectId,
          ImageNumber.ViewPrimaryImage
        );

        if (objImage1 !== null) {
          break;
        }
        else {
          await this.sleep(1000);
        }

      }

      if (objImage1 !== null) {
        this.setState({
          image1: `data:image/jpeg;base64,${btoa(
            String.fromCharCode(...objImage1.Body.data)
          )}`
        });
      }
      else {
        this.setState({
          image1: null
        });
      }


    }
    else {
      this.setState({
        image1: null
      });
    }

    if (object.secondaryImage !== null) {
      let objImage2 = null;

      for (let i = 0; i < 5; i++){
        objImage2 = await fetchImage(
          object.clientId,
          object.objectId,
          ImageNumber.ViewSecondaryImage
        );

        if (objImage2 == null) {
          break;
        }
        else {
          await this.sleep(1000);
        }

      }

      if (objImage2 !== null) {
        this.setState({
          image2: `data:image/jpeg;base64,${btoa(
            String.fromCharCode(...objImage2.Body.data)
          )}`
        });
      }
      else {
        this.setState({
          image2: null
        });
      }
    }
  };

  render() {
    const {
      object,
      reloadObject,
      reloadImages,
      refreshObjectImageSuccess
    } = this.props;
    const { image1, image2, thumbnailNumber } = this.state;

    if (reloadObject) {
      this.doReloadObject();
    }
    else if (reloadImages) {

      this.sleep(2000)
        .then(() => {
          this.getObjectImages();
          refreshObjectImageSuccess();
        });


    }

    return (
      <Container fluid>
        <Row>
          <Col sm={this.hideFieldOnClientPortal() ? 12 : 5} style={backToSearchResultsStyle}>
            <Link to="/" className="url-link" style={urlLinkStyle}>
              {"< Back to Search Results"}
            </Link>
          </Col>

          <Col sm={2} style={this.hideFieldOnClientPortal() ? hiddenObjectRowStyle : viewObjectRowStyle}>
            <Button
              style={this.hideFieldOnClientPortal() ? hiddenButtonStyle : viewHeaderButtonStyle}
              onClick={this.handleEditObject}
            >
              Edit Object
            </Button>
          </Col>

          <Col sm={2} style={this.hideFieldOnClientPortal() ? hiddenObjectRowStyle : viewObjectRowStyle}>
            <Button
              style={this.hideFieldOnClientPortal() ? hiddenButtonStyle : viewHeaderButtonStyle}
              disabled={object.inventoryId === null}
              onClick={e =>
                this.handlePrintBarcode(
                  object,
                  e
                )
              }
            >
              Print Barcode
            </Button>
          </Col>
          
          {this.hideFieldOnClientPortal() ? undefined : <Col sm={2} />} 

          <ViewObjectImagePanel
            image1={image1}
            image2={image2}
            inventoryId={object.inventoryId}
            thumbnailNumber={thumbnailNumber}
            handleThumbnailClick={this.handleThumbnailClick}
            downloadImage={this.handleDownloadImage}
          />

          <Col sm={7}>
            <h2 style={viewObjectTitleStyle}>
              {inventoryIdFormat(object.inventoryId)}
            </h2>
            <Table>
              <tbody>
                <ObjectViewRow
                  label="Status"
                  value={object.flagForMissingInfo}
                  highlighted
                  hidden={this.hideFieldOnClientPortal(
                    object.flagForMissingInfo
                  )}
                />
                <ObjectViewRow
                  label="Location"
                  value={object.location}
                  hidden={this.hideField(object.location)}
                />
                <ObjectViewRow
                  label="Object Type"
                  value={object.objectType}
                  hidden={this.hideField(object.objectType)}
                />
                <ObjectViewRow
                  label="Designer"
                  value={object.designer}
                  hidden={this.hideField(object.designer)}
                />
                <ObjectViewRow
                  label="Secondary Line"
                  value={object.secondaryLine}
                  hidden={this.hideField(object.secondaryLine)}
                />
                <ObjectViewRow
                  label="Division"
                  value={object.division}
                  hidden={this.hideField(object.division)}
                />
                <ObjectViewRow
                  label="Year"
                  value={object.year}
                  hidden={this.hideField(object.year)}
                />
                <ObjectViewRow
                  label="Season"
                  value={object.season}
                  hidden={this.hideField(object.season)}
                />
                <ObjectViewRow
                  label="Look Number"
                  value={object.lookNumber}
                  hidden={this.hideField(object.lookNumber)}
                />
                <ObjectViewRow
                  label="Internal Reference Number"
                  value={object.internalReferenceNumber}
                  hidden={this.hideField(object.internalReferenceNumber)}
                />
                <ObjectViewRow
                  label="Primary Color"
                  value={object.primaryColor}
                  hidden={this.hideField(object.primaryColor)}
                />
                <ObjectViewRow
                  label="Secondary Colors"
                  value={object.secondaryColors.join(", ")}
                  hidden={this.hideField(object.secondaryColors)}
                />
                <ObjectViewRow
                  label="Material"
                  value={object.material}
                  hidden={this.hideField(object.material)}
                />
                <ObjectViewRow
                  label="Description"
                  value={object.description}
                  hidden={this.hideField(object.description)}
                />
                <ObjectViewRow
                  label="Event"
                  value={object.event}
                  hidden={this.hideField(object.event)}
                />
                <ObjectViewRow
                  label="Event Date"
                  value={object.eventDate}
                  hidden={this.hideField(object.eventDate)}
                />
                <ObjectViewRow
                  label="Editor / Stylist"
                  value={object.stylistEditor}
                  hidden={this.hideField(object.stylistEditor)}
                />
                <ObjectViewRow
                  label="Size"
                  value={object.size}
                  hidden={this.hideField(object.size)}
                />
              </tbody>
            </Table>
          </Col>

          <Col sm={12} style={notesColStyle}>
            <NotePanel
              label="Archive Notes:"
              value={object.archiveNotes}
              hidden={this.hideField(object.archiveNotes)}
              styleName="archiveNote"
            />
            <NotePanel
              label="Conservation Notes:"
              value={object.conservationNotes}
              hidden={this.hideField(object.conservationNotes)}
              styleName="note"
            />
            <ViewObjectTags
              tags={object.tags}
              hideOnClientPortal={this.hideFieldOnClientPortal()}
            />
            {/* <div style={noteRowStyle}>
              <div style={notesLabelStyle}>Tags:</div>
              <div style={tagsValueStyle}>
                {object.tags
                  .map(
                    s =>
                      s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()
                  )
                  .join(", ")}
              </div>
            </div> */}
            <div style={relatedItemsRowStyle}>
              <div style={notesLabelStyle}>Related Items:</div>

              {object.relatedObjects.map(obj => (
                <ViewRelatedItemImage
                  key={obj.objectId}
                  clientId={object.clientId}
                  objectId={obj.objectId}
                  designer={obj.designer}
                  inventoryId={inventoryIdFormat(obj.inventoryId)}
                  imageNumber={ImageNumber.SearchImage}
                  allowLink
                />
              ))}
            </div>
          </Col>

          <Col sm={12}>
            <h2 style={{ marginBottom: "36px" }}>Loan History</h2>
            {object.history
              .sort((a, b) => {
                if (a.sortDate > b.sortDate) return 1;
                if (a.sortDate < b.sortDate) return -1;
                return 0;
              })
              .map((item, index) => {
                if (item.type === "shipment") {
                  return (
                    <ViewCheckout
                      key={item.shipmentId}
                      shipment={item}
                      hideDivider={index === 0}
                    />
                  );
                }
                else {
                  return (
                    <ViewCheckin
                      key={item.checkInId}
                      checkinBy={item.createdBy}
                      checkinDate={item.checkInDate}
                      location={item.locationName}
                    />
                  );
                }
              })}
          </Col>
        </Row>
        <EditObjectPage image1={image1} image2={image2} />
      </Container>
    );
  }
}

ViewObjectPage.propTypes = {
  match: shape({
    params: shape({
      objectId: string.isRequired
    }).isRequired
  }).isRequired,
  object: shape(archiveObjectPropType).isRequired,
  role: string.isRequired,
  loadObject: func.isRequired,
  editObject: func.isRequired,
  clearObject: func.isRequired,
  loadClients: func.isRequired,
  refreshObjectImageSuccess: func.isRequired,
  reloadObject: bool,
  reloadImages: bool.isRequired,
  clientId: string.isRequired,
  clients: arrayOf(clientShape).isRequired,
};

ViewObjectPage.defaultProps = {
  reloadObject: false
};

const mapStateToProps = state => {
  return {
    role: state.user.profile.role,
    object: state.object.viewObject,
    reloadObject: state.object.reloadObject,
    reloadImages: state.object.reloadImages,
    clientId: state.system.tenantId,
    clients: state.clients.clients,
  };
};

export default connect(
  mapStateToProps,
  {
    loadObject,
    editObject,
    clearObject,
    refreshObjectImageSuccess,
    loadClients
  }
)(ViewObjectPage);
