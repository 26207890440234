import { loadUserSuccess, showEditForm } from "./userActions";
import { fetchSystemUsers } from "../api/userApi";

export const LOAD_SYSTEMUSER_LIST_REQUESTED = "LOAD_SYSTEMUSER_LIST_REQUESTED";
export const LOAD_SYSTEMUSER_LIST_SUCCESS = "LOAD_SYSTEMUSER_LIST_SUCCESS";
export const LOAD_SYSTEMUSER_LIST_FAILURE = "LOAD_SYSTEMUSER_LIST_FAILURE";
export const CHANGE_SYSTEM_USER_FILTER = "CHANGE_SYSTEM_USER_FILTER";

export const EDIT_SYSTEMUSER_REQUESTED = "EDIT_SYSTEMUSER_REQUESTED";


const loadSystemUserListRequested = () => ({
  type: LOAD_SYSTEMUSER_LIST_REQUESTED
});

const loadSystemUserListSuccess = clients => ({
  type: LOAD_SYSTEMUSER_LIST_SUCCESS,
  payload: clients
});

const loadSystemUserListFailure = error => ({
  type: LOAD_SYSTEMUSER_LIST_FAILURE,
  payload: error
});

const changeSystemUserFilterRequested = (id, value) => ({
  type: CHANGE_SYSTEM_USER_FILTER,
  id,
  value
});

export const changeSystemUserFilter = (id, value) => dispatch => {
  dispatch(changeSystemUserFilterRequested(id, value));
};


export const loadSystemUserList = () => async dispatch => {
  dispatch(loadSystemUserListRequested());

  try {
    const data = await fetchSystemUsers();
    dispatch(loadSystemUserListSuccess(data));
  }
  catch (err) {
    dispatch(loadSystemUserListFailure(err));
  }
}

export const editSystemUser = (userId) => async (dispatch, getState) => {
  const { users } = getState().systemUsers;
  const user = users.filter(x => x.userId === userId);

  if (user.length === 0){
    return;
  }

  dispatch(loadUserSuccess(user[0]));
  dispatch(showEditForm(true));
}
