import React from 'react';
import { string, func, bool } from 'prop-types';
import {
  Input
} from 'reactstrap';

const checkEvent = (id, checked) => {

  return {
    target: {
      id: id,
      value: !!(checked)
    },
    preventDefault() { },
  }
}

const checkboxStyle = {
  margin: "0px",
  position: "static",
}

const CheckBoxInput = ({ id, handleChange, checked }) => (
  <Input
    type='checkbox'
    id={id}
    checked={checked}
    onChange={(e) => {
      handleChange(checkEvent(id, e.target.checked))
    }}
    style={checkboxStyle}
  />
)

CheckBoxInput.propTypes = {
  id: string.isRequired,
  checked: bool.isRequired,
  handleChange: func.isRequired,
}

// CheckBoxInput.defaultProps = {
//   isChecked: false,
// }

export default CheckBoxInput;
