const archiveObject = {
  objectId: "",
  inventoryId: "",
  objectType: "",
  designer: "",
  location: "",
  secondaryLine: "",
  division: "",
  year: "",
  season: "",
  lookNumber: "",
  internalReferenceNumber: "",
  primaryColor: "",
  secondaryColors: [],
  material: "",
  description: "",
  event: "",
  eventDate: "",
  stylistEditor: "",
  archiveNotes: "",
  conservationNotes: "",
  size: "",
  relatedObjects: [],
  tags: [],
  primaryImage: "",
  primaryImageStatus: "MISSING_PRIMARY_IMAGE",
  secondaryImage: "",
  dateEntered: "",
  enteredBy: "",
  dateChanged: "",
  changedBy: "",
  shipments: [],
  checkIns: [],
  history: [],
  relationshipsAreDirty: false,
  coreFieldsAreDirty: false,
  removedRelatedObjects: []
};

export default archiveObject;
