import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func, shape, arrayOf } from "prop-types";
import { signOut } from "../../actions/authActions";
import { setTenantId } from "../../actions/systemActions";
import StandardUserMenu from "./StandardUserMenu";
import DigitalArchivistUserMenu from "./DigitalArchivistUserMenu";
import { NULL_UUID } from "../../api/constants";

class PrimaryHeader extends Component {
  isMenuOpen = state => {
    const { isOpen } = state;
    this.setState({
      sideBarMenuIsOpen: isOpen || false //eslint-disable-line react/no-unused-state
    });
  };

  signMeOut = () => {
    const { signOut } = this.props;
    signOut();
  };

  handleChangeClient = (newValue) => {
    const { setTenantId } = this.props;

    setTenantId(newValue.value, newValue.label);
  };

  handleCloseSideNav = () => {
    this.setState({
      sideBarMenuIsOpen: false //eslint-disable-line react/no-unused-state
    });
  };

  toggleSettingsSubMenu = () => {
    const { settingsSubMenuIsOpen } = this.state;
    this.setState({
      settingsSubMenuIsOpen: !settingsSubMenuIsOpen
    });
  }

  render() {
    const { userProfile, clients, selectedClient } = this.props;
    const { sideBarMenuIsOpen, settingsSubMenuIsOpen } = this.state || false;

    if (userProfile.role === "User") {
      return (
        <StandardUserMenu
          userName={userProfile.name}
          sideBarMenuIsOpen={sideBarMenuIsOpen}
          isMenuOpen={this.isMenuOpen}
          signMeOut={this.signMeOut}
          closeSideNav={this.handleCloseSideNav}
        />
      );
    }

    if (
      userProfile.role === "System Administrator" ||
      userProfile.role === "Digital Archivist Administrator" ||
      userProfile.role === "Digital Archivist User"
    ) {
      return (
        <DigitalArchivistUserMenu
          clients={clients}
          selectedClient={selectedClient}
          userName={userProfile.name}
          handleChangeClient={(newValue, actionMeta) => this.handleChangeClient(newValue, actionMeta)}
          sideBarMenuIsOpen={sideBarMenuIsOpen}
          isMenuOpen={this.isMenuOpen}
          signMeOut={this.signMeOut}
          closeSideNav={this.handleCloseSideNav}
          settingsSubMenuIsOpen={settingsSubMenuIsOpen}
          toggleSettingsSubMenu={this.toggleSettingsSubMenu}
        />
      );
    }

    return (
      <div>
        <h2>Unauthorized</h2>
      </div>
    )
  }
}

PrimaryHeader.propTypes = {
  userProfile: shape({
    sub: string,
    email: string,
    first_name: string,
    last_name: string,
    name: string,
    role: string,
    tier: string,
    tenant_id: string
  }).isRequired,
  clients: arrayOf(
    shape({
      tenantId: string,
      client: string
    })
  ).isRequired,
  selectedClient: shape({
    clientId: string,
    clientName: string,
  }),
  setTenantId: func.isRequired,
  signOut: func.isRequired
};

PrimaryHeader.defaultProps = {
  selectedClient: {
    clientId: NULL_UUID,
    clientName: ""
  },
}


const mapStateToProps = state => ({
  userProfile: state.user.profile,
  clients: state.system.lists["clients"],
  clientId: state.system.tenantId,
  selectedClient: {
    clientId: state.system.tenantId,
    clientName: state.system.clientName,
  }

});

export default connect(
  mapStateToProps,
  {
    setTenantId,
    signOut
  }
)(PrimaryHeader);
