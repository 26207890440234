import React from "react";
import { func, bool, string } from "prop-types";
import {
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import {
  authBackgroundStyle,
  authFormStyle,
  actionButtonStyle,
  cancelButtonStyle
} from "../../styles/authStyles";

const resetPasswordNoteStyle = {
  marginLeft: "15px",
  marginRight: "15px",
  marginTop: "15px",
  marginBottom: "40px",
  color: "#212529"
}

const AppForgotPasswordRequestForm = ({
  processing,
  handleChange,
  formIsValid,
  resetPasswordRequest,
  cancelResetPassword,
  error
}) => (
  <div className="forgot-password-background" style={authBackgroundStyle}>
    <Container className="require-new-password-container" style={authFormStyle}>
      <h2 style={{textAlign: "center", color: "black"}}>Reset your password</h2>
      <div className="reset-password-note" style={resetPasswordNoteStyle}>
        You will receive a verification code from The Digital Archivist to reset
        your password.
      </div>
      <Form>
        <Col>
          <FormGroup>
            <Input
              id="username"
              name="username"
              placeholder="Enter email"
              type="text"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <Button
            color="primary"
            size="lg"
            disabled={!formIsValid}
            onClick={() => resetPasswordRequest()}
            style={actionButtonStyle}
          >
            Submit
          </Button>
          <Button
            outline
            color="secondary"
            size="lg"
            onClick={() => cancelResetPassword()}
            style={cancelButtonStyle}
          >
            Cancel
          </Button>
        </Col>
        {processing ? (
          <span>
            <i className="fa fa-spin fa-spinner" />
            Requesting password reset...
          </span>
        ) : null}
        <Col>
          <Label>{error}</Label>
        </Col>
      </Form>
    </Container>
  </div>
);

AppForgotPasswordRequestForm.propTypes = {
  formIsValid: bool.isRequired,
  processing: bool.isRequired,
  handleChange: func.isRequired,
  resetPasswordRequest: func.isRequired,
  cancelResetPassword: func.isRequired,
  error: string
};

AppForgotPasswordRequestForm.defaultProps = {
  error: ""
};

export default AppForgotPasswordRequestForm;
