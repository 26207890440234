import {
  ADD_USER_REQUESTED,
  EDIT_USER_REQUESTED,
  UPDATE_USER_FIELD_VALUE,
  UPDATE_USER_FORM_STATE,
  UPDATE_USER_FULL_NAME,
  CHECK_USER_IS_VALID,
  CHECK_USER_IS_DIRTY,
  SHOW_USER_EDIT_FORM,
  CANCEL_USER_EDIT_FORM,
  LOAD_USER_REQUESTED,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILURE,
  SAVE_USER_REQUESTED,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  CHANGE_USER_FILTER,
  INITIALIZE_USER_FILTER,
  DISABLE_USER_REQUESTED,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_FAILURE,
  ENABLE_USER_REQUESTED,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_FAILURE,
} from "../actions/userActions";
import { LOGOUT_SUCCESS } from "../actions/authActions";
import { user } from "../models/user";

const initialFilter = {
  userName: ""
};

const initialState = {
  filter: { ...initialFilter },
  action: '',
  editFormIsOpen: false,
  saveSuccess: false,
  isValid: false,
  isDirty: true,
  isSaving: false,
  addAnother: false,
  loading: false,
  systemUser: false,
  users: [],
  editUser: { ...user },
  originalUser: { ...user },
  error: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_USER_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          userName: action.userName,
        }
      };

    case LOAD_USER_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isValid: true,
        isDirty: false,
        editUser: {...action.payload},
        originalUser: {...action.payload}
      };

    case LOAD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case ADD_USER_REQUESTED:
      return {
        ...state,
        isValid: false,
        isDirty: true,
        action: "Create User",
        editFormIsOpen: true,
        editUser: { ...action.payload },
        originalUser: { ...action.payload },
        editSystemUser: false
      };

    case EDIT_USER_REQUESTED:
      return {
        ...state,
        action: "Edit User",
        editFormIsOpen: true,
      };

    case CHECK_USER_IS_VALID:
      return {
        ...state,
        isValid: action.payload
      };

    case CHECK_USER_IS_DIRTY:
      return {
        ...state,
        isDirty: action.isDirty
      };

    case UPDATE_USER_FULL_NAME:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          fullName: action.payload
        }

      }
    case UPDATE_USER_FIELD_VALUE:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          [action.id]: action.id == "email" ? action.value.toLowerCase() : action.value
        }
      };

    case UPDATE_USER_FORM_STATE:
      return {
        ...state,
        [action.id]: action.value
      }

    case SHOW_USER_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: true,
        systemUser: action.payload
      };

    case CANCEL_USER_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: false,
        action: '',
        isValid: false,
        isDirty: false,
        saveSuccess: false,
        editUser: {
          ...user
        },
        originalUser: {
          ...user
        }
      };

    case SAVE_USER_REQUESTED:
      return {
        ...state,
        isSaving: true
      };

    case SAVE_USER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        editFormIsOpen: false,
        saveSuccess: true,
        editUser: {
          ...action.payload
        },
        originalUser: {
          ...action.payload
        }
      };

    case SAVE_USER_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case CHANGE_USER_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.id]: action.value
        }
      };

    case DISABLE_USER_REQUESTED:
      return {
        ...state
      }

    case DISABLE_USER_SUCCESS:
      return {
        ...state,
        editUser: {
          ...action.payload,
          status: "Disabled"
        },
        originalUser: {
          ...action.payload,
          status: "Disabled"
        }
      }

    case DISABLE_USER_FAILURE:
      return {
        ...state,
        err: action.payload
      }

    case ENABLE_USER_REQUESTED:
      return {
        ...state
      }

    case ENABLE_USER_SUCCESS:
      return {
        ...state,
        editUser: {
          ...action.payload,
          status: "Active"
        },
        originalUser: {
          ...action.payload,
          status: "Active"
        }
      }

    case ENABLE_USER_FAILURE:
      return {
        ...state,
        err: action.payload
      }

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
