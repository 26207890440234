import moment from "moment";
import emptyArchiveObject from "../models/archiveObject";
import { LOGOUT_SUCCESS } from "../actions/authActions";

import {
  SHOW_EDIT_FORM,
  CANCEL_EDIT_FORM,
  LOAD_OBJECT_REQUESTED,
  LOAD_OBJECT_SUCCESS,
  LOAD_OBJECT_FAILURE,
  ADD_OBJECT_REQUESTED,
  EDIT_OBJECT_REQUESTED,
  CLEAR_OBJECT,
  CHECK_OBJECT_IS_VALID,
  CHECK_OBJECT_IS_DIRTY,
  FLAG_OBJECT_CORE_FIELD_DIRTY,
  UPDATE_OBJECT_FIELD_VALUE,
  ADD_OBJECT_MULTISELECT_VALUE,
  REMOVE_OBJECT_MULTISELECT_VALUE,
  CLEAR_OBJECT_MULTISELECT_VALUE,
  UPDATE_FORM_STATE,
  SAVE_OBJECT_REQUESTED,
  SAVE_OBJECT_SUCCESS,
  SAVE_OBJECT_FAILURE,
  OBJECT_IMAGE_REFRESH_REQUESTED,
  OBJECT_IMAGE_REFRESH_SUCCESS,
  URL_CHANGE_RELOAD_OBJECT,
  ADD_RELATED_OBJECT_REQUESTED,
  ADD_RELATED_OBJECT_SUCCESS,
  ADD_RELATED_OBJECT_FAILURE,
  REMOVE_RELATED_OBJECT,
  ASSIGN_RELATED_OBJECTS
} from "../actions/objectActions";

const initialState = {
  action: "",
  reloadObject: false,
  reloadImages: false,
  isValid: false,
  isDirty: false,
  addAnother: false,
  printBarcode: false,
  editFormIsOpen: false,
  addInventoryId: "",
  processing: false,
  viewObject: emptyArchiveObject,
  editObject: emptyArchiveObject
};

const objectReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_OBJECT_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      };

    case LOAD_OBJECT_SUCCESS:
      return {
        ...state,
        reloadObject: false,
        isValid: true,
        loading: false,
        viewObject: {
          ...action.payload,
          history: [
            ...action.payload.shipments.map(x => ({
              ...x,
              type: "shipment",
              sortDate: moment(x.dateRequested).toISOString()
            })),
            ...action.payload.checkIns.map(x => ({
              ...x,
              type: "checkin",
              sortDate: moment(x.checkInDate).toISOString()
            }))
          ]
        }
      };

    case LOAD_OBJECT_FAILURE:
      return {
        ...state,
        reloadObject: false,
        loading: false,
        error: action.payload.error,
        viewObject: emptyArchiveObject
      };

    case ADD_OBJECT_REQUESTED:
      return {
        ...state,
        action: "ADD",
        editObject: action.payload
      };

    case EDIT_OBJECT_REQUESTED:
      return {
        ...state,
        action: "EDIT",
        isDirty: false,
        addAnother: false,
        printBarcode: false,
        editObject: {
          ...state.viewObject,
          relationshipsAreDirty: false,
          coreFieldsAreDirty: false,
          removedRelatedObjects: []
        },
        originalObject: { ...state.viewObject }
      };

    case CLEAR_OBJECT:
      return {
        ...state,
        viewObject: { ...emptyArchiveObject }
      };

    case CHECK_OBJECT_IS_VALID:
      return {
        ...state,
        isValid: action.isValid
      };

    case CHECK_OBJECT_IS_DIRTY:
      return {
        ...state,
        isDirty: action.isDirty
      };

    case SHOW_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: true
      };

    case CANCEL_EDIT_FORM:
      return {
        ...state,
        action: null,
        isValid: false,
        isDirty: false,
        saveSuccess: false,
        editFormIsOpen: false,
        editObject: {
          ...emptyArchiveObject
        },
        originalObject: {
          ...emptyArchiveObject
        }
      };

    case UPDATE_OBJECT_FIELD_VALUE:
      return {
        ...state,
        editObject: {
          ...state.editObject,
          [action.id]: action.value
        }
      };

    case UPDATE_FORM_STATE:
      return {
        ...state,
        [action.id]: action.value
      };

    case ADD_OBJECT_MULTISELECT_VALUE:
      return {
        ...state,
        editObject: {
          ...state.editObject,
          [action.id]: [...action.value]
        }
      };

    case REMOVE_OBJECT_MULTISELECT_VALUE:
      return {
        ...state,
        editObject: {
          ...state.editObject,
          [action.id]: [...action.value]
        }
      };

    case CLEAR_OBJECT_MULTISELECT_VALUE:
      return {
        ...state,
        editObject: {
          ...state.editObject,
          [action.id]: []
        }
      };

    case OBJECT_IMAGE_REFRESH_REQUESTED:
      return {
        ...state,
        reloadImages: true
      };

    case OBJECT_IMAGE_REFRESH_SUCCESS:
      return {
        ...state,
        reloadImages: false
      };

    case SAVE_OBJECT_REQUESTED:
      return state;

    case SAVE_OBJECT_SUCCESS:
      return {
        ...state,
        originalObject: {
          ...action.payload,
          relationshipsAreDirty: false,
          coreFieldsAreDirty: false,
          removedRelatedObjects: []
        },
        isDirty: false,
        saveSuccess: true
      };

    case SAVE_OBJECT_FAILURE:
      return {
        ...state,
        error: action.payload,
        saveSuccess: false
      };

    case FLAG_OBJECT_CORE_FIELD_DIRTY:
      return {
        ...state,
        editObject: {
          ...state.editObject,
          coreFieldsAreDirty: true
        }
      };

    case URL_CHANGE_RELOAD_OBJECT:
      return {
        ...state,
        reloadObject:
          action.payload.location.pathname.split("/")[1] === "object"
      };

    case ADD_RELATED_OBJECT_REQUESTED:
      return {
        ...state,
        processing: true
      };

    case ADD_RELATED_OBJECT_SUCCESS:
      return {
        ...state,
        isDirty: true,
        editObject: {
          ...state.editObject,
          relationshipsAreDirty: true,
          relatedObjects:
            state.editObject.relatedObjects.some(
              x => x.objectId === action.payload.objectId
            ) || state.editObject.objectId === action.payload.objectId
              ? [...state.editObject.relatedObjects]
              : [
                  ...state.editObject.relatedObjects,
                  {
                    objectId: action.payload.objectId,
                    inventoryId: action.payload.inventoryId,
                    designer: action.payload.designer,
                    objectType: action.payload.objectType
                  },
                  ...action.payload.relatedObjects.filter(x => {
                    return (!state.editObject.relatedObjects || !state.editObject.relatedObjects.some(
                      y => y.objectId === x.objectId
                    ));
                  })
                ]
        },
        processing: false,
        addInventoryId: ""
      };

    case ADD_RELATED_OBJECT_FAILURE:
      return {
        ...state,
        addInventoryId: "",
        processing: false
      };

    case ASSIGN_RELATED_OBJECTS:
      return {
        ...state,
        isDirty: true,
        editObject: {
          ...state.editObject,
          relationshipsAreDirty: true,
          relatedObjects: [
            ...state.editObject.relatedObjects,
            ...action.payload.relatedObjects.filter(x => {
              return (!state.editObject.relatedObjects || !state.editObject.relatedObjects.some(
                y => y.objectId === x.objectId
              ));
            }),
            {
              objectId: action.payload.objectId,
              inventoryId: action.payload.inventoryId,
              designer: action.payload.designer,
              objectType: action.payload.objectType,
            }

          ]
        }
      };

    case REMOVE_RELATED_OBJECT:
      return {
        ...state,
        isDirty: true,
        editObject: {
          ...state.editObject,
          relationshipsAreDirty: true,
          relatedObjects: [
            ...state.editObject.relatedObjects.filter(
              x => x.objectId != action.objectId
            )
          ],
          removedRelatedObjects: [
            ...state.editObject.removedRelatedObjects,
            action.objectId
          ]
        }
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isValid: false,
        isDirty: false,
        addAnother: false,
        printBarcode: false,
        editFormIsOpen: false
      };

    default:
      return state;
  }
};

export default objectReducer;
