import { doFetch } from "./index";

const findObject = async (identifier, clientId) => {
  const path = `/lookup-object/${identifier}?clientId=${clientId}`;

  const params = {
    method: "GET",
    headers: {}
  };

  const response = await doFetch(path, params);

  return response;
}

export default findObject;
