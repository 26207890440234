import React from "react";
import { string, bool, func } from "prop-types";
import { FormGroup, Input, Label, Col } from "reactstrap";

const requireFieldIndicatorStyle = {
  color: "#FF0000",
  paddingRight: ".5em"
};

const FormTextInput = ({ label, id, value, handleChange, required, type, disabled, autoComplete}) => (
  <FormGroup row>
    <Label for={id} sm={3}>
      {label}
      {required ? (
        <span
          className="required-field-indicator"
          style={requireFieldIndicatorStyle}
        >
          *
        </span>
      ) : null}
    </Label>
    <Col sm={9}>
      <Input
        id={id}
        type={type}
        disabled={disabled}
        value={value || ""}
        onChange={handleChange}
        autoComplete={autoComplete}
      />
    </Col>
  </FormGroup>
);

FormTextInput.propTypes = {
  label: string.isRequired,
  id: string.isRequired,
  value: string,
  type: string,
  required: bool,
  disabled: bool,
  autoComplete: bool,
  handleChange: func.isRequired
};

FormTextInput.defaultProps = {
  disabled: false,
  required: false,
  type: "text",
  value: "",
  autoComplete: true
};

export default FormTextInput;
