import { string, arrayOf, shape } from "prop-types";

const archiveObjectPropType = {
  objectId: string,
  inventoryId: string,
  objectType: string,
  designer: string,
  location: string,
  secondaryLine: string,
  division: string,
  year: string,
  season: string,
  lookNumber: string,
  internalReferenceNumber: string,
  primaryColor: string,
  secondaryColors: arrayOf(string),
  material: string,
  description: string,
  event: string,
  eventDate: string,
  stylistEditor: string,
  archiveNotes: string,
  conservationNotes: string,
  size: string,
  relatedObjects: arrayOf(
    shape({
      objectId: string,
      inventoryId: string,
      designer: string
    })
  ),
  tags: arrayOf(string),
  primaryImage: string,
  secondaryImage: string,
  missingData: string,
  dateEntered: string,
  enteredBy: string,
  dateChanged: string,
  changeddBy: string
};

export default archiveObjectPropType;
