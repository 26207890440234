import React, { Component } from 'react';
import { string, func, object, oneOfType } from 'prop-types';
import { Auth, Logger, JS } from 'aws-amplify';
import AppRequireNewPasswordForm from './AppRequireNewPasswordForm';

const logger = new Logger('AppRequireNewPassword');

class AppRequireNewPassword extends Component {
  constructor(props) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.changeState = this.changeState.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.changePassword = this.changePassword.bind(this);
    this.cancelChangePassword = this.cancelChangePassword.bind(this);
    this.formIsValid = this.formIsValid.bind(this);

    this._validAuthStates = ['requireNewPassword'];

    this.state = {
      newPassword: null,
      confirmPassword: null,
      processing: false,
      error: '',
    }
  }

  handleChange = (event) => {
    event.preventDefault();

    this.setState({[event.target.id]: event.target.value });
  }

  formIsValid = () => {

    const { newPassword, confirmPassword } = this.state;

    const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[!@#$%^&*.{}?-`~_|;:'<>,()"/\]\\])[a-zA-Z0-9!@#$%^&*.{}?-`~_|;:'<>,()"/\\\]]{10,20}$/;

    return (newPassword != null &&
      confirmPassword != null &&
      (newPassword === confirmPassword) &&
      re.test(newPassword));
  }

  cancelChangePassword = () => {
    this.changeState('signIn');
  }

  changePassword = () => {
    const { authData: user }  = this.props ;

    const { newPassword } = this.state;
    const { requiredAttributes } = user.challengeParam;
    const attrs = objectWithProperties(this.inputs, requiredAttributes);

    if (!Auth || typeof Auth.completeNewPassword !== 'function') {
        throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    this.setState({ processing: true, error: '' });

    Auth.completeNewPassword(user, newPassword, attrs)
      .then(user => {
        logger.debug('complete new password', user);
        if (user.challengeName === 'SMS_MFA') {
          this.changeState('confirmSignIn', user);
        }
        else if (user.challengeName === 'MFA_SETUP') {
          logger.debug('TOTP setup', user.challengeParam);
          this.changeState('TOTPSetup', user);
        }
        else {
          this.checkContact(user);
        }
      })
      .catch(err => this.error(err));
  }

  checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    Auth.verifiedContact(user)
      .then(data => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState('signedIn', user);
        }
        else {
          // user is unverified.  Verify email or phone in case account needs to be recovered in future
          user = Object.assign(user, data);
          this.changeState('verifyContact', user);
        }
      });
  }

  changeState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(state, data);
    }
  }


  render() {
    const { authState } = this.props;

    if (!this._validAuthStates.includes(authState)) {
      return null;
    }

    const { authData: user }  = this.props ;
    const { requiredAttributes } = user.challengeParam;

    logger.debug("requiredAttributes", requiredAttributes);

    const { error, processing } = this.state;

    const isValid = this.formIsValid();

    logger.debug("isValid", isValid);

    return (
      <AppRequireNewPasswordForm
        requiredAttributes={requiredAttributes}
        processing={processing}
        error={error}
        handleChange={this.handleChange}
        formIsValid={isValid}
        changePassword={this.changePassword}
        cancelChangePassword={this.cancelChangePassword}
      />
    );


  }
}

const objectWithProperties = (obj, keys) => {
  const target = {};
  for (const key in obj) {
      if (keys.indexOf(key) === -1) {
          continue;
      }
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          continue;
      }
      target[key] = obj[key];
  }
  return target;
}

AppRequireNewPassword.propTypes = {
  authState: string,
  authData: oneOfType([
    string,
    object
  ]),
  onStateChange: func,
}

AppRequireNewPassword.defaultProps = {
  authState: 'default',
  authData: '',
  onStateChange: null,
}

export default AppRequireNewPassword;
