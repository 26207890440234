import { string, shape } from "prop-types";
import { NULL_UUID } from "../api/constants";

export const user = {
  userId: NULL_UUID,
  firstName: "",
  lastName: "",
  fullName: "",
  email: "",
  role: "",
  tier: "",
  clientId: "",
  clientName: "",
  status: "",
};

export const userShape = shape({
  userId: string,
  firstName: string,
  lastName: string,
  fullName: string,
  email: string,
  role: string,
  tier: string,
  clientId: string,
  clientName: string,
  status: string
});
