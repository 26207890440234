export const appHeaderStyle = {
  backgroundColor: "black",
  paddingTop: "0",
  paddingBottom: "0",
  zIndex: "2",
  position: "fixed",
};

export const navbarContentsStyle = {
  width: "1140px", // Need media query
  maxWidth: "1140px",
  margin: "0 auto"
};

export const navbarLogoStyle = {
  marginLeft: "30px",
  marginRight: "30px",
  marginTop: "25px",
  marginBottom: "25px"
};

export const navbarBrandStyle = {

};

export const navbarInputStyle = {
  marginLeft: "30px",
  display: "inline-block"
};

export const menuUserIconStyle = {
  marginRight: "15px"
};

export const menuIconStyle = {
  marginRight: "15px",
  marginLeft: "5px",
  width: "20px",
  height: "auto"
};

export const menuUsernameStyle = {
  fontWeight: "600",
  color: "#383838",
  cursor: "default"
};

export const menuLogoutStyle = {
  borderBottom: 0
};

export const menuLogoutButtonStyle = {
  padding: 0,
  fontSize: "17px",
  color: "#1D1D1D",
  width: "100%",
  textAlign: "left"
};

export const menuButtonStyle = {
  backgroundColor: 'transparent',
  width: "100%",
  borderLeft: "none",
  borderRight: "none",
  borderTop: "none",
  borderBottom: "1px solid rgba(112, 112, 112, 0.5)",
  textAlign: "left",
  paddingLeft: 0,
  cursor: "pointer",

}

export const subMenuButtonStyle = {
  color: 'rgba(29, 29, 29, 0.5)',
  opacity: '50%'
}

export const subMenuListItemStyle = {
  paddingBottom: "8px",
  paddingTop: "8px",
}

export const burgerMenuStyles = sideBarMenuIsOpen => ({
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "initial",
    right: "1.5em",
    top: "36px",
    zIndex: "2000"
  },
  bmBurgerBars: {
    background: sideBarMenuIsOpen ? "#707070" : "#ffffff", //eslint-disable-line react/destructuring-assignment
    transition: "background-color 500ms linear"
  },
  bmBurgerBarsHover: {
    opacity: 1
  },
  bmCrossButton: {
    height: "24px",
    width: "24px"
  },
  bmCross: {
    background: "#bdc3c7"
  },
  bmMenu: {
    backgroundColor: "#F7F7F7",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
    paddingTop: "4.5em",
    paddingRight: "16px",
    paddingLeft: "18px",
    boxShadow: "0 0 25px rgba(0, 0, 0, 25%)"
  },
  bmMorphShape: {
    fill: "#333f48"
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em"
  },
  bmItem: {
    borderBottom: "1px solid rgba(112, 112, 112, 50%)",
    paddingTop: "20px",
    paddingBottom: "20px",
    fontSize: "17px",
    color: "#1D1D1D"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  bmMenuWrap: {
    top: 0
  }
});
