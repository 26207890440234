import {
  ADD_CLIENT_REQUESTED,
  EDIT_CLIENT_REQUESTED,
  UPDATE_CLIENT_FIELD_VALUE,
  UPDATE_CLIENT_FORM_STATE,
  CHECK_CLIENT_IS_VALID,
  CHECK_CLIENT_IS_DIRTY,
  SHOW_CLIENT_EDIT_FORM,
  CANCEL_CLIENT_EDIT_FORM,
  LOAD_CLIENT_LIST_REQUESTED,
  LOAD_CLIENT_LIST_SUCCESS,
  LOAD_CLIENT_LIST_FAILURE,
  LOAD_CLIENT_REQUESTED,
  LOAD_CLIENT_SUCCESS,
  LOAD_CLIENT_FAILURE,
  SAVE_CLIENT_REQUESTED,
  SAVE_CLIENT_SUCCESS,
  SAVE_CLIENT_FAILURE,
  CHANGE_CLIENT_FILTER,
  INITIALIZE_CLIENT_FILTER,
} from "../actions/clientActions";

import {
  SAVE_USER_SUCCESS,
  DISABLE_USER_SUCCESS,
  ENABLE_USER_SUCCESS,
} from "../actions/userActions";
import { LOGOUT_SUCCESS } from "../actions/authActions";
import { client } from "../models/client";

const initialFilter = {
  clientName: ""
};

const initialState = {
  filter: { ...initialFilter },
  action: '',
  editFormIsOpen: false,
  saveSuccess: false,
  isValid: false,
  isDirty: true,
  isSaving: false,
  addAnother: false,
  loading: false,
  clients: [],
  editClient: { ...client },
  originalClient: { ...client },
  error: null
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_CLIENT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          clientName: action.clientName,
        }
      };

    case LOAD_CLIENT_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isValid: true,
        isDirty: false,
        originalClient: {...action.payload},
        editClient: {...action.payload}
      };

    case LOAD_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case LOAD_CLIENT_LIST_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: [...action.payload]
      };

    case LOAD_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case ADD_CLIENT_REQUESTED:
      return {
        ...state,
        isValid: false,
        isDirty: true,
        action: "Create Client",
        editFormIsOpen: true,
        editClient: { ...action.payload },
        originalClient: { ...action.payload }
      };

    case EDIT_CLIENT_REQUESTED:
      return {
        ...state,
        action: "Edit Client",
        editFormIsOpen: true,
      };

    case CHECK_CLIENT_IS_VALID:
      return {
        ...state,
        isValid: action.payload
      };

    case CHECK_CLIENT_IS_DIRTY:
      return {
        ...state,
        isDirty: action.isDirty
      };

    case UPDATE_CLIENT_FIELD_VALUE:
      return {
        ...state,
        editClient: {
          ...state.editClient,
          [action.id]: action.value
        }
      };

    case UPDATE_CLIENT_FORM_STATE:
      return {
        ...state,
        [action.id]: action.value
      }

    case SHOW_CLIENT_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: action.showEditForm
      };

    case CANCEL_CLIENT_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: false,
        action: '',
        isValid: false,
        isDirty: false,
        saveSuccess: false,
        editClient: {
          ...client
        },
        originalClient: {
          ...client
        }
      };

    case SAVE_CLIENT_REQUESTED:
      return {
        ...state,
        isSaving: true
      };

    case SAVE_CLIENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        editFormIsOpen: false,
        saveSuccess: true,
        clients: [
          ...state.clients.filter(x => x.clientId !== action.payload.clientId),
          action.payload
        ],
        editClient: {
          ...action.payload
        },
        originalClient: {
          ...action.payload
        }
      };

    case SAVE_CLIENT_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case CHANGE_CLIENT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.id]: action.value
        }
      };

    case SAVE_USER_SUCCESS:
      return {
        ...state,
        editClient: {
          ...state.editClient,
          users: [
            ...state.editClient.users
              .filter(x => x.userId !== action.payload.userId),
            action.payload
          ]
        }
      }

    case DISABLE_USER_SUCCESS:
      return {
        ...state,
        editClient: {
          ...state.editClient,
          users: [
            ...state.editClient.users
              .filter(x => x.userId !== action.payload.userId),
            action.payload
          ]
        },
        originalClient: {
          ...state.originalClient,
          users: [
            ...state.originalClient.users
              .filter(x => x.userId !== action.payload.userId),
            action.payload
          ]
        }
      }

      case ENABLE_USER_SUCCESS:
        return {
          ...state,
          editClient: {
            ...state.editClient,
            users: [
              ...state.editClient.users
                .filter(x => x.userId !== action.payload.userId),
              action.payload
            ]
          },
          originalClient: {
            ...state.originalClient,
            users: [
              ...state.originalClient.users
                .filter(x => x.userId !== action.payload.userId),
              action.payload
            ]
          }
        }

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default clientReducer;
