import { string, shape } from "prop-types";

export const requestedObject = {
  requestId: '',
  objectId: '',
  inventoryId: '',
  client: '',
  clientId: '',
  objectType: '',
  location: '',
  dateRequested: '',
};

export const requestedObjectShape =
  shape({
    requestId: string,
    objectId: string,
    inventoryId: string,
    client: string,
    clientId: string,
    objectType: string,
    location: string,
    dateRequested: string,
  });

