import {
  ADD_LOCATION_REQUESTED,
  EDIT_LOCATION_REQUESTED,
  UPDATE_LOCATION_FIELD_VALUE,
  UPDATE_LOCATION_FORM_STATE,
  CHECK_LOCATION_IS_VALID,
  CHECK_LOCATION_IS_DIRTY,
  SHOW_LOCATION_EDIT_FORM,
  CANCEL_LOCATION_EDIT_FORM,
  LOAD_LOCATIONS_REQUESTED,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_LOCATIONS_FAILURE,
  SAVE_LOCATION_REQUESTED,
  SAVE_LOCATION_SUCCESS,
  SAVE_LOCATION_FAILURE,
  CHANGE_LOCATION_FILTER,
  INITIALIZE_LOCATION_FILTER
} from "../actions/locationActions";
import { LOGOUT_SUCCESS } from "../actions/authActions";
import { location } from "../models/location";

const initialFilter = {
  locationName: "",
  loading: false,
};

const initialState = {
  filter: { ...initialFilter },
  action: '',
  editFormIsOpen: false,
  saveSuccess: false,
  isValid: false,
  isDirty: true,
  isSaving: false,
  addAnother: false,
  loading: false,
  locations: [],
  editLocation: { ...location },
  originalLocation: { ...location },
  error: null
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_LOCATION_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          locationName: action.locationName,
        }
      };

    case LOAD_LOCATIONS_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: [...action.payload]
      };

    case LOAD_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };


    case ADD_LOCATION_REQUESTED:
      return {
        ...state,
        isValid: false,
        isDirty: true,
        action: "CREATE LOCATION",
        editFormIsOpen: true,
        editLocation: { ...action.payload },
        originalLocation: { ...action.payload }
      };

    case EDIT_LOCATION_REQUESTED:
      return {
        ...state,
        isValid: false,
        isDirty: false,
        action: "EDIT LOCATION",
        editFormIsOpen: true,
        editLocation: { ...action.payload },
        originalLocation: { ...action.payload }
      };

    case CHECK_LOCATION_IS_VALID:
      return {
        ...state,
        isValid: action.payload
      };

    case CHECK_LOCATION_IS_DIRTY:
      return {
        ...state,
        isDirty: action.isDirty
      };

    case UPDATE_LOCATION_FIELD_VALUE:
      return {
        ...state,
        editLocation: {
          ...state.editLocation,
          [action.id]: action.value
        }
      };

    case UPDATE_LOCATION_FORM_STATE:
      return {
        ...state,
        [action.id]: action.value
      }

    case SHOW_LOCATION_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: true
      };

    case CANCEL_LOCATION_EDIT_FORM:
      return {
        ...state,
        editFormIsOpen: false,
        action: '',
        isValid: false,
        isDirty: false,
        saveSuccess: false,
        editLocation: {
          ...location
        },
        originalLocation: {
          ...location
        }
      };

    case SAVE_LOCATION_REQUESTED:
      return {
        ...state,
        isSaving: true
      };

    case SAVE_LOCATION_SUCCESS:
      return {
        ...state,
        isSaving: false,
        editFormIsOpen: false,
        saveSuccess: true,
        location: {
          ...location
        },
        originalLocation: {
          ...location
        }
      };

    case SAVE_LOCATION_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case CHANGE_LOCATION_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.id]: action.value
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default locationReducer;
