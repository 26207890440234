/* eslint-disable */
import React, { Component } from "react";

const PrivacyPolicy = () => (
  <div className="privacy-policy">

    <h2>Privacy Policy</h2>

    <p><span>This privacy
    policy applies to the <b>THE DIGITAL ARCHIVIST </b>website
    (www.digitialarchivist.com), where this privacy policy is posted, to The
    Digital Archivist™ web application, and to <b>THE DIGITAL ARCHIVIST </b>mobile
    application (collectively, the &quot;Site&quot;). This privacy policy does not
    apply to information that is collected offline or to any information that may
    be posted on the Site by third parties. BY USING THE SITE, YOU CONSENT TO THE
    TERMS AND CONDITIONS OF THIS PRIVACY POLICY AND TO OUR PROCESSING OF PERSONAL
    INFORMATION FOR THE PURPOSES STATED BELOW. IF YOU DO NOT AGREE TO THE TERMS AND
    CONDITIONS OF THIS PRIVACY POLICY, PLEASE DO NOT USE THE SITE. </span></p>

    <p><b><span>A.<span> </span></span></b><b><span>Information Collected</span></b><span> </span></p>

    <p><span>We collect two
    types of information from visitors to the Site: (1) Personally Identifiable
    Information; and (2) Non-Personally Identifiable Information, such as your IP
    address or cookies. </span></p>



    <p ><b><span>(1)<span>&nbsp; </span></span></b><b><span>Personally Identifiable Information</span></b><span> </span></p>



    <p><span>&quot;Personally
    Identifiable Information&quot; is information that identifies you personally,
    such as your name, address, telephone number, email address, or employer name.
    The Site collects and stores the personally identifiable information that you
    have provided to us. Here are some examples of ways in which we may collect
    your personally identifiable information on the Site: </span></p>



    <ul>
    <li><span
        >We collect your first and last
        name, email address, business address, title, employer name, type of
        organization, and billing information, including credit card information
        when you create an account with us;</span></li>
    <li><span
        >We collect personally identifiable
        information when you (or we) enter it in The Digital Archivist™; </span></li>
    <li><span
        >We will collect your email address
        when you contact us either by email or by using the “Contact The Digital
        Archivist” feature on the Site;   </span></li>
    <li><span
        >We may tie your personally
        identifiable information to your non-personally identifiable information,
        described below, in an effort to track your usage of our services so that
        we can deliver services to you that meet your needs; and </span></li>
    <li><span
        >We may collect your name, email
        address or phone number if you contact us with a question or comment:</span></li>
    </ul>



    <p><span>The above list
    provides an example of the personally identifiable information that may be
    collected on the Site. If you do not want THE DIGITAL ARCHIVIST to collect your
    personally identifiable information, please do not create an account with us. </span></p>



    <p><b><span>(2)<span>&nbsp; </span></span></b><b><span>Non-Personally Identifiable Information</span></b><span> </span></p>



    <p><span>&quot;Non-Personally
    Identifiable Information&quot; can be technical information or it can be
    demographic information, such as your age, location, gender or job description,
    as well as information about your employer. Non-personally identifiable
    information does NOT identify you personally. Here are some examples of the
    non-personally identifiable information that is collected via the Site:</span></p>



    <ul>
    <li><span
        >Internet Protocol (IP) address –
        Your IP address is a number that lets computers attached to the Internet
        know where to send you data. </span></li>
    <li><span
        >Cookie – A cookie is a small text
        file, which may include an anonymous unique identifier, that our site may
        place on your computer as a tool to remember you and improve our Website’s
        service to you. </span><span>We ask
        your consent to use cookies when you initially access our Website. You may
        grant this consent by clicking “Okay, I agree.” You may also refuse or
        disable cookies by selecting the appropriate settings on your browser, although
        this may interfere with your ability to use the full functionality of the
        Website.  </span></li>
    </ul>



    <p><span>We collect the
    above non-personally identifiable information from all visitors to our Site. If
    you do not want THE WARDROBE to have access to this information, please do not
    visit our Site.</span></p>



    <p><b><span>B. Use of
    Information Collected Via the Site</span></b></p>



    <p ><b><span>(1)<span>&nbsp; </span></span></b><b><span>Personally Identifiable Information </span></b></p>



    <p><span>We use your
    personally identifiable information that is collected on the Site primarily for
    the following purposes:</span></p>



    <ul>
    <li><span
        >To process your orders, and to
        update you on the status of your account; </span></li>
    <li><span
        >To provide information and services
        via The Digital Archivist™; </span></li>
    <li><span
        >To alert you to updated information
        and new products and services from <b> </b>the Site, or to forward
        promotional materials to you;</span></li>
    <li><span
        >To allow you to share content and
        comments on the Site, if such functionality becomes available; </span></li>
    <li><span
        >To allow you to upload content to
        the Site, if such functionality becomes available;  </span></li>
    <li><span
        >To contact you in response to
        sign-up forms such as “Contact The Wardrobe” or other inquiry;</span></li>
    <li><span
        >To continuously improve your
        experience and the quality of the service provided by the Site.  </span></li>
    </ul>



    <p><b><span>(2)<span>&nbsp; </span></span></b><b><span>Non-Personally Identifiable Information </span></b></p>



    <p><span>Non-personally
    identifiable information is used as described above and in other ways as
    permitted by applicable laws, including combining non-personally identifiable
    information with personally identifiable information. </span></p>



    <p><b><span>C. Sharing and
    Disclosure of Information</span></b><span>
    </span></p>



    <p><b><span>(1)<span>&nbsp; </span></span></b><b><span>Personally Identifiable Information</span></b></p>



    <p><span>We may share or
    disclose your personally identifiable information in the following instances: </span></p>



    <p><span>(i)<span>&nbsp;&nbsp;&nbsp; </span></span><span>To complete a subscription. If you
    choose to subscribe to The Digital Archivist™, we may collect from you your
    credit card number, billing address and other information related to such
    purchase, and we may use such collected information in order to fulfill your
    subscription. </span></p>





    <p><span>(ii)<span>&nbsp; </span></span><span>To unaffiliated third-party service
    providers, agents or independent contractors who help us maintain our Site or
    software and provide other administrative services to us. If you do not wish
    for our unaffiliated third-party service providers to have access to your
    information, please do not register or submit any personally identifiable
    information to us. </span></p>



    <p><span>(iii)</span><span>To comply with the law or in the good faith belief that
    such action is necessary in order to conform to the requirements of law or
    comply with legal process served on us, protect and defend our rights or
    property, including the rights and property of <b>THE WARDROBE</b> <b>/ THE
    DIGITAL ARCHIVIST </b>or act in urgent circumstances to protect the personal
    safety of our other users. </span></p>



    <p><span>(iv)<span> </span></span><span>To third parties as part of any corporate reorganization
    process including, but not limited to, mergers, acquisitions, and sales of all
    or substantially all of our assets. </span></p>



    <p><span>(v)<span>&nbsp; </span></span><span>To track and analyze non-identifying and
    aggregate usage and volume statistical information from our visitors and
    customers and provide such information to third parties. </span></p>



    <p><span>(vi)<span> </span></span><span>To protect against potential fraud, we may verify with
    third parties the information collected from these Site. In the course of such
    verification, we may receive personally identifiable information about you from
    such services. </span></p>



    <p><span>Except as
    described in this Privacy Policy or at the time we request the information, we
    do not otherwise use, share or otherwise disclose your personally identifiable
    information to any third parties. </span></p>



    <p><span>(2)<span>&nbsp; </span></span><b><span>Non-Personally Identifiable Information</span></b><span> </span></p>



    <p><span>We use
    non-personally identifiable information collected on the Site in the manner
    disclosed above, in Section A (2). We may share this non-personal information
    with third parties.</span></p>



    <p><b><span>D.
    Collection and Use of Information from Children Under the Age of 13</span></b><span> </span></p>



    <p><span>The Site </span><span>does not knowingly collect or solicit
    Personal Information from anyone under the age of 13 or knowingly allow such
    persons to use the Site. If you are under 13 please do not attempt to register
    on the Site or send us any Personal Information.&nbsp; </span></p>



    <p><b><span>E.
    International Transfer of Information</span></b><span>
    </span></p>



    <p><span>If you choose to
    provide us with personally identifiable information, The Site<b> </b>may
    transfer that information to other third parties, across borders, and from your
    country or jurisdiction to other countries or jurisdictions around the world. </span></p>



    <p><b><span>F. Opt-Out</span></b><span> </span></p>



    <p><span>From time to
    time, we communicate with users who subscribe to our services via email or text
    message. For example, we may use your email address to confirm your request, to
    send you notice of payments, to send you information about changes to our
    products and services, and to send notices and other disclosures as required by
    law. Generally, users cannot opt-out of these communications, but they will be
    primarily informational in nature rather than promotional. </span></p>

    <p>
      <span>
    However, we provide you the opportunity to exercise an opt-out choice if you do
    not want to receive other types of communication from us, such as emails or
    updates from us regarding new services and products offered on the Site or if
    you do not want us to share your personally identifiable information with third
    parties. The opt-out choice may be exercised by ticking or un-ticking the
    appropriate box if such checkbox is available at the points where personally
    identifiable information is collected or by contacting us. </span></p>



    <p><b><span>H. Third Party
    Web Site</span></b><span> </span></p>



    <p><span>This statement
    applies solely to information collected on the Site. The Site may contain links
    to other web sites. We are not responsible for the privacy practices or the
    content of these other web sites. </span></p>



    <p><b><span>I. Changes to
    this Privacy Policy</span></b><span> </span></p>



    <p><span>We reserve the
    right to change this privacy policy from time to time. </span></p>



    <p><b><span>J. Security</span></b><span> </span></p>



    <p><span>No data
    transmissions over the Internet can be guaranteed to be 100% secure.
    Consequently, we cannot ensure or warrant the security of any information you
    transmit to us and you understand that any information that you transfer to The
    Site<b> </b>is done at your own risk. </span></p>



    <p><span>Once we receive
    your transmission, we make reasonable efforts to ensure security on our
    systems. We use firewalls to protect your information from unauthorized access,
    disclosure, alteration, or destruction. However, please note that this is not a
    guarantee that such information may not be accessed, disclosed, altered or
    destroyed by breach of such firewalls and secure server software.</span></p>



    <p><span>If we learn of a
    security systems breach we may attempt to notify you electronically so that you
    can take appropriate protective steps. </span></p>



    <p><b><span>K</span></b><b><span>. Contacting the Site</span></b></p>



    <p><span>If you have any
    questions about this privacy policy or our privacy practices, please contact us
    at [support@thewardrobellc.com]. All information you provide in any written
    communication will also be covered by this policy. </span></p>





    <p><span>Effective Date: August
    13, 2019</span></p>

    <p><span>Last Updated On: August
    13, 2019 </span></p>


  </div>
);

export default PrivacyPolicy;
