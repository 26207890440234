import { Auth, Logger } from "aws-amplify";
import {
  loadSystemLists,
  loadSystemClientList,
  loadLocationList,
  setTenantId
} from "./systemActions";
import { loadSystemUserList } from "./systemUserActions";
import { initializeShipmentFilter } from "./shipmentActions";
import { initializeShipmentLogFilter } from "./shipmentLogActions";
import { initializeImageManagementFilter } from "./imageManagementActions";
import { getMyArchive } from "./myArchiveActions";
import { NULL_UUID } from "../api/constants";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const DELETE_USER_PROFILE = "DELETE_USER_PROFILE";

export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

const logger = new Logger("AuthActions");

export const updateUserProfile = userProfile => {
  logger.info("user profile = ", userProfile);
  return {
    type: UPDATE_USER_PROFILE,
    payload: userProfile
  };
};

export const deleteUserProfile = () => {
  return {
    type: DELETE_USER_PROFILE
  };
};

export const initializeAppOnSuccessfulLogin = profile => async (
  dispatch,
  getState
) => {

  await dispatch(updateUserProfile(profile));

  let tenantId = "";
  if (
    profile.role.startsWith("System") ||
    profile.role.startsWith("Digital Archivist")
  ) {
    // System or wardrobe user so load the list associated with the selected client
    tenantId = getState().system.tenantId;
    if (!tenantId) {
      dispatch(setTenantId(NULL_UUID));
      tenantId = NULL_UUID;
    }

    dispatch(loadSystemClientList());
    dispatch(loadSystemUserList());
    dispatch(loadSystemLists(tenantId));
    dispatch(loadLocationList());
    dispatch(initializeShipmentFilter());
    dispatch(initializeShipmentLogFilter());
    dispatch(initializeImageManagementFilter());

  }
  else {
    dispatch(setTenantId(profile.tenant_id));
    dispatch(getMyArchive())
    tenantId = profile.tenant_id;
  }
};

export const signOut = () => {
  return dispatch => {
    dispatch({ type: LOGOUT_REQUESTED });

    if (!Auth || typeof Auth.signOut !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    Auth.signOut()
      .then(() => dispatch(clearState()))
      .catch(err => logger.error(err));

    dispatch({ type: LOGOUT_SUCCESS });
  };
};

export const clearState = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};
