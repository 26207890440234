import moment from 'moment';

const uuidV4RegEx = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i;
const inventoryIdRegEx = /^[0-9]{9}/i;

export const isValidDate = str => {
  const m = moment(str);
  return m.isValidDate(str)
};

export const isValidV4UUID = str => {
  return uuidV4RegEx.test(str);
};

export const isValidInventoryId = str => {
  return inventoryIdRegEx.test(str);
};

export const isValidJson = str => {
  try {
    JSON.parse(str);
  }
  catch (e) {
    return false;
  }
  return true;
};

export const isEmpty = value => {
  return (value == null || value.length === 0);
}
