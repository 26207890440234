/* eslint-disable */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { timeout } from 'q';


class ScrollToTop extends Component {

  componentDidMount = () => {
    window.scroll(0, 0);
  }

  componentDidUpdate = prevProps => {
		if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scroll(0, 0);
    }
  }

	render = () => this.props.children;

}

export default withRouter(ScrollToTop);
