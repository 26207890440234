import {
  LOAD_SYSTEMUSER_LIST_REQUESTED,
  LOAD_SYSTEMUSER_LIST_SUCCESS,
  LOAD_SYSTEMUSER_LIST_FAILURE,
  CHANGE_SYSTEM_USER_FILTER,
} from "../actions/systemUserActions";
import { SAVE_SYSTEM_USER_SUCCESS } from "../actions/userActions";
import { LOGOUT_SUCCESS } from "../actions/authActions";
import { user } from "../models/user";

const initialFilter = {
  fullName: ""
};

const initialState = {
  filter: { ...initialFilter },
  action: "",
  editFormIsOpen: false,
  saveSuccess: false,
  isValid: false,
  isDirty: true,
  isSaving: false,
  addAnother: false,
  loading: false,
  users: [],
  editUser: { ...user },
  originalUser: { ...user },
  error: null
};

const systemUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SYSTEMUSER_LIST_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case LOAD_SYSTEMUSER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...action.payload]
      };

    case LOAD_SYSTEMUSER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: { ...action.payload }
      };

    case CHANGE_SYSTEM_USER_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.id]: action.value
        }
      };

    case SAVE_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        users: [
          ...state.users.filter(x => x.userId !== action.payload.userId),
          action.payload
        ]
      }

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default systemUserReducer;
