export const APPLICATION_NAME = "The Digital Archivist";
export const NULL_UUID = "00000000-0000-0000-0000-000000000000";
// export const API_HOST_NAME = {
//   test: "https://digitalarchivist.oxerdev.com/",
//   staging: "https://stagingapi.digitalarchivist.com/",
//   prod: "https://api.digitalarchivist.com/",
// }

// eslint-disable-next-line no-undef
export const API_HOST_NAME = __API__;

export const imageCustomPrefix = {
  public: 'uploads/',
  protected: 'uploads/',
  private: 'downloads/'
};

export const ImageNumber = {
  OriginalPrimaryImage: 0,
  ViewPrimaryImage: 1,
  OriginalSecondaryImage: 2,
  ViewSecondaryImage: 3,
  SearchImage: 4,
};

